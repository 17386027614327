import { React, useEffect, useState, useContext } from "react";
import { AuthContext } from "../../../auth/AuthContext";
import { Column } from "@ant-design/charts";
import { Card } from "antd";
import { userTestRepo } from "../../../store";
import { useTranslation } from "react-i18next";

const ChartsColumnSubject = () => {
  const { t } = useTranslation();
  const { user, control } = useContext(AuthContext);
  const [dataList, setDataList] = useState([]);
  const [, setCleanup] = useState();
  const [sendingRequest, setSendingRequest] = useState(false);

  const asyncFetch =  () => {
    setCleanup(false);
      
      userTestRepo.getStemsCorrectThemesByType({
        user_id: user.userId,
        subject_id: control.subjectId
      }).then(({data})=>{
        if(data.length !== 0  ){
          for (let i = 0; i < data.length; i++) {   
            setDataList((dataList) => [
              ...dataList,
              {            
                name: t("modalityControl"),
                月份: data[i].name,
                月均降雨量: Math.round(data[i].sumCorrect),
              },
            ]);          
          }
          setSendingRequest(true);
        }
      });
      
      
  
  
  };

  useEffect(() => {
    asyncFetch();
    return () => {
      setCleanup();
    };
  }, []);
  if(sendingRequest === true){
  var config = {
    data: dataList ,
    isGroup: true,
    xField: "月份",
    yField: "月均降雨量",
    seriesField: "name",
    label: {
      position: "middle",
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" },
      ],
    },
  }
  };

  return (
    <Card title={t('titlecard')} bordered={false}>
      {sendingRequest === true  ? <Column className="pxy-10" {...config} />: null}
    </Card>
  );
};

export default ChartsColumnSubject;
