import "./CardUserInhabilitado.scss";
import { Avatar } from "antd";
import { Link } from "wouter";
import { useTranslation } from "react-i18next";
import AzureAvatar from "../AzureAvatar/AzureAvatar";

const CardUserInhabilitado = () => {
  const { t } = useTranslation();

  return (
    <div className="card-user_header">
      <div>
        <h3 className="montserrat-15 mb-5 c-blue-primary">{t("usuario")}</h3>
        <p className="montserrat-15 c-blue-primary">
          {localStorage.getItem("username")}
        </p>
      </div>
      <Link href="">
      {localStorage.getItem("avatar") === "null" ? (
          <Avatar
            style={{
              backgroundColor: "#0C4393",
              verticalAlign: "middle",
            }}
            size="large"
          >
            {localStorage.getItem("username").slice(0, 1).toUpperCase()}
          </Avatar>
        
        ) : (
          <AzureAvatar value={localStorage.getItem('avatar')}  size="large" />
        )}
      </Link>
    </div>
  );
};

export default CardUserInhabilitado;
