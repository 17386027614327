import { useContext, useEffect, useState } from "react";
import { Avatar, Button, Modal } from "antd";
import { CameraOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../auth/AuthContext";
import { types } from "../../../types/types";
import { AZURE } from "../../../Config/config";
import { userRepo } from "../../../store";
import notfound from "../../../assets/notfound.png";
import "./AccountAvatarUserEdit.scss";


const AccountAvatarUserEdit = () => {
  const { t } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);

  const { dispatchUser } = useContext(AuthContext);

  const [filepreview, setFilepreview] = useState(null);

  const [image, setImage] = useState("");

  const [, setCleanup] = useState(false);

  const [user, setUser] = useState({
    name: "",
    email: "",
    tax: "",
    avatar: "",
  });

  //Carga  de datos
  useEffect(() => {
    onLoad();
    return () => {
      setCleanup();
    };
  }, []);

  const onLoad = async () => {
    const response = await userRepo.showUser();
    setUser({
      name: response.data.name,
      email: response.data.email,
      tax: response.data.tax_id,
      avatar: response.data.avatar,
    });
    setCleanup(false);
  };

  const handleModal = (modalVisible) => {
    setImage("");
    setFilepreview(null);
    setModalVisible(modalVisible);
  };

  const normFile = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    setFilepreview(URL.createObjectURL(files[0]));
    setImage(files[0]);
  };

  const onSend = (modalVisible) => {
    if (image !== "") {
      Modal.info({
        title: t("info"),
        content: t("upload"),
      });
      setModalVisible(modalVisible);
      const formData = new FormData();
      formData.append("file", image);
      userRepo.upload(formData).then(
        (data) => {
          Modal.info({
            title: t("info"),
            content: t("success"),
          });
          dispatchUser({
            type: types.avatar,
            payload: {
              avatar: data.data,
            },
          });
          localStorage.setItem('avatar',data.data);
          window.location.reload(true);
        },
        (error) => {
          Modal.error({
            title: t("error"),
            content: error.response.data.errors,
          });
        }
      );
      setCleanup(true);
      setImage("");
    } else {
      Modal.error({
        title: t("error"),
        content: t("notSuccess"),
      });
    }
  };

  return (
    <div className="profile-user-avatar-container">
      
       
         {localStorage.getItem("avatar") === "null" ? (
          <Avatar
            style={{
              backgroundColor: "#0C4393",
              verticalAlign: "middle",
            }}
            size="large"
          >
            {localStorage.getItem("username").slice(0, 1).toUpperCase()}
          </Avatar>
        
      ) : (
        <div className="profile-user_avatar">
          <img
            className="profile-user_avatar"
            src={AZURE.getUrl() + localStorage.getItem('avatar')}
            alt={t("altAvatar")}
          />
        </div>
      )}

      <div className="profile-user_content">
        <h3 className="montserrat-15 mb-5 c-blue-primary">{t("usuario")}</h3>
        <p className="montserrat-15 mb-20 c-blue-primary">{user.email}</p>

        <Button icon={<CameraOutlined />} onClick={() => handleModal(true)}>
          {t("changeAvatar")}
        </Button>
      </div>
      <Modal
        title={t("changeAvatar")}
        style={{ top: 20 }}
        open={modalVisible}
        onOk={() => onSend(false)}
        onCancel={() => handleModal(false)}
      >
        <div className="profile-user-avatar-container">
          <div className="profile-user_avatar">
            {filepreview === null ? (
              <img
                className="profile-user_avatar"
                src={notfound}
                alt={t("altAvatar")}
              />
            ) : (
              <img
                className="profile-user_avatar"
                src={filepreview}
                alt={t("altAvatar")}
              />
            )}
          </div>
          <div className="file-select" id="src-file1">
            <label className="custom-file-upload">
              <input
                type="file"
                onChange={normFile}
                style={{ display: "none" }}
                accept=".jpg, .jpeg, .png"
              />
              <CameraOutlined /> {t("changeAvatar")}
            </label>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AccountAvatarUserEdit;
