import React, { useState } from "react";

export const CheckBox = ({ initialState, id, onChange }) => {
    const [checked, setChecked] = useState(initialState);
    const onClick = (checked) => {
      setChecked(checked);
      onChange(id, checked);
    };
    return (
      <input
        type="checkbox"
        onClick={(e) => onClick(e.target.checked)}
        defaultChecked={checked}
      />
    );
  };