import { Button, Form } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../auth/AuthContext";
import { useTranslation } from "react-i18next";
import { useLocation } from "wouter";

const UnsubscribeStep1 = ({ next, step1Data, setStep1Data }) => {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const { subcription } = useContext(AuthContext);
  const [ , setCleanup] = useState();

  useEffect(() => {
    return () => {
      setCleanup();
    };
  }, []);

  const finishStep1 = (e) => {
    setStep1Data(); // Guardo los datos del tercer formulario en un estado
    next(); // Voy al siguiente paso
  };

  return (
    <Form onFinish={finishStep1}>
      <div className="page-controls max-width">
        <div className="mt-20">
          <h3 className="mb-15 mt-30">
           {t('unsubscribStep11')}  {subcription.subcriptionById.subscriptionEnd},
           {t('unsubscribStep12')} {subcription.subcriptionById.paqueteName}, {t('unsubscribStep13')}{" "}
            {subcription.subcriptionById.subjects}
          </h3>
          
          <h4 className="mb-15 mt-30 text-center">
          {t('unsubscribStep14')}
          </h4>
        </div>
      </div>

      <div className="container-buttons-unsubscribe">
        <Button onClick={() => setLocation("/dashboard")}>
          {t("btnback")}
        </Button>

        <Button type="primary" htmlType="submit">
          {t("btn")}
        </Button>
      </div>
    </Form>
  );
};

export default UnsubscribeStep1;
