import React, { useState } from 'react'
import { AZURE } from '../../../Config/config'
import { Avatar } from 'antd'
import css from './AzureAvatar.scss'

export default function AzureAvatar ({
  value = null,
  size = 'large',
  children
}) {
  const [src] = useState(() => {
    
    return value !== null ? AZURE.getUrl() + value : ''
  })

  return (
    <Avatar style={css.Avatar} size={size} src={src}>
      {children}
    </Avatar>
  )
}
