import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  Checkbox,
  Divider,
  Form,
  message,
  Modal,
  Pagination,
  Space,
  Table,
} from "antd";
import { useLocation } from "wouter";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../auth/AuthContext";
import LinearProgress from "@mui/material/LinearProgress";
import { userTestStemRepo, stemContextRepo } from "../../../store";
import "./EssaysCheck.scss";

const EssaysCheck = () => {
  const { t } = useTranslation();
  const columns = [
    {
      title: "#",
      dataIndex: "questionNumber",
      key: "questionNumber",
    },
    {
      title: t("mark"),
      dataIndex: "mark",
      key: "mark",
    },
  ];
  const [, setLocation] = useLocation();
  const { control } = useContext(AuthContext);
  const { historical } = useContext(AuthContext);
  const { typeTest } = useContext(AuthContext);
  const [question, setQuestion] = useState([]);
  const [totalStem, setTotalStem] = useState();
  const [context, setContext] = useState(true);
  const [data, setData] = useState([]);
  const [modalVisibleInfo, setModalVisibleInfo] = useState(true);
  const [explanation, setExplanation] = useState([]);
  //Variable encargada de la paginación
  const [current, setCurrent] = useState(1);
  const [loadings, setLoadings] = useState();
  const [, setLoading] = useState(true);

  let setSize = false;
  const key = "updatable";

  React.useEffect(() => {
    window.onpopstate = (e) => {
      if (localStorage.getItem("header") === "0") {
        onSend();
        localStorage.setItem("header", 1);
      }
    };
    window.onbeforeunload = (e) => {
      if (localStorage.getItem("header") === "0") {
        onSend();
        localStorage.setItem("header", 1);
      }
    };
  });

  useEffect(() => {
    onload();
    return () => {
      setLoadings();
    };
  }, []);

  let node = React.createRef();
  useEffect(() => {
    renderMath();
  });
  const renderMath = () => {
    window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, node.current]);
  };
 
  const info = () => {
    message.info(
      t("msgAlert"),
      0
    );
  };

  const onload = async () => {
    var arrayStem;
    info();
    setModalVisibleInfo(true);
    const response = await userTestStemRepo.getUserTestStem(historical.userTest); 
    const arrayMultiAnswer = multiAnswer(response.data);
    arrayStem = deleteDuplicate(response.data); 
   
    const responseContext = await stemContextRepo.getStemContext();
   
    for (let j = 0; j < arrayStem.length; j++) {    
      setExplanation((explanation) => [...explanation, false]);
      setData((data) => [
        ...data,
        {
          key: j + 1,
          questionNumber: j + 1,
          mark: arrayStem[j].is_marked === 0 ? <Checkbox name={j + 1} id={j + 1} checked={false}></Checkbox>: <Checkbox name={j + 1} id={j + 1} checked={true}></Checkbox>,
        },
      ]);
    }
    setTotalStem(arrayStem.length);
  
    for (let i = arrayStem.length - 1; i >= 0; i--) {
      var stemChoice = "";
      const ans = [];
      if (arrayStem[i].stem["is_multi_choice"] === 1 ) {      
        for(let a = 0; a < arrayMultiAnswer.length; a++){
          if( arrayMultiAnswer[a].stem_id === arrayStem[i].stem_id ){
            ans.splice(
              ans.length,
              0,
              
              arrayMultiAnswer[a].stem_choices[0].body+
                "......" +
                ( arrayStem[i].is_correct === 1
                  ? " <strong>" + t("correct") + "</strong>"
                  : " <strong>" + t("incorrect") + "</strong><br>")
            ) 
          }
       }       
        
      } else {
        
        if (arrayStem[i].stem_choices.length === 0) {
          stemChoice = "<strong>" + t("unanswered") + "</strong>";
        }
        if (arrayStem[i].stem_choices.length !== 0) {
          stemChoice = arrayStem[i].stem_choices[0].body+
            "....." +
            (arrayStem[i].is_correct === 1
              ? " <strong>" + t("correct") + "</strong>"
              : " <strong>" + t("incorrect") + "</strong><br>");
        }
      }

      if (arrayStem[i].stem["stem_context_id"] !== null) {
        const context = responseContext.data.data.filter(
          (item) => item.id === arrayStem[i].stem["stem_context_id"]
        );
        setQuestion((question) => [
          ...question,
          {
            id: arrayStem[i].stem_id,
            statement: arrayStem[i].stem["statement"].replace(
              /<a /g,
              '<a target="blank" '
            ),
            solution_body: arrayStem[i].stem["solution_body"].replace(
              /<a /g,
              '<a target="blank" '
            ),
            theme_id: arrayStem[i].stem["period_theme_id"],
            stem_context_id: arrayStem[i].stem["stem_context_id"],
            answer: arrayStem[i].stem["is_multi_choice"] === 1 ? ans : stemChoice,
            context: context[0].body.replace(/<a /g, '<a target="blank" '),
            correct: arrayStem[i].is_correct,
            marked: arrayStem[i].is_marked,
            multioption: arrayStem[i].stem["is_multi_choice"],
            url_source:
              arrayStem[i].stem["url_source"] !== null
                ? arrayStem[i].stem["url_source"]
                : null,
          },
        ]);
      } else {
        setQuestion((question) => [
          ...question,
          {
            id: arrayStem[i].stem_id,
            statement: arrayStem[i].stem["statement"].replace(
              /<a /g,
              '<a target="blank" '
            ),
            solution_body: arrayStem[i].stem["solution_body"].replace(
              /<a /g,
              '<a target="blank" '
            ),
            theme_id: arrayStem[i].stem["period_theme_id"],
            stem_context_id: arrayStem[i].stem["stem_context_id"],
            answer:
              arrayStem[i].stem["is_multi_choice"] === 1 ? ans : stemChoice,
            context: null,
            correct: arrayStem[i].is_correct,
            marked: arrayStem[i].is_marked,
            multioption: arrayStem[i].stem["is_multi_choice"],
            url_source:
              arrayStem[i].stem["url_source"] !== null
                ? arrayStem[i].stem["url_source"]
                : null,
          },
        ]);
      } //if contetx
    } //for
    setLoading(false);
    setModalVisibleInfo(false);
    setLoadings(false);
  }; //fin de la funcion onload

  const multiAnswer=(array) =>{
    const search = array.reduce((acc, answers) => {
      acc[answers.stem_id] = ++acc[answers.stem_id] || 0;
      return acc;
    }, {});
    
    const duplicate = array.filter( (answers) => {
      return search[answers.stem_id];
    });
    return duplicate;
}
const deleteDuplicate = (answer) => {
  let hash = {};
  let answers = answer.filter((o) =>
    hash[o.stem_id] ? false : (hash[o.stem_id] = true)
  );
  return answers;
}; 


  const onChangeExplanation = (e) => {
    replaceExplanation(e.target.name - 1, e.target.checked);    
  };

    //método que guarda las respuestas simples
    const replaceExplanation = (indexToReplace, newItem) => {
      const updatedItems = [...explanation];
      updatedItems.splice(indexToReplace, 1, newItem);
      return setExplanation(updatedItems);
    };

  const onChangeContext = (e) => {
    setContext(e.target.checked);
  };


  const sizeChangeLogic = (current, size) => {
    setSize = true;
    setCurrent(1);
  };

  const onChangeLogic = (current) => {
    setCurrent(current);
  };

  const onChangeCurrent = (current) => {
    if( current < totalStem){
      setCurrent(current + 1);
    }
  };

  const onSend = () => {
    message.destroy();
    if(typeTest.type === 'custom'){
     setLocation("/historico");
    }
    if(typeTest.type === 'official'){
      setLocation("/historico/ensayo");
      }
    
  };

  return (
    <div className="max-width mt-40 unselectable ">
      <div className="main-check-grid " ref={node}>
        <Card
          className="mt-20 cols-1"
          title={control.subjectName}
          bordered={false}
        >
          {typeof question != "undefined" ? (
            question
              .filter((item, i) => i + 1 === current)
              .map((item, i) => (
                <Card key={`key-essay-check${current}`}>
                  <div className="pxy-10">
                    <div className="pl-10">
                      <Button type="primary">
                        {t("titleQuestion")} {current}
                      </Button>
                      <div className="container-buttons-register" ref={node}>
                        {item.context !== null ? (
                          <div>
                            <div className="row">
                              <div className="col-80">
                                <p className="c-black mt-10">
                                  {item.stem_context_title}
                                </p>
                              </div>
                              <div className="col-20">
                                <Form.Item>
                                  <Checkbox
                                    name="context"
                                    onChange={onChangeContext}
                                    defaultChecked={true}
                                  >
                                    {t("showContext")}
                                  </Checkbox>
                                </Form.Item>
                              </div>
                            </div>
                            {context === true ? (
                              <div
                                className="main-ensayos-context mt-10"
                                dangerouslySetInnerHTML={{
                                  __html: item.context,
                                }}
                              />
                            ) : null}
                            <Divider />
                          </div>
                        ) : null}
                      </div>
                      <div
                        className="mt-20"
                        dangerouslySetInnerHTML={{ __html: item.statement }}
                      />
                      <p className="main-ensayos-button c-black">
                        {item.correct ? "Correcta" : "Incorrecta"}
                      </p>

                      {item.multioption === 0 ? (
                        <Space direction="vertical">
                          <div
                            className="mt-20 stem"
                            style={{   whiteSpace: "initial", textAlign:"justify"}}
                            dangerouslySetInnerHTML={{ __html: item.answer }}
                          />
                        </Space>
                      ) : (
                        <Space direction="vertical">
                          <div
                            className="mt-20 stem"
                            style={{   whiteSpace: "initial", textAlign:"justify"}}
                            dangerouslySetInnerHTML={{ __html: item.answer }}
                          />
                        </Space>
                      )}
                      <Divider></Divider>
                      <div>
                      <Form.Item noStyle>
                        <Checkbox
                              value={explanation[current - 1]}
                              id={current}
                              name={current}
                              onChange={onChangeExplanation}
                            >
                              {t("explanation")}
                            </Checkbox>
                           
                            {explanation[current-1] === true ? (
                              <div
                                className="mt-20 unselectable main-ensayos-context"
                                dangerouslySetInnerHTML={{
                                  __html: item.solution_body,
                                }}
                              />
                            ) : null}
                            <Button type="primary"  id={i+1} name={i+1} style={{ float: "right" }}
                                 onClick={()=> onChangeCurrent(current)}>
                                  {t('btnNext')}
                                  </Button>

                      </Form.Item>
                       </div>
                      {item.url_source !== null ? (
                        <div>
                          <Divider></Divider>
                          <div className="mt-5">
                            <a className="montserrat-9">
                            {t("informationTakenFrom")}{" "}
                              <button
                                className="url montserrat-9"
                                onClick={() =>
                                  window.open(item.url_source, "_blank")
                                }
                              >
                                {t("here")}
                              </button>
                            </a>
                          </div>
                        </div>
                      ) : null}
                     
                    </div>
                  </div>
                </Card>
              ))
          ) : (
            <h1>Loading..</h1>
          )}

          <div className="pxy-30">
            <div className="main-ensayos-pagination-questions mt-15 mb-15">
              <Pagination
                current={current}
                onShowSizeChange={sizeChangeLogic}
                onChange={onChangeLogic}
                showSizeChanger={false}
                total={data.length * 10}
              />
            </div>

            <div className="main-ensayos-buttons-questions">
              <Button type="primary" onClick={onSend}>
                {t("btnfinalizar")}
              </Button>
            </div>
          </div>
        </Card>

        <Card className="mt-20 cols-2" title={t("status")} bordered={false}>
          <div className="pxy-30 ">
            <Table
              size="small"
              pagination={true}
              columns={columns}
              dataSource={data}
            />
          </div>
        </Card>
      </div>
      <Modal
        title={t("msginfo")}
        style={{ top: 30 }}
        open={modalVisibleInfo}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <h3>{t("paragraph1")}</h3>
        <br/>
        <p >{t("paragraph10")}</p>
        <p className="mt-5">{t("paragraph2")}</p>
        <p className="mt-5">{t("paragraph3")}</p>
        <p className="mt-5">{t("paragraph4")}</p>
        <p className="mt-5">{t("paragraph5")}</p>
        {control.standartTestId === 2 ? (
          <p className="mt-5">{t("paragraphRule")}</p>
        ) : (
          <p className="mt-5">{t("paragraphRulePSU")}</p>
        )}        
        <Divider></Divider>
        <br/>
        <h3>{t("paragraph6")}</h3>
        <br/>
        <p>{t("paragraph7")}</p>
        <p className="mt-5">{t("paragraph8")}</p>
        <p className="mt-5">{t("paragraph9")}</p>
        <br/>
        <LinearProgress color="primary" />
        <br/>
        {loadings === false ? (
          <span>
            <Button
              type="primary"
              style={{ float: "right" }}
              onClick={() => setModalVisibleInfo(false)}
            >
              {t('btncontinue')}
            </Button>
            <br/>
          </span>
        ) : null}
        <br/>
      </Modal>
    </div>
  );
};

export default EssaysCheck;
