import { useContext } from "react";
import HeaderPage from "../../Organisms/HeaderPage/HeaderPage";
import CardThemeControlHistorical from "../../Organisms/CardThemeControlHistorical/CardThemeControlHistorical";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../auth/AuthContext";

const ThemesHistorical = () => {
  const { t } = useTranslation();
  const { control } = useContext(AuthContext);
 
  return (
    <div className="max-width">
        <HeaderPage
          withMenu
          contentButton={t("contentButton")}
          routeButton="/materias"
          contentTitle={t("contentTitle")}
          contentSubtitle={t("contentSubtitle")+' '+control.subjectName}
        />
      
      
      <CardThemeControlHistorical />
      
    </div>
  );
};

export default ThemesHistorical;
