import "./AppLayout.scss";

import PageHeader from "../../Organisms/PageHeader/PageHeader";
import PageFooter from "../../Organisms/PageFooter/PageFooter";

export const AppLayout = ({ children }) => {
  return (
    <div className="main-page-app">
      <PageHeader />
        <main className="main-page-content">{children}</main>
      <PageFooter />
    </div>
  );
};
