import Repository from './repository.js'

export default class GradeRepository extends Repository {
    constructor(apis) {
        super(apis)
    }

    showGrades(params = {}){
       return this.$axios
            .get('api/grades', {params: params})
    }
}


