import Repository from './repository.js'
export default class StateRepository extends Repository {
    constructor(apis) {
        super(apis)
    }

      
    showStates(){
       return this.$axios            
            .get('api/states')
            
    }

    findState(stateId){
        return this.$axios            
             .get('api/states/'+stateId)
             
    }

    findCity(stateId){
        return this.$axios            
             .get('api/states/'+stateId+'/cities')
             
    }
     
}
