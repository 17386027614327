import Repository from './repository.js'
export default class ThemeRepository extends Repository {
    constructor(apis) {
        super(apis)
    }

    getSubjectThemes (params = {}) {
        return this.$axios.get(
          'api/theme',{params: params}
        )
    }

    getPeriodThemes(params = {}){
        return this.$axios.get(
          'api/theme-period',{params: params}
        )
    }

    getStemsTheme(params = {}){
        return this.$axios            
            .get('api/period-themes/stems',{params: params})
    }

    getThemeContext(params = {}){
        return this.$axios            
            .get('api/period-themes/context',{params: params})
    }

    

    
    
          

    
}