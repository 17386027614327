import { Redirect, Route } from 'wouter';
import PropTypes from 'prop-types'

const Public = ({
  isAuthenticated,
  component: Component,
  ...rest}) => {  
  return (
 
    <Route { ...rest }
            component={ (props) => (
                  ( isAuthenticated  )
                    ? ( <Redirect to="/" /> )
                    : ( <Component { ...props } /> )
            )}
        
        />
    
  )
}
Public.propTypes = {
  isAuthenticated: PropTypes.bool,
  component: PropTypes.func.isRequired
}

export default Public;



