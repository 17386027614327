import Repository from './repository.js'

export default class VerifyEmailRepository extends Repository {
    constructor(apis) {
        super(apis)
    }

    verifyEmail(url) {
        return this.$verifyEmail     
            .get(url)
            .then(({data}) => data)
    }
    
    resendVerifyEmail(email) {
        return this.$axios      
            .get('api/me/'+email+'/resend-verification')
            .then(({data}) => data)
    }
    
}
