import { useState, useEffect } from "react";
import "./Forgot.scss";
import { Form, Input, Button, Modal } from "antd";
import Logo from "../../Atoms/Logo/Logo";
import { ReloadOutlined } from "@ant-design/icons";

import { Link } from "wouter";
import { useTranslation } from "react-i18next";
import { userRepo } from "../../../store";

const Forgot = () => {
  const { t } = useTranslation();

  const [loadings, setLoadings] = useState();

  useEffect(() => {
    return () => {
      setLoadings();
    };
  }, []);

  const onFinish = (values) => {
    setLoadings(true);

    userRepo.forgot(values.email).then(
      (data) => {
        setLoadings(false);
        Modal.success({
          title: t("info"),
          content: data.data.status,
        });
      }, // COOKIE ERROR
      (error) => {
        setLoadings(false);

        Modal.error({
          title: t("error"),
          content: error.response.data.message,
        });
      }
    );
  };

  return (
    <div className="login_container">
      <div>
        <Logo width={"200px"} height={"100%"} customClass="mb-10 text-center" />
        <Form
          name="login"
          className="login_container_form form"
          onFinish={onFinish}
        >
          <h1 className="mb-10">{t("titleForgot")}</h1>
          <p className="mb-10">{t("contentForgot")}</p>

          <Form.Item
            label={t("email")}
            name="email"
            rules={[{ required: true, message: t("msgemail") }]}
            normalize={(value) => value.trim()}
          >
            <Input placeholder={t("email")} size="large" />
          </Form.Item>

          <div className="login_remenber_container">
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              <Link href="/login"> {t("btncancel")} </Link>
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              icon={<ReloadOutlined />}
              loading={loadings}
            >
              {t("btnsend")}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Forgot;
