
import { useEffect, useState }  from 'react'
import { useForm } from "../../../customHooks/useForm";
import { Button, Form, Input, Modal } from "antd";
import {  FormOutlined, CheckOutlined } from "@ant-design/icons";
import TitleForm from "../../Atoms/TitleForm/TitleForm";
import { useTranslation } from "react-i18next";
import { useLocation } from "wouter";
import {  userRepo  } from "../../../store";

export const PersonalDataUser = ({ dataUser }) => {  

    const { t } = useTranslation(); 
    const [, setLocation] = useLocation(); 
    const [User, setUser] = useState({
        id: "",
        address_id: "",
        name: "",
        last_name: "",
        password: "",
        email: "",
        tax_id: "",
        phone: "",
        timezone: "",
        language: "",
        terms_and_conditions: "",
      });
    let {
        name,
        last_name,
        email,
        tax_id,
        phone
    } = dataUser;
    
    const [formValues, handleInputChange] = useForm();
    const [editPersonal, setPersonal] = useState(false);
   
    const handlePersonalActive = () => {
      setPersonal(true);
    };
  
    const handlePersonal = () => {
      setPersonal(false);
    };
   
    const onFinishPersonal = () => {
        if (isNaN(formValues.phone) === false || formValues.phone === undefined ) {
          userRepo
            .editUserAll({
              name: formValues.name,
              last_name: formValues.last_name,
              email: formValues.email,
              phone: formValues.phone,
              timezone: formValues.timezone,
              language: formValues.language,
              terms_and_conditions: formValues.terms_and_conditions,
            })
            .then(
              (data) => {
                setUser({
                  address_id: data.data.address_id,
                  name: data.data.name,
                  last_name: data.data.last_name,
                  tax_id: data.data.tax_id,
                  email: data.data.email,
                  phone: data.data.phone,
                });
                Modal.info({
                  title: t("info"),
                  content: t("msgUpdate"),
                });
                setPersonal(false);                
                
              },
              // GET USER ERROR
              (error) => {
                if (error.response.status === 422) {
                  Modal.error({
                    title: t("error"),
                    content: t("msgRequired"),
                  });
                }
    
                if (error.response.status === 500) {
                  Modal.error({
                    title: t("error"),
                    content: t("msgRequired"),
                  });
                  setPersonal(false);
                }
    
                if (error.response.status === 401) {
                  Modal.error({
                    title: t("error"),
                    content: error,
                  });
                  setLocation("/login");
                }
              }
            );
        } else {
          Modal.error({
            title: t("error"),
            content: t("messagePhone"),
          });
        }
        setLocation("/cuenta/editar");
       
      };
    

  return (
    <>
        {editPersonal=== true ? (
          <div className="profile-form-row">
            <p className="montserrat-14 mb-5 ">
              <span className="montserrat-14 bold c-blue-primary">
                {t("content1")}
                <CheckOutlined
                  type="primary"
                  style={{ padding: "3px", fontSize: "26px", color: "#0C4393" }}
                  onClick={handlePersonal}
                />
              </span>
            </p>
            <TitleForm />
            <div className="profile-form-row-grid mb-5">
              <Form.Item
                label={
                  <p style={{ fontSize: "20px", color: "red" }}>
                    *
                    <span style={{ fontSize: "14px", color: "#030E1F" }}>
                      {t("nombre")}
                    </span>
                  </p>
                }
                name="name"
                rules={[
                  { pattern: "^[a-zA-Z]*$", message: t("msgcharacters") },
                ]}
              >
                <Input
                  defaultValue={name}
                  key={name}
                  name="name"
                  onChange={handleInputChange}
                />
              </Form.Item>
              <Form.Item
                label={
                  <p style={{ fontSize: "20px", color: "red" }}>
                    *
                    <span style={{ fontSize: "14px", color: "#030E1F" }}>
                      {t("apellido")}
                    </span>
                  </p>
                }
                name="last_name"
                rules={[
                  { pattern: "^[a-zA-Z]*$", message: t("msgcharacters") },
                ]}
              >
                <Input
                  name="last_name"
                  defaultValue={last_name}
                  key={last_name}
                  value={last_name}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </div>

            <div className="profile-form-row-grid mb-5">
              <Form.Item
                label={
                  <p style={{ fontSize: "20px", color: "red" }}>
                    *
                    <span style={{ fontSize: "14px", color: "#030E1F" }}>
                      Rut
                    </span>
                  </p>
                }
                name="tax_id"
              >
                <Input
                  name="tax_id"
                  defaultValue={tax_id}
                  key={tax_id}
                  value={tax_id}
                  disabled
                  onChange={handleInputChange}
                />
              </Form.Item>
              <Form.Item
                label={
                  <p style={{ fontSize: "20px", color: "red" }}>
                    *
                    <span style={{ fontSize: "14px", color: "#030E1F" }}>
                      Email
                    </span>
                  </p>
                }
                name="email"
                rules={[{ type: "email", message: t("msgemailvalid") }]}
              >
                <Input
                  name="email"
                  defaultValue={email}
                  key={email}
                  value={email}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </div>

            <div className="profile-form-row-grid mb-5">
              <Form.Item
                label={
                  <p style={{ fontSize: "20px", color: "red" }}>
                    *
                    <span style={{ fontSize: "14px", color: "#030E1F" }}>
                      No. Móvil
                    </span>
                  </p>
                }
                name="phone"
                rules={[{ pattern: "^[0-9]*$", message: t("msgnumeric") }]}
              >
                <Input
                  name="phone"
                  defaultValue={phone}
                  maxLength={11}
                  key={phone}
                  value={phone}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </div>
            <div className="profile-form-buttons mt-30">
              <Button
                type="primary"
                htmlType="submit"
                onClick={onFinishPersonal}
              >
                {t("btnguardar")}
              </Button>
            </div>
            <TitleForm />
          </div>
        ) : (
          <div className="profile-form-row">
            <p className="montserrat-14 mb-5 ">
              <span className="montserrat-14 bold c-blue-primary">
                {t("content1")}
                <FormOutlined
                  type="primary"
                  style={{ padding: "3px", fontSize: "26px", color: "#0C4393" }}
                  onClick={handlePersonalActive}
                />
              </span>
            </p>
            <TitleForm />
            <div className="profile-form-row-grid mb-5">
              <Form.Item label={t("nombre")}>
                <Input value={name} disabled />
              </Form.Item>
              <Form.Item label={t("apellido")}>
                <Input value={last_name} disabled />
              </Form.Item>
            </div>

            <div className="profile-form-row-grid mb-5">
              <Form.Item label="Rut">
                <Input value={tax_id} disabled />
              </Form.Item>
              <Form.Item label="Email">
                <Input value={email} disabled />
              </Form.Item>
            </div>

            <div className="profile-form-row-grid mb-5">
              <Form.Item label="No. Móvil">
                <Input value={phone} disabled />
              </Form.Item>
            </div>
          </div>
        )}
  
  
  </>
  );
};

export default PersonalDataUser;

