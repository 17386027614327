import { useState } from 'react';


export const useCheck = (initialState) => {
    
    const [checked, setChecked] = useState(initialState);
 
    const handleChange = () => {
        setChecked(!checked);
    }; 


    return [ checked, handleChange ];

}