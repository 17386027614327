import { Divider } from "antd";

const TitleForm = ({ content }) => {
  return (
    <div>
      <p className="montserrat-15 mb-20 bold c-blue-primary">{content}</p>
      <Divider />
    </div>
  );
};

export default TitleForm;
