import { useContext, useEffect, useState } from "react";
import { Button, Modal } from "antd";
import HeaderPage from "../../Organisms/HeaderPage/HeaderPage";
import ChartsGauss from "../../Organisms/ChartsGauss/ChartsGauss";
import CardAverageListSubject from "../../Molecules/CardAverageListSubject/CardAverageListSubject";
import ChartsRadialAntd from "../../Organisms/ChartsRadialAntd/ChartsRadialAntd";
import ChartsColumnSubject from "../../Organisms/ChartsColumnSubject/ChartsColumnSubject";
import GeneralAverageBySubject from "../../Organisms/GeneralAverageBySubject/GeneralAverageBySubject";
import BannerStatistics from "../../Organisms/BannerStatistics/BannerStatistics";
import { AuthContext } from "../../../auth/AuthContext";
import { useTranslation } from "react-i18next";
import { useLocation } from "wouter";
import { types } from "../../../types/types";
import {
  periodRepo,
  subjectRepo,
  userTestRepo,
  stemRepo,
} from "../../../store";
import "./Statistics.scss";

const Statistics = () => {
  const { t } = useTranslation();
  const {
    control,
    user,
    subcription,
    dispatchWrong,
    dispatchPageHeader,
  } = useContext(AuthContext);
  const [, setLocation] = useLocation();
  const [duration, setDuration] = useState(0);
  const [, setCleanup] = useState();

  useEffect(() => {
    onLoad();
    return () => {
      setCleanup();
    };
  }, []);

  const onLoad = async () => {
    var theme = [];
    periodRepo.findPeriod(control.periodId).then(({ data }) => {
      setDuration(parseInt(data.test_duration)*60);
    });   

    const response = await subjectRepo.getSubjectThemes(
      control.subjectId,
      control.periodId
    );

    for (let i = 0; i < response.data.data.total; i++) {
      theme[i] = response.data.data[i].id;
    }
    setCleanup(false);
  };

  const handleGeneral = async () => {
    const responseStemWrong = await userTestRepo.getQuestionIncorrect(
      control.subjectId,
      {
        user_id: null,
        only_correct: 0,
        only_incorrect: 1,
      }
    );
    const ans = [];
    for (let j = 0; j < responseStemWrong.data.data.length; j++) {
      ans.splice(ans.length, 0, responseStemWrong.data.data[j].stem_id);
    }
    if (responseStemWrong.data.data.length !== 0) {
      dispatchWrong({
        type: types.wrong,
        payload: {
          url: stemRepo.getStemsUserTest({ stem_ids: ans }),
          seconds: duration,
        },
      });
      dispatchPageHeader({
        type: types.pageheader,
        payload: {
          active: 0,
        },
      });
      setLocation("/revision");
    } else {
      Modal.info({
        title: t("info"),
        content: t("msgAnswerGeneral"),
      });
    }
  };

  const handleDetails = async () => {
    const responseStemWrong = await userTestRepo.getQuestionIncorrect(
      control.subjectId,
      {
        user_id: user.userId,
        only_correct: 0,
        only_incorrect: 1,
      },
      (error) => {
        if (error.response.status === 401) {
          Modal.error({
            title: t("error"),
            content: error,
          });
          setLocation("/login");
        } else {
          Modal.error({
            title: t("error"),
            content: error,
          });
        }
      }
    );
    const ans = [];
    for (let j = 0; j < responseStemWrong.data.data.length; j++) {
      ans.splice(ans.length, 0, responseStemWrong.data.data[j].stem_id);
    }
    if (responseStemWrong.data.data.length !== 0) {
      dispatchWrong({
        type: types.wrong,
        payload: {
          url: stemRepo.getStemsUserTest({ stem_ids: ans }),
          seconds: duration,
        },
      });
      dispatchPageHeader({
        type: types.pageheader,
        payload: {
          active: 0,
        },
      });
      setLocation("/revision");
    } else {
      Modal.info({
        title: t("info"),
        content: t("msgAnswerDetails"),
      });
    }
  };

  return (
    <div className="max-width">
     
        <HeaderPage
          withMenu
          contentButton={t("contentButtonStatistics")}
          routeButton="/materias"
          contentTitle={t("contentTitleStatistics")}
          contentSubtitle={
            t("contentSubtitleStatistics") + " " + control.subjectName
          }
        />
      
      <div className="page-statistics">
      <div className="page-statistics_grid max-width">
        <div className="cols-1">
          <BannerStatistics />
        </div>
        <div className="cols-2">
          <GeneralAverageBySubject />     
        </div>
      </div>          
     
      <div className="row  mt-15 max-width">
      <div className="col-statistics col-40 mt-15 mr-5" style={{background: "white"}}>
        <ChartsGauss />
        </div>        
        <div className="col-statistics col-40 mt-15">
        <ChartsRadialAntd />
        </div>
      </div>
      <div className="row mt-15 max-width">
      <div className="col-statistics col-40 mt-15 mr-5">
        <ChartsColumnSubject />
        </div>
        <div className="col-statistics col-40 mt-15" style={{background: "white"}}>
        <CardAverageListSubject />
        </div>
      </div>
      {subcription.active === 1 ? (
        <div className="container-buttons-register">
          <Button type="primary"
            className="style_button_statistics"
            onClick={() => handleGeneral()}>
            {t("btngeneral")}
          </Button>
          <Button type="primary" 
          className="style_button_statistics"
          onClick={() => handleDetails()}>
            {t("btnerrores")}
          </Button>
        </div>
      ) : null}
    </div>
    </div>
  );
};

export default Statistics;
