import "./GeneralAverage.scss";
import React, { useState, useEffect } from "react";
import averageImg from "../../../assets/average.svg";
import { Card, Modal } from "antd";
import { useTranslation } from "react-i18next";
import {  userTestRepo } from "../../../store";
import { useLocation } from "wouter";

const GeneralAverage = () => {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const [averageList, setAverageList] = useState({correct:0, count:0});
  const [, setCleanup] = useState();

  useEffect(() => {
    onload();
    return () => {
      setCleanup();
    };
  }, []);

  const onload = () => {
      userTestRepo.getStemsCorrectGeneral().then(({data})=>{
        if(data.length !== 0){    
          setAverageList({average:Math.round(data[0].sumCorrect*100 /data[0].sumTotal), correct:data[0].sumCorrect, count:data[0].sumTotal});        
        }
    },
     
        (error) => {
          if (error.response.status === 401) {
            Modal.error({
              title: t("error"),
              content: error,
            });
            setLocation("/login");
          } else {
            Modal.error({
              title: t("error"),
              content: error,
            });
          }
        }
      );

      setCleanup(false);
    
  };  

  return (
    <Card title={t("promedio")} bordered={false}>
      <div className="main-average_icon">
        <img src={averageImg} alt="Average" />
        <p className="montserrat-50 bold">{averageList.average} %</p>
        <ol
            
            className="card-average-list"
            style={{ listStyleType: "none" }}
          >
            
            <li>
              {" "}
              {t("questionCorrectAverage")} : {averageList.correct} 
            </li>
            <li>
              {" "}
              {t("questionTotalAverage")} : {averageList.count} 
            </li>
          </ol>
      </div>
      
    </Card>
  );
};

export default GeneralAverage;
