import Repository from './repository.js'
export default class StemRepository extends Repository {
    constructor(apis) {
        super(apis)
    }
    
    getStems(params = {}){        
        return this.$axios            
             .get('api/stems',{params: params})
    }

    getStemsContext(params = {}){        
        return this.$axios            
             .get('api/stems/contexts',{params: params})
    }

    getStemsEnsayo(params = {}){        
        return this.$axios            
             .get('api/stems/ensayo',{params: params})
    }

    getStemsUserTest(params = {}){        
        return this.$axios            
             .get('api/stems/user-test-stems',{params: params})
    }
    
    getStemsTheme(stemId){
        return  this.$axios            
             .get('api/user-tests/'+stemId+'/stems')
    }
    
}