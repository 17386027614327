import { Link, useRoute } from "wouter";

const ActiveLinkD = ({ href, type, children }) => {
  
  const [isActive] = useRoute(href);  

    return (
    <li className="main-menu_item ">
      <Link
        href={href}
        className={isActive ? "active" : ""}
      >
        {children}
      </Link>
    </li>
  );
};

export default ActiveLinkD;
