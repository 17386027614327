import React from "react";
import { HourglassOutlined } from "@ant-design/icons";
import { useTimer } from "react-timer-hook";

export const Timer = ({ expiryTimestamp, onSubmitExam }) => {
  const { seconds, minutes, hours } = useTimer({
    expiryTimestamp,
    onExpire: () => onSubmitExam(),
  });
  return (
    <div>
      <HourglassOutlined /> {hours}:{minutes}:{seconds}
    </div>
  );
};


