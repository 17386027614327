import { useState } from "react"

export const useVisible = (initalState = false) => {
  const [visible, setVisible] = useState(initalState)

  const handleVisible = () => {
    setVisible(true)
  }
  const closeVisible = () => {
    setVisible(false)
  }

  return [ visible, handleVisible, closeVisible ]
}
