
import { useTranslation } from "react-i18next";
import { GlobalOutlined } from "@ant-design/icons";
import "./MenuButtonTranslate.scss";

const MenuButtonTranslate = () => {
  const { i18n } = useTranslation();

  const  changeLanguage = (lang) => {    
    localStorage.setItem('lang',lang);
    i18n.changeLanguage(lang);
  }

  return (
    <nav className="menu-translate">
      <ul className="menu-translate_list">      
        <div>
        {localStorage.getItem('lang') === "en" ?
          <button  className="menu-translate-button" onClick={() =>changeLanguage('es')}>
            {" "}
            <GlobalOutlined style={{ width: "20px" }} />
            es
          </button>         
          :
          <button className="menu-translate-button" onClick={() =>changeLanguage('en')}>
            {" "}
            <GlobalOutlined style={{ width: "20px" }} />
            en
          </button>
           }
        </div>      
      </ul>
    </nav>
  );
};

export default MenuButtonTranslate;
