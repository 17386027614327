export const setLocalCurrentUser = (name, lastName, avatar, email) => {
    const Cookies = document.cookie.split("=");
    if (Cookies) {
        localStorage.setItem("username", name + " " + lastName);
        localStorage.setItem("isAuthenticated", true);
        localStorage.setItem("avatar", avatar);  
        localStorage.setItem("email", email);       
    }
}

export const isAuthenticated = () => {
    return localStorage.getItem("isAuthenticated") === true;
}

export const handleCloseSession = () => {
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("username");
    localStorage.removeItem("avatar");
    localStorage.removeItem("email");
    localStorage.removeItem("redirect");
    localStorage.removeItem("header");    
};
