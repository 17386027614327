import Repository from './repository.js'
export default class TownRepository extends Repository {
    constructor(apis) {
        super(apis)
    }
    
    findTown(townId){
        return this.$axios            
             .get('api/towns/'+townId)             
    }

}

