import React from "react";
import { Button } from "antd";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography   
} from "@material-ui/core";
import "./ModalAntd.scss";
import { useTranslation } from "react-i18next";
  

const ModalAntd = () => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <button className="page-modal" onClick={handleClickOpen("paper")}>
        <p className="montserrat-15">{t('terminos')}</p>
      </button>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle className="page-modal-color" id="scroll-dialog-title">
        {t("terminos")}
        </DialogTitle>
        <DialogContent
          className="page-modal-color"
          dividers={scroll === "paper"}
        >
          <DialogContentText
            id="scroll-dialog-description"
            className="page-modal-color"
            ref={descriptionElementRef}
            tabIndex={-1}
            component={'span'}
          >
           <Typography component={'div'} className="montserrat-15 mb-10"  style={{ textAlign: "justify", fontSize:10 }}>
           {t('paragraphTC1')}
            </Typography>
            <Typography  component={'div'} className="montserrat-15 mb-10"  style={{ textAlign: "justify", fontSize:10 }}>
            {t('paragraphTC2')}
            </Typography>
            <Typography component={'div'} className="montserrat-15 mb-10"  style={{ textAlign: "justify", fontSize:10 }}>
            {t('paragraphTC3')}
            </Typography>
            <Typography component={'div'} className="montserrat-15 mb-10"  style={{ textAlign: "justify", fontSize:10 }}>
            {t('paragraphTC4')}
            </Typography>
            <Typography component={'div'}  className="montserrat-15 mb-10"  style={{ textAlign: "justify", fontSize:10 }}>
            {t('paragraphTC5')}
            </Typography>
            <Typography  component={'div'} className="montserrat-15 mb-10"  style={{ textAlign: "justify", fontSize:10 }}>
            {t('paragraphTC6')}
            </Typography>
            <Typography component={'div'}  className="montserrat-15 mb-10"  style={{ textAlign: "justify", fontSize:10 }}>
            {t('paragraphTC7')}
              <Typography
                component={'div'} 
                className="montserrat-15 mb-10"
                 style={{ textAlign: "justify", fontSize:10 }}
              >
                {t('paragraphTC7a')}
              </Typography>
              <Typography
                component={'div'} 
                className="montserrat-15 mb-10"
                 style={{ textAlign: "justify", fontSize:10 }}
              >
               {t('paragraphTC7b')}
              </Typography>
              <Typography
                component={'div'} 
                className="montserrat-15 mb-10"
                 style={{ textAlign: "justify", fontSize:10 }}
              >
                {t('paragraphTC7c')}
              </Typography>
            </Typography>
            <Typography component={'div'}  className="montserrat-15 mb-10"  style={{ textAlign: "justify", fontSize:10 }}>
            {t('paragraphTC8')}
            </Typography>
            < Typography component={'div'}  className="montserrat-15 mb-10"  style={{ textAlign: "justify", fontSize:10 }}>
            {t('paragraphTC9')}
            </ Typography>
            <Typography  component={'div'} className="montserrat-15 mb-10"   style={{ textAlign: "justify", fontSize:10 }}>
            {t('paragraphTC10')}
            </Typography>
            <Typography component={'div'}  className="montserrat-15 mb-10"  style={{ textAlign: "justify", fontSize:10 }}>
            {t('paragraphTC11')}
            </Typography>
            <Typography component={'div'}  className="montserrat-15 mb-10"  style={{ textAlign: "justify", fontSize:10 }}>
            {t('paragraphTC12')}
            </Typography>
            <Typography  component={'div'} className="montserrat-15 mb-10"  style={{ textAlign: "justify", fontSize:10 }}>
            {t('paragraphTC13')}
            </Typography>
            <Typography component={'div'} className="montserrat-15 mb-10"  style={{ textAlign: "justify", fontSize:10 }}>
            {t('paragraphTC14')}
            </Typography>
            <Typography  component={'div'} className="montserrat-15 mb-10"   style={{ textAlign: "justify", fontSize:10 }}>
            {t('paragraphTC15')}
            </ Typography>
            < Typography  component={'div'} className="montserrat-15 mb-10"  style={{ textAlign: "justify", fontSize:10 }}>
            {t('paragraphTC16')}
            </Typography>
          </DialogContentText>
        </DialogContent>

        <DialogActions className="page-modal-color">
          <Button onClick={handleClose} type="primary">
            {t('btncancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalAntd;
