import { types } from '../types/types'

const initialState = {
  value: '',
  userTest: ''
}

export const historicalReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.historical:
      return {
        ...action.payload
      }

    default:
      return state
  }
}
