import {
  Button,
  Card,
  Form,
  InputNumber,
  Radio,
  Spin,
  Space,
  Switch,
  Table,
} from "antd";
import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "wouter";
import moment from "moment";
import "./CardThemeControlHistorical.scss";
import { useCheck } from "../../../customHooks/useCheck";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../auth/AuthContext";
import { types } from "../../../types/types";
import { userTestRepo, userTestStemRepo } from "../../../store";

const CardThemeControlHistorical = () => {
  const { t } = useTranslation();

  const columns = [
    {
      title: "",
      dataIndex: "select",
      key: "select",
    },
    {
      title: t("theme"),
      dataIndex: "theme",
      key: "theme",
    },
    {
      title: t("question"),
      dataIndex: "cant",
      key: "cant",
    },
  ];

  const [, setLocation] = useLocation();
  const { control, dispatchControl, dispatchPageHeader } =
    useContext(AuthContext);
  const { historical } = useContext(AuthContext);

  const [questionselect, ] = useState(0);
  const [questionstart, ] = useState(0);
  const [questionfinal, ] = useState(0);
  const [checkedQuestion, handleChangeQuestion] = useCheck(false);
  const [checkedStudy, handleChangeStudy] = useCheck(true);
  const [checkedAnswer, handleChangeAnswer] = useCheck(false);
  const [checkedTimer, handleChangeTimer] = useCheck(false);
  const [value, setValue] = useState(1); //radio group
  const [duration, setDuration] = useState(0);
  const [loading, setLoading] = useState(true);
  const [, setCleanup] = useState();
  const [theme, setTheme] = useState([]);
  const [themeIdQuanty, setThemeIdQuanty] = useState([]);

  useEffect(() => {
    onload();
    setLoading(false);
    return () => {
      setCleanup();
    };
  }, []);

  const onload = () => {
    userTestRepo.showUserTest(historical.userTest).then(({ data }) => {
      setDuration(moment(data.end).diff(moment(data.start), "minutes"));
    });

    userTestRepo.userTestTheme(historical.userTest).then(({ data }) => {
      for (let i = 0; i < data.data.length; i++) {
        setTheme((theme) => [
          {
            key: data.data[i].id,
            select: "",
            theme: data.data[i].name,
            cant: Math.round(
              parseInt(data.data[i].pivot.correct_quantity) +
                parseInt(data.data[i].pivot.incorrect_quantity) +
                parseInt(data.data[i].pivot.unanswered_quantity)
            ),
          },
          ...theme,
        ]);
        setThemeIdQuanty((themeIdQuanty) => [
          ...themeIdQuanty,
          {
            id: data.data[i].id,
            quantity: Math.round(
              parseInt(data.data[i].pivot.correct_quantity) +
                parseInt(data.data[i].pivot.incorrect_quantity) +
                parseInt(data.data[i].pivot.unanswered_quantity)
            ),
          },
        ]);
      }
    });
    setCleanup(false);
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onCancel = () => {
    setLocation("/dashboard");
  };

  const onSend = (
    value,
    questionselect,
    questionstart,
    questionfinal,
    checkedStudy,
    checkedAnswer,
    checkedTimer
  ) => {
    localStorage.setItem("render", 1);

    userTestStemRepo.getUserTestStem(historical.userTest).then(({ data }) => {
      const ans = [];
      for (let j = 0; j < data.length; j++) {
        ans.splice(ans.length, 0, data[j].stem_id);
      }

      var seconds = parseInt(duration * 60);

      dispatchControl({
        type: types.configuration,
        payload: {
          value: value,
          theme_id: ans,
          themeIdQuanty: themeIdQuanty,
          limit: questionselect,
          to: questionstart,
          from: questionfinal,
          randomQuestion: checkedQuestion,
          randomAnswer: checkedAnswer,
          studyMode: checkedStudy,
          timer: checkedTimer,
          seconds: seconds,
          urlStem: "",
        },
      });

      dispatchPageHeader({
        type: types.pageheader,
        payload: {
          active: 0,
        },
      });

      localStorage.setItem("header", 0);
      setLocation("/test/historial");
    });
  };

  return (
    <Card className="mt-20" title={control.subjectName} bordered={false}>
      {loading ? (
        <div className="mt-60">
          <Space
            direction="horizontal"
            style={{ width: "100%", justifyContent: "center" }}
          >
            <Spin tip={t("loading")} size="large"></Spin>
          </Space>
        </div>
      ) : (
        <div className="main-control-grid">
          <div className="row">
            <div className="col ">
              <div className="pxy-40">
                <Radio.Group
                  onChange={onChange}
                  value={value}
                  className="ant-radio-group"
                >
                  <div className="row">
                    <div className="col">
                      <Radio value={1} checked disabled>
                        {t("radio1")}
                      </Radio>
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: 30 }}>
                    <div className="col-historical col-60">
                      <Form.Item>
                        {" "}
                        <Radio value={2} disabled>
                          {t("radio21")}
                        </Radio>{" "}
                      </Form.Item>
                    </div>
                    <div
                      className="col-historical col-40"
                      style={{ marginLeft: -2 }}
                    >
                      <Form.Item>
                        <InputNumber name="selectquestion" disabled />
                        {/* <p>{t("radio22")}</p> */}
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-historical col-50">
                      <Radio value={3} disabled>
                        {t("radio31")}
                      </Radio>
                    </div>
                    <div className="col-historical col-05">
                      <Form.Item>
                        <InputNumber name="questionstart" disabled />
                      </Form.Item>
                    </div>
                    <div className="col-historical col-40">
                      <Form.Item>
                        <p
                          style={{ marginLeft: 15, color: "hwb(0deg 60% 40%)" }}
                        >
                          {t("radio32")}
                        </p>
                      </Form.Item>
                    </div>
                    <div className="col-historical col-05">
                      <Form.Item>
                        <InputNumber name="questionfinal" disabled />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="theme-table-row" style={{ marginTop: 5 }}>
                    <Radio value={4} disabled>
                      {t("radio4")}
                    </Radio>
                  </div>
                </Radio.Group>
              </div>
            </div>
            <div className="col pxy-30">
              <div className="row" style={{ marginTop: 10 }}>
                <div className="col col-35">
                  <p>{t("switch4")}</p>
                </div>
                <div
                  className="col col-20"
                  style={{ textAlign: "center", marginLeft: "42px" }}
                >
                  <Switch
                    style={{ width: "40px" }}
                    onChange={handleChangeTimer}
                    disabled
                  />
                  <label className="form-check-label"></label>
                </div>
                <div className="col col-45">
                  <div className="row">
                    <div className="col">
                      <p className="mb-5">
                        {t("tiempo")} {duration} min.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col col-35">
                  <p>{t("switch1")}</p>
                </div>

                <div className="col col-65">
                  <Switch
                    style={{ width: "30px" }}
                    onChange={handleChangeQuestion}
                    disabled
                  />
                  <label className="form-check-label"></label>
                </div>
              </div>

              <div className="row">
                <div className="col col-35">
                  <p>{t("switch3")}</p>
                </div>
                <div className="col col-65">
                  <Switch
                    style={{ width: "40px" }}
                    onChange={handleChangeAnswer}
                  />
                  <label className="form-check-label"></label>
                </div>
              </div>
              <div className="row">
                <div className="col col-35">
                  <p>{t("switch2")}</p>
                </div>
                <div className="col col-65">
                  <Switch
                    style={{ width: "40px" }}
                    defaultChecked
                    onChange={handleChangeStudy}
                    disabled
                  />
                  <label className="form-check-label"></label>
                </div>
              </div>
            </div>
          </div>
          <div className="row-table pxy-30">
            <div className="card-theme-table">
              <Card className="mt-30 mb-20">
                <Table
                  columns={columns}
                  dataSource={theme}
                  pagination={true}
                  size="middle"
                />
              </Card>
            </div>
          </div>
        </div>
      )}
      <div className="themes-historical-table-switchs-buttons pxy-30">
        <Button onClick={onCancel}>{t("btncancelar")}</Button>
        <Button
          type="primary"
          onClick={() => {
            onSend(
              value,
              questionselect,
              questionstart,
              questionfinal,
              checkedStudy,
              checkedAnswer,
              checkedTimer
            );
          }}
        >
          {t("btninicio")}
        </Button>
      </div>
    </Card>
  );
};

export default CardThemeControlHistorical;
