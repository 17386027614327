import "./Tooltip.scss";

const Tooltip = ({ isVisible, children, notification }) => {
  return (
    <div
      className={`main-toolip ${isVisible ? "visible" : ""} ${
        notification ? "notification" : ""
      }`}
    >
      {children}
    </div>
  );
};

export default Tooltip;
