import Repository from './repository.js'
export default class AddressRepository extends Repository {
    constructor(apis) {
        super(apis)
    }

    createAddress(params = {}) {
        
        const {address_line_1,address_line_2,town_id, zip_code,id } = params;
        
        return this.$axios            
            .post('api/addresses', {
                address_line_1: address_line_1,
                address_line_2: address_line_2,
                town_id: town_id,
                zip_code: zip_code,
                id: id,
        })
    }

    editAddress(addressId, params = {}) { 
         
        const {address_line_1, address_line_2, town_id, zip_code } = params;              
        return this.$axios            
            .post("api/addresses/"+addressId,              
            {   
                address_line_1: address_line_1,
                address_line_2: address_line_2,
                town_id: town_id,
                zip_code: zip_code
            })
            .then(({data}) => data)
    }

       

    
}

    
