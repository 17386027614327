// showCity
// findCity

import Repository from './repository.js'
export default class CityRepository extends Repository {
    constructor(apis) {
        super(apis)
    }

    getCities(cityId){
        return this.$axios            
            .get('api/cities/'+cityId)
             
    }

    findCity(cityId){
        return this.$axios            
             .get('api/cities/'+cityId+'/towns')
             
    }    

}

