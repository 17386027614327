

import AccountAvatarUserEdit from "../AccountAvatarUserEdit/AccountAvatarUserEdit";
import "./ProfileUserEditHeader.scss";

const ProfileUserEditHeader = () => {

return (
    <div className="profile-user-header">
      <AccountAvatarUserEdit />      
    </div>
  );
};

export default ProfileUserEditHeader;
