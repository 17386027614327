
import "./BannerStatistics.scss";
import image_banner from "../../../assets/image_banner.png";
import Title from "../../Atoms/Title/Title";
import { useTranslation } from "react-i18next";

const BannerStatistics = () => {
  const { t } = useTranslation();
 

  

  return (
    <div className="main-banner_statistics ">
      <div className="main-banner_image">
        <img src={image_banner} alt="Banner" />
      </div>
      <div className="main-banner_content">
        <Title content={t("titleIkengaStatistics")} customClass="mb-15 c-blue-primary" />
        <p className="mb-15 c-green-primary sm-bold" >{t("subTitleIkengaStatistics")}</p>
          
                
      </div>
    </div>
  );
};

export default BannerStatistics;
