import Repository from './repository.js'
export default class StemContextRepository extends Repository {
    constructor(apis) {
        super(apis)
    }

    getStemContext(){
        return this.$axios            
             .get('api/contexts')
    }

    
    

    
}