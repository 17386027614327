import { Card } from "antd";
import HeaderPage from "../../Organisms/HeaderPage/HeaderPage";
import "./ProfileAccountEdit.scss";
import ProfileUserEditHeader from "../../Molecules/ProfileUserEditHeader/ProfileUserEditHeader";
import FormEditProfileUser from "../../Organisms/FormEditProfileUser/FormEditProfileUser";
import { useTranslation } from "react-i18next";

const ProfileAccountEdit = () => {
  const { t } = useTranslation();

  return (
    <div className="max-width">
      <HeaderPage
        contentButton={t("contentButton")}
        routeButton="/dashboard"
        contentTitle={t("contentAccount")}
        contentSubtitle={t("contentSubtitleAccount")}
      />

      <Card className="mt-50" title={t("cardtitle")} bordered={false}>
        <div className="pxy-30">
          <ProfileUserEditHeader />
          <FormEditProfileUser />
        </div>
      </Card>
    </div>
  );
};

export default ProfileAccountEdit;
