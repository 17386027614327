import Repository from './repository.js'
export default class AuthenticationRepository extends Repository {

    constructor(apis) {
        super(apis)
    }

    login(params = {}) {
        const {email, password} = params;        
        return this.$axios            
            .post('api/login', {
                email: email,
                password: password,
            }).then(({data}) => data)
    }
}
