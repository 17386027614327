
import { useState }  from 'react'
import { Button, Form, Input, Modal, Select } from "antd";
import {  FormOutlined, CheckOutlined } from "@ant-design/icons";
import TitleForm from "../../Atoms/TitleForm/TitleForm";
import { useTranslation } from "react-i18next";
import { useLocation } from "wouter";
import {  cityRepo,  stateRepo, addressRepo, townRepo, } from "../../../store";
const { Option } = Select;


export const UbicationDataUser = ({ dataUser, address, states, stateOld, cityOld, townOld }) => {

    const { t } = useTranslation(); 
    const [, setLocation] = useLocation(); 
    const [form] = Form.useForm();
      
    const [town, setTown] = useState("");  
  
    const [cityAll, setCityAll] = useState("");
    const [townAll, setTownAll] = useState("");
      
    const [editUbication, setUbication] = useState(false);

    const { address_id   } = dataUser; 

    const onCity = async (value) => {
      const response = await stateRepo.findCity(value);
      setCityAll(response.data.data);
    };

    const onTown = async (value) => {
      const response = await cityRepo.findCity(value);
      setTownAll(response.data.data);
    };

    const handleUbicationActive = () => {
      setUbication(true);
    };
  
    const handleUbication = () => {
      setUbication(false);
    };

    const handleInputSelectTown = (value) => {
      setTown(value);
    };

    const handleInputSelectCity = (value) => {    
      onTown(value);
    };

    const handleInputSelectState = (value) => {
      form.setFieldsValue( { city: null, town: null } );    
      onCity(value);
    };
  
    const onFinishUbication = () => {
      addressRepo
        .editAddress(address_id, {
          town_id: town,
        })
        .then(
          (data) => {
            Modal.info({
              title: t("info"),
              content: t("msgUpdate"),
            });
            setUbication(false);
          },
          // GET USER ERROR
          (error) => {
            if (error.response.status === 422) {
              Modal.error({
                title: t("error"),
                content: t("msgRequired"),
              });
            }
            if (error.response.status === 500) {
              Modal.error({
                title: t("error"),
                content: t("msgRequired"),
              });
              setUbication(false);
            }
            if (error.response.status === 401) {
              Modal.error({
                title: t("error"),
                content: error,
              });
              setLocation("/login");
            }
          }
        );
        setLocation("/cuenta/editar"); 
    };


  return (
    <>
        {editUbication === true ? (
          <div className="profile-form-row">
            <p className="montserrat-14 mb-5 ">
              <span className="montserrat-14 bold c-blue-primary">
                {t("content3")}
                <CheckOutlined
                  type="primary"
                  style={{ padding: "3px", fontSize: "26px", color: "#0C4393" }}
                  onClick={handleUbication}
                />
              </span>
            </p>
            <TitleForm />

            <div className="profile-form-row-grid mb-5">
              <Form.Item
                label={
                  <p style={{ fontSize: "20px", color: "red" }}>
                    *
                    <span style={{ fontSize: "14px", color: "#030E1F" }}>
                      {t("state")}
                    </span>
                  </p>
                }
                name="state"
              >
                <Select
                  name="state"
                  onChange={handleInputSelectState}
                  placeholder={t("state")}
                  size="large"
                >
                  {states.length ? (
                    states.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))
                  ) : (
                    <Option>{t("msgSubcription13")}...</Option>
                  )}
                </Select>
              </Form.Item>

              <Form.Item
                label={
                  <p style={{ fontSize: "20px", color: "red" }}>
                    *
                    <span style={{ fontSize: "14px", color: "#030E1F" }}>
                      {t("city")}
                    </span>
                  </p>
                }
                name="city"
              >
                <Select
                  name="city"
                  onChange={handleInputSelectCity}
                  placeholder={t("city")}
                  size="large"
                >
                  {cityAll.length ? (
                    cityAll.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))
                  ) : (
                    <Option>{t("msgSubcription13")}......</Option>
                  )}
                </Select>
              </Form.Item>
            </div>
            <div className="profile-form-row-grid mb-5">
              <Form.Item
                label={
                  <p style={{ fontSize: "20px", color: "red" }}>
                    *
                    <span style={{ fontSize: "14px", color: "#030E1F" }}>
                      {t("town")}
                    </span>
                  </p>
                }
                name="town"
              >
                <Select
                  name="town"
                  onChange={handleInputSelectTown}
                  placeholder={t("town")}
                  size="large"
                >
                  {townAll.length ? (
                    townAll.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))
                  ) : (
                    <Option>{t("msgSubcription13")}...</Option>
                  )}
                </Select>
              </Form.Item>
            </div>
            <div className="profile-form-buttons mt-30">
              <Button
                type="primary"
                htmlType="submit"
                onClick={onFinishUbication}
              >
                {t("btnguardar")}
              </Button>
            </div>
          </div>
        ) : (
          <div className="profile-form-row">
            <p className="montserrat-14 mb-5 ">
              <span className="montserrat-14 bold c-blue-primary">
                {t("content3")}
                <FormOutlined
                  type="primary"
                  style={{ padding: "3px", fontSize: "26px", color: "#0C4393" }}
                  onClick={handleUbicationActive}
                />
              </span>
            </p>
            <TitleForm />

            <div className="profile-form-row-grid mb-5">
              <Form.Item label={t("state")}>
                <Input value={stateOld} disabled />
              </Form.Item>

              <Form.Item label={t("city")}>
                <Input value={cityOld} disabled />
              </Form.Item>
            </div>
            <div className="profile-form-row-grid mb-5">
              <Form.Item label={t("town")}>
                <Input value={townOld} disabled />
              </Form.Item>
            </div>
          </div>
        )}
  </>
  );
};

export default UbicationDataUser;

