import { types } from '../types/types'

const initialState = { 
  start: '',
  end: '',
  score: '',
  time: '',
  total: '',
  totalCorrect: '',
  totalIncorrect: '',
  totalUnanswered: '',

}

export const resultReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.result:
      return {
        ...action.payload
      }

    default:
      return state
  }
}
