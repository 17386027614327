import { useState, useEffect } from "react";
import price_header_yellow from "../../../assets/price_header_yellow.png";
import price_header_blue from "../../../assets/price_header_blue.png";
import icon_checklist from "../../../assets/icon-checklist.svg";
import Subtitle from "../../Atoms/Subtitle/Subtitle";
import { useLocation } from "wouter";
import "./GridPriceTable.scss";
import Logo from "../../Atoms/Logo/Logo";
import { useTranslation } from "react-i18next";
import { packageRepo } from "../../../store";

const GridPriceTable = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [, setLocation] = useLocation();
  const [packages, setPackages] = useState([]);
  const [, setCleanup] = useState();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("lang"));

    onload();
    return () => {
      setCleanup();
    };
  }, []);

  const onload = async () => {
    const responsePackages = await packageRepo.getAll();
    setPackages(responsePackages.data.data);
    setCleanup(false);
  };

  const onSend = (id) => {
    setLocation("/registro/" + id + "/" + localStorage.getItem("lang"));
  };

  return (
    <div className="price-table-container">
      <Logo width={"200px"} height={"100%"} customClass="mb-10 text-center" />
      <div className="max-width box-white">
        <h1 className="text-center mb-10">{t("contentSubscribe")}</h1>
        <p className="montserrat-15 text-center mb-45 price-table-subtitle">
          {t("contentp")}
          <br></br>
          <span className="montserrat-15 bold c-blue-primary">
            {t("contentspan")}{" "}
          </span>
        </p>

        <div className="price-table-grid-movil mt-15">
          {packages.map((item, i) =>
            i % 2 !== 0 ? (
              <div key={`key-price-table-carousel-${i}`}>
                <div className="price-table-item">
                  <div className="price-table-header">
                    <img
                      src={price_header_yellow}
                      alt={t("altPrice")}
                      className="table-header-image"
                    />
                    <div className="table-header-content">
                      <Subtitle
                        h4
                        content={item.name}
                        customClass={"c-blue-primary"}
                      />
                      <Subtitle
                        h4
                        content={item.description}
                        customClass={"c-blue-primary"}
                      />
                    </div>
                  </div>
                  <div className="price-table-list">
                    <ul>
                      <li
                        key={`key-price-table-carousel-li-${i}`}
                        className="montserrat-15"
                      >
                        <span>
                          <img src={icon_checklist} alt="Icono checklist" />
                        </span>{" "}
                        {item.duration} {t("dias")} $ {item.price} (CLP)
                      </li>
                    </ul>
                  </div>

                  <button
                    className="blue-outline"
                    onClick={() => onSend(item.id)}
                  >
                    {t("btnsubscribe")}
                  </button>
                </div>
              </div>
            ) : (
              <div
                key={`key-price-table-grid-${i}`}
                className="price-table-item"
              >
                <div className="price-table-header">
                  <img
                    src={price_header_blue}
                    alt={t("altPrice")}
                    className="table-header-image"
                  />
                  <div className="table-header-content">
                    <Subtitle h4 content={item.name} customClass={"c-white"} />
                    <Subtitle
                      h4
                      content={item.description}
                      customClass={"c-white"}
                    />
                  </div>
                </div>
                <div className="price-table-list">
                  <ul>
                    <li
                      key={`key-price-table-grid-li-${i}`}
                      className="montserrat-15"
                    >
                      <span>
                        <img src={icon_checklist} alt="Icono checklist" />
                      </span>
                      {item.duration} {t("dias")} $ {item.price} (CLP)
                    </li>
                  </ul>
                </div>
                <button
                  className="blue-outline"
                  onClick={() => onSend(item.id)}
                >
                  {t("btnsubscribe")}
                </button>
              </div>
            )
          )}
        </div>
        <div className="price-table-grid mt-15">
          {packages.map((item, i) =>
            i % 2 !== 0 ? (
              <div
                key={`key-price-table-grid-${i}`}
                className="price-table-item"
              >
                <div className="price-table-header">
                  <img
                    src={price_header_yellow}
                    alt={t("altPrice")}
                    className="table-header-image"
                  />
                  <div className="table-header-content">
                    <Subtitle
                      h4
                      content={item.name}
                      customClass={"c-blue-primary"}
                    />
                    <Subtitle
                      h4
                      content={item.description}
                      customClass={"c-blue-primary"}
                    />
                  </div>
                </div>
                <div className="price-table-list">
                  <ul>
                    <li
                      key={`key-price-table-grid-li-${i}`}
                      className="montserrat-15"
                    >
                      <span>
                        <img src={icon_checklist} alt="Icono checklist" />
                      </span>
                      {item.duration} {t("dias")} $ {item.price} (CLP)
                    </li>
                  </ul>
                </div>
                <button
                  className="blue-outline"
                  onClick={() => onSend(item.id)}
                >
                  {t("btnsubscribe")}
                </button>
              </div>
            ) : (
              <div
                key={`key-price-table-grid-${i}`}
                className="price-table-item"
              >
                <div className="price-table-header">
                  <img
                    src={price_header_blue}
                    alt={t("altPrice")}
                    className="table-header-image"
                  />
                  <div className="table-header-content">
                    <Subtitle h4 content={item.name} customClass={"c-white"} />
                    <Subtitle
                      h4
                      content={item.description}
                      customClass={"c-white"}
                    />
                  </div>
                </div>
                <div className="price-table-list">
                  <ul>
                    <li
                      key={`key-price-table-grid-li-${i}`}
                      className="montserrat-15"
                    >
                      <span>
                        <img src={icon_checklist} alt="Icono checklist" />
                      </span>
                      {item.duration} {t("dias")} $ {item.price} (CLP)
                    </li>
                  </ul>
                </div>
                <button
                  className="blue-outline"
                  onClick={() => onSend(item.id)}
                >
                  {t("btnsubscribe")}
                </button>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default GridPriceTable;
