import ActiveLinkD from "../../Atoms/ActiveLinkD/ActiveLinkD";
import { useTranslation } from "react-i18next";
import "./MainMenu.scss";

const MainMenu = () => {
  const { t } = useTranslation();  

  return (
    <nav className="main-menu">
      <ul className="main-menu_list">        
        <ActiveLinkD href="/dashboard" >
          Dashboard
        </ActiveLinkD>
        <ActiveLinkD href="/materias" >
          {t("control")}
        </ActiveLinkD>     
            
      </ul>
    </nav>
  );
};

export default MainMenu;
