import { useState, useContext , useEffect} from "react";
import { Button, Card, Table, Modal } from "antd";
import { useLocation } from "wouter";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../auth/AuthContext";
import { types } from "../../../types/types";
import "./CardTableHistorico.scss";
import { userTestRepo } from "../../../store";

const CardTableHistorico = ({ customClass, columns, data }) => {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const [selectedRowKeys, setSelectedRowKeys] = useState("");
  const [selectionType, setSelectionType] = useState("checkbox");
  
  const { typeTest, subcription, dispatchHistorical } = useContext(AuthContext);
  const [, setCleanup] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisibleAll, setModalVisibleAll] = useState(false);

  useEffect(() => {    
    return () => {
      setCleanup();
    };
  }, []);

  const handleModalDeleteOk = (modalVisible, select) => {
    setModalVisible(modalVisible);
     userTestRepo.deleteUserTest(select);

    setTimeout(function () {
      Modal.info({
        title: t("msginfo"),
        content: t("msgModal"),
      });
      setTimeout(function () {
        typeTest.type === "custom" ? 
        setLocation("/materias")
        :
        setLocation("/ensayo")  
      }, 3000);
    }, 2000);
  };

  const handleModalDelete = (modalVisible) => {
    setModalVisible(modalVisible);
  };
  const handleModalDeleteAllOk = (modalVisibleAll, select) => {
    setModalVisibleAll(modalVisibleAll);
    userTestRepo.deleteUserTestAll({ user_test_ids: select });

    setTimeout(function () {
      Modal.info({
        title: t("msginfo"),
        content: t("msgModal"),
      });
      setTimeout(function () {
        typeTest.type === "custom" ? 
        setLocation("/materias")
        :
        setLocation("/ensayo")  
      }, 3000);
    }, 2000);


  };
  const handleModalDeleteAll = (modalVisibleAll) => {
    setModalVisibleAll(modalVisibleAll);
  };

  const handleCheck = () => {
    dispatchHistorical({
      type: types.historical,
      payload: {
        value: typeTest.type === "custom" ?  "custom" : "official",
        userTest: selectedRowKeys[0],
      },
    });
    setCleanup(false);
    setLocation("/revisar");
  };

  const handleReturn = () => {
    dispatchHistorical({
      type: types.historical,
      payload: {
        value:  typeTest.type === "custom" ?   "custom" : "official",
        userTest: selectedRowKeys[0],
      },
    });
    setCleanup(false);
    setLocation("/temashistorial");
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectionType(selectedRows);
      setSelectedRowKeys(selectedRowKeys);      
    },
  };

  return (
    <Card
      className={`${customClass ? customClass : ""}`}
      title={t("titleHistorical")}
      bordered={false}
    >
      <div className="pxy-10 ">
        <Table
          showSorterTooltip={{ title: t('sort') }}
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
          size="small"
          scroll={{ x: 420 }}
          pagination={true}
          columns={columns}
          dataSource={data}
        />

        {data.length === 0 ? null : subcription.active === 1 &&
          selectedRowKeys !== "" ? (
          <div className="container-buttons-historico">
            {selectedRowKeys.length > 1 ? (
              <button className="gray" >
                {t("btnborrar")}{" "}
              </button>
            ) : (
              <Button
                type="primary"
                className="style_button"
                onClick={() => handleModalDelete(true, selectedRowKeys[0])}
              >
                {t("btnborrar")}{" "}
              </Button>
            )}

            {selectedRowKeys.length > 1 ? (
              <Button
                type="primary"
                className="style_button"
                onClick={() => handleModalDeleteAll(true, selectedRowKeys)}
              >
                {t("btnborrartodo")}
              </Button>
            ) : (
              <button className="gray" >
                {t("btnborrartodo")}
              </button>
            )}
            {selectedRowKeys.length > 1 ? (
              <Button type="primary"  disabled className="gray">
                {t("btnretornar")}
              </Button>
              
            ) : (
              <Button
                type="primary"
                className="style_button"
                onClick={() => handleReturn(selectedRowKeys)}
              >
                {t("btnretornar")}
              </Button>
            )}
            {selectedRowKeys.length > 1 ? (
              <button  className="gray" >
                {t("btnrevisar")}
              </button>
            ) : (
              <Button
                type="primary"
                className="style_button"
                onClick={() => handleCheck(selectedRowKeys)}
              >
                {t("btnrevisar")}
              </Button>
            )}
          </div>
        ) : null}
      </div>

      <Modal
        title={t("msginfo")}
        style={{ top: 20 }}
        open={modalVisible}
        onCancel={() => handleModalDelete(false)}
        onOk={() => handleModalDeleteOk(false, selectedRowKeys)}
      >
        {t("msgmodalDelete")}
      </Modal>
      <Modal
        title={t("msginfo")}
        style={{ top: 20 }}
        open={modalVisibleAll}
        onCancel={() => handleModalDeleteAll(false)}
        onOk={() => handleModalDeleteAllOk(false, selectedRowKeys)}
      >
        {t("msgmodalDeleteAll")}
      </Modal>
    </Card>
  );
};

export default CardTableHistorico;
