
import { useState }  from 'react'
import { useForm } from "../../../customHooks/useForm";
import { Button, Form, Input, Modal } from "antd";
import {  FormOutlined, CheckOutlined } from "@ant-design/icons";
import TitleForm from "../../Atoms/TitleForm/TitleForm";
import { useTranslation } from "react-i18next";
import { useLocation } from "wouter";
import {  userRepo  } from "../../../store";


export const PasswordDataUser = ({ dataUser }) => {

    const { t } = useTranslation(); 
    const [, setLocation] = useLocation(); 
    const [passwordNew, setPasswordNew] = useState();
  
    const { password } = dataUser;
  
    const [formValues, handleInputChange] = useForm();
    const [editPassword, setPassword] = useState(false);
    const [confirm, setConfirm] = useState();

    const handlePasswordActive = () => {
      setPassword(true);
    };
  
    const handlePassword = () => {
      setPassword(false);
    };
   
    const onFinishPassword = () => {
      if (
        formValues.password !== undefined &&
        formValues.passwordNew !== undefined &&
        formValues.confirm !== undefined
      ) {
        userRepo
          .editUserPassword({
            name: formValues.name,
            last_name: formValues.last_name,
            old_password: formValues.password,
            new_password: formValues.passwordNew,
            email: formValues.email,
            phone: formValues.phone,
            timezone: formValues.timezone,
            language: formValues.language,
            terms_and_conditions: formValues.terms_and_conditions,
          })
          .then(
            (data) => {
              setPasswordNew();
              setConfirm();  
              Modal.info({
                title: t("info"),
                content: t("msgUpdate"),
              });
              setPassword(false);              
             setLocation("/dashboard");
            },
            // GET USER ERROR
            (error) => {
              setPasswordNew();
              setConfirm();  
              if (error.response.status === 500) {
                Modal.error({
                  title: t("error"),
                  content: t("msgRequired"),
                });
                setPassword(false);
              }
              if (error.response.status === 400) {
                Modal.error({
                  title: t("error"),
                  content: error.response.data.message,
                });
                setPassword(false);
              }
              if (error.response.status === 401) {
                Modal.error({
                  title: t("error"),
                  content: error,
                });
                setLocation("/login");
              }
            }
          );
      } else {
        Modal.error({
          title: t("error"),
          content: t("msgRequired"),
        });
      }
      setLocation("/cuenta/editar");
    };
  

  return (
    <>
        {editPassword === true ? (
          <div className="profile-form-row">
            <p className="montserrat-14 mb-5 ">
              <span className="montserrat-14 bold c-blue-primary">
                {t("content4")}
                <CheckOutlined
                  type="primary"
                  style={{ padding: "3px", fontSize: "26px", color: "#0C4393" }}
                  onClick={handlePassword}
                />
              </span>
            </p>
            <TitleForm />
            <div className="profile-form-row-grid mb-5">
              <Form.Item
                label={
                  <p style={{ fontSize: "20px", color: "red" }}>
                    *
                    <span style={{ fontSize: "14px", color: "#030E1F" }}>
                      {t("passwordNow")}
                    </span>
                  </p>
                }
                name="password"
                hasFeedback
                rules={[
                  {
                    pattern:
                      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{8,}$",
                    message: t("msgpassword1"),
                  },
                ]}
              >
                <Input.Password
                  name="password"
                  value={password}
                  placeholder={t("password")}
                  onChange={handleInputChange}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                label={t("passwordNew")}
                name="passwordNew"
                hasFeedback
                rules={[
                  { required: true, message: t("msgpasswordNew") },
                  {
                    pattern:
                      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{8,}$",
                    message: t("msgpassword1"),
                  },
                ]}
              >
                <Input.Password
                  defaultValue={passwordNew}
                  name="passwordNew"
                  value={passwordNew}
                  onChange={handleInputChange}
                  placeholder={t("password")}
                  size="large"
                />
              </Form.Item>
            </div>
            <div className="profile-form-row-grid mb-5">
              <Form.Item
                name="confirm"
                label={t("confirmPassword")}
                dependencies={["passwordNew"]}
                hasFeedback
                rules={[
                  { required: true, message: t("msgconfirm") },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("passwordNew") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t("coincide")));
                    },
                  }),
                ]}
              >
                <Input.Password
                  defaultValue={confirm}
                  name="confirm"
                  value={confirm}
                  onChange={handleInputChange}
                  placeholder={t("repeat")}
                  size="large"
                />
              </Form.Item>
            </div>
            <div className="profile-form-buttons mt-30">
              <Button
                type="primary"
                htmlType="submit"
                onClick={onFinishPassword}
              >
                {t("btnguardar")}
              </Button>
            </div>
          </div>
        ) : (
          <div className="profile-form-row">
            <p className="montserrat-14 mb-5 ">
              <span className="montserrat-14 bold c-blue-primary">
                {t("content4")}
                <FormOutlined
                  type="primary"
                  style={{ padding: "3px", fontSize: "26px", color: "#0C4393" }}
                  onClick={handlePasswordActive}
                />
              </span>
            </p>
            <TitleForm />
          </div>
        )}  
  </>
  );
};

export default PasswordDataUser;

