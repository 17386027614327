import { Card, Tabs } from "antd";
import React, { useState, useEffect, useContext } from "react";
import "./CardTheme.scss";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../auth/AuthContext";
import CardThemeOption from "../../Molecules/CardThemeOption/CardThemeOption";
import CardThemeOptionExam from "../../Molecules/CardThemeOptionExam/CardThemeOptionExam";

const CardTheme = () => {
  const { t } = useTranslation();  
  const { control } = useContext(AuthContext);
  const [, setCleanup] = useState(); 
  const columns = [
    {
      title: "",
      dataIndex: "select",
      key: "select",
    },
    {
      title: t("theme"),
      dataIndex: "theme",
      key: "theme",
    },
    {
      title: t("context"),
      dataIndex: "context",
      key: "context",
    },
    {
      title: t("question"),
      dataIndex: "quantity",
      key: "quantity",
    },
  ];
  
  useEffect(() => {
    return () => {
      setCleanup();
    };
  }, []);

 
  return (
    <Card className="mt-20" title={control.subjectName} bordered={false}>   
        <div className="mains-control-grid">   
         <div className="pxy-20">
           
              <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab={t('practiceTests')} key="1">
                  <CardThemeOption columns={columns}/>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t('takeExam')} key="2">
                  <CardThemeOptionExam columns={columns}/>  
                </Tabs.TabPane>
              </Tabs>
                     
          </div>
          
        </div> 
    </Card>
  );
};

export default CardTheme;
