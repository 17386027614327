
import Repository from './repository.js'
export default class CountryRepository extends Repository {
    constructor(apis) {
        super(apis)
    }

    getCountries(){
        return this.$axios            
            .get('api/countries')
             
    }

       

}

