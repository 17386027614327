import moment from "moment";

const Subjects = subjects => {
  var arraySubjects = ''
  for (let j = 0; j < subjects.length; j++) {
    if (j + 1 === subjects.length && subjects.length === 1) {
      arraySubjects += subjects[j].name + '.'
    }
    if (j + 1 === subjects.length && subjects.length !== 1) {
      arraySubjects += ' y ' + subjects[j].name + '.'
    }
    if (j + 1 !== subjects.length) {
      arraySubjects += subjects[j].name + ', '
    }
  }

  return arraySubjects.replace(',  y ', ' y ')
}

const calculationTrialDays = (trialStart, trialDuration) => {
  const dateNow = new Date(moment()._d).toISOString();
  var startDate = moment(dateNow);
  var endDate = moment(trialStart).add(parseInt(trialDuration), 'days');
  var days = endDate.diff(startDate, 'days');
  return days > 0 ? days : 0;
}

export default { Subjects, calculationTrialDays }
