import { types } from '../types/types'

const initialState = {
  active: '',
  standardTest: '',
  subcriptionById : null
}

export const subcriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.subcription:
      return {
        ...action.payload
      }
    
      
    case types.subcriptionById:
      return {
        ...state,
        subcriptionById: {
          ...action.payload
        }
      }
      
    

    default:
      return state
  }
}
