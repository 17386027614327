import axios from 'axios'
import Cookies from 'js-cookie';
import { APP } from "../../Config/config"

let verifyEmailClient = axios.create({
    timeout: 0,
    headers: {
        'Accept': 'application/json, text/plain, */*, text/html',
        "Content-Type" : "application/json",
    },
    
});

// Request interceptor. Runs before your request reaches the server
const onRequest = (config) => {
    if (!Cookies.get('XSRF-TOKEN')) {
            return setCSRFToken()
                .then(response => config);
        }
    return config;
}

const setCSRFToken = () => {
    axios.defaults.withCredentials = true;
    return axios.get(APP.apiUrl + "sanctum/csrf-cookie");
}

// attach your interceptor
verifyEmailClient.interceptors.request.use(onRequest, null);



export { verifyEmailClient };
