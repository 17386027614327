import axios from 'axios'
import Cookies from 'js-cookie';
import "react-phone-input-2/lib/style.css";
import { APP } from '../../Config/config';

var id = window.location.href.split("/");

if (id[5] === 'es' || id[5] === 'en' ) {    
    localStorage.setItem("lang", id[5]);    
}else{
    localStorage.setItem("lang", 'es');
}
if(id[4] === 'es' || id[4] === 'en'  ){
    localStorage.setItem("lang", id[4]);  
}else{
    localStorage.setItem("lang", 'es');
}


const lang = localStorage.getItem('lang');


let axiosClient = axios.create({
    baseURL: APP.apiUrl,
    timeout: 0,
    headers: {
        'X-localization': lang,
        'Access-Control-Allow-Credentials': true,
        "Accept": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Methods": "GET,POST,OPTIONS,DELETE,PUT,PATCH"
    },
    withCredentials: true,
});

// Request interceptor. Runs before your request reaches the server
const onRequest = (config) => {
    if (!Cookies.get('XSRF-TOKEN')) {
            return setCSRFToken()
                .then(response => config);
        }

    return config;
}

const setCSRFToken = async () => {
    axios.defaults.withCredentials = true;
    return  await axios.get(APP.apiUrl + "sanctum/csrf-cookie")
        
       
}

// attach your interceptor
axiosClient.interceptors.request.use(onRequest, null);

export { axiosClient };
