import { React, useEffect, useState, useContext } from "react";
import { Card } from "antd";
import { AuthContext } from "../../../auth/AuthContext";
import { userSubjectStatsRepo } from "../../../store";
import { RadialBar } from "@ant-design/charts";
import { useTranslation } from "react-i18next";



const ChartsRadialAntd = () => {
  const { t } = useTranslation();
  const { control, user } = useContext(AuthContext);
  const [dataList, setDataList] = useState([]);
  const [, setCleanup] = useState();

  const asyncFetch =  () => {
        userSubjectStatsRepo.getUserThemeStatsColumn({
          user_id: user.userId,
          subject_id: control.subjectId,
          user_test_type: null
        }).then(({data}) =>{
        
        for(let i = 0; i < data.length; i++){
          setDataList((dataList) => [
            ...dataList,
            {
              name: data[i].name,
              puntaje: Math.round(data[i].correct_percentage),
            },
          ]);  
        }
      
      });
    
     setCleanup(false);
    
  };
  useEffect(() => {
    asyncFetch();
    return () => {
      setCleanup();
      
    };
  }, []);

  if (dataList !== "[]") {
    var config = {
      width: 350,
      height: 438,
      data: dataList,
      xField: "name",
      yField: "puntaje",
      radius: 1,
      innerRadius: 0.5,
      maxAngle: 250,
      type: "line",
      xAxis: {
        title: {
          text:  t('theme'),
          style: { fill: "black", fontSize: 12 },
        },
      },
      
      tooltip: {
        tooltipContainerFillColor: "black",
        fontFamily: "Montserrat, sans-serif",
        formatter: (datum) => {
          return {
            name:  datum.name,
            value: datum.puntaje + '%',
          };
        },
      },
    };
  }
  return (
    <Card title={t('radialAntd')} bordered={false}>
      {dataList !== "[]" ? <RadialBar style={ { width: "80% !important", height: "80% !important" }} {...config} /> : null}
    </Card>
  );
};

export default ChartsRadialAntd;
