import { useState } from "react";
import { Steps } from "antd";
import "./Unsubscribe.scss";
import  UnsubscribeStep1 from "./UnsubscribeStep1";
import  UnsubscribeStep2 from "./UnsubscribeStep2";
import  UnsubscribeStep3 from "./UnsubscribeStep3";
import { useTranslation } from "react-i18next";
const { Step } = Steps;

const Unsubscribe = () => {
  const { t } = useTranslation();
  const [step1Data, setStep1Data] = useState(null);
  const [step2Data, setStep2Data] = useState(null);
  const [step3Data, setStep3Data] = useState(null);
  const [current, setCurrent] = useState(0);
  const next = () => setCurrent(current + 1);
  const prev = () => setCurrent(current - 1);

  const steps = [ 
    {
      title: t("notifications"),
      content: (
        < UnsubscribeStep1
          next={next}
          step1Data={step1Data}
          setStep1Data={setStep1Data}
        />
      ),
    },   
    {
      title: t("Confirm"),
      content: (
        < UnsubscribeStep2
          next={next}
          prev={prev}
          step2Data={step2Data}
          setStep2Data={setStep2Data}  
        />
      ),
    },
    {
      title: t("canceled"),
      content: (
        < UnsubscribeStep3
          next={next}
          prev={prev}         
          step3Data={step3Data}
          setStep3Data={setStep3Data}
          
        />
      ),
    },
  ];

  return (
    <div className="unsubscribe_container">
      
        <div className="box-white">
          <Steps current={current} className="mb-10" responsive={"600px"}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div>{steps[current].content}</div>
        </div>
      </div>
    
  );
};

export default Unsubscribe;
