
import { useState }  from 'react'
import { useForm } from "../../../customHooks/useForm";
import { Button, Form, Input, Modal } from "antd";
import {  FormOutlined, CheckOutlined } from "@ant-design/icons";
import TitleForm from "../../Atoms/TitleForm/TitleForm";
import { useTranslation } from "react-i18next";
import { useLocation } from "wouter";
import {  addressRepo,  } from "../../../store";


export const DirectionDataUser = ({ dataUser, address }) => {

    const { t } = useTranslation(); 
    const [, setLocation] = useLocation(); 
    
    const [Address, setAddress] = useState({
      address_line_1: "",
      address_line_2: "",
      town_id: "",
      zip_code: "" });  
      
    const [editDirection, setDirection] = useState(false);
    const [formValues, handleInputChange] = useForm();  
    const { address_id } = dataUser;  
    const { address_line_1, address_line_2, town_id, zip_code } = address;

    const handleDirectionActive = async () => {   
      setDirection(true);
    };
  
    const handleDirection = () => {
      setDirection(false);
    };

    const onFinishDirection = () => {
      addressRepo
        .editAddress(address_id, {
          address_line_1: formValues.address_line_1,
          address_line_2: formValues.address_line_2,
          town_id: town_id,
          zip_code: formValues.zip_code,
        })
        .then(
          (data) => {
            setAddress({
              address_line_1: data.data.address_line_1,
              address_line_2: data.data.address_line_2,
              town_id: data.data.town_id,
              zip_code: data.data.zip_code,
            });
            Modal.info({
              title: t("info"),
              content: t("msgUpdate"),
            });
            
            setDirection(false);
          },
          // GET USER ERROR
          (error) => {
            if (error.response.status === 422) {
              Modal.error({
                title: t("error"),
                content: t("msgRequired"),
              });
            }
            if (error.response.status === 500) {
              Modal.error({
                title: t("error"),
                content: t("msgRequired"),
              });
              setDirection(false);
            }
            if (error.response.status === 401) {
              Modal.error({
                title: t("error"),
                content: error,
              });
              setLocation("/login");
            }
          }
        );
        setLocation("/cuenta/editar"); 
    };


  return (
    <>
        {editDirection === true ? (
          <div className="profile-form-row">
            <p className="montserrat-14 mb-5 ">
              <span className="montserrat-14 bold c-blue-primary">
                {t("content2")}
                <CheckOutlined
                  type="primary"
                  style={{ padding: "3px", fontSize: "26px", color: "#0C4393" }}
                  onClick={handleDirection}
                />
              </span>
            </p>
            <TitleForm />
            <div className="profile-form-row-grid mb-5">
              <Form.Item
                label={
                  <p style={{ fontSize: "20px", color: "red" }}>
                    *
                    <span style={{ fontSize: "14px", color: "#030E1F" }}>
                      {t("direccion1")}
                    </span>
                  </p>
                }
                name="address_line_1"
              >
                <Input
                  name="address_line_1"
                  defaultValue={address_line_1}
                  key={address_line_1}
                  value={address_line_1}
                  onChange={handleInputChange}
                />
              </Form.Item>
              <Form.Item label={t("direccion2")} name="address_line_2">
                <Input
                  name="address_line_2"
                  defaultValue={address_line_2}
                  key={address_line_2}
                  value={address_line_2}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </div>

            <div className="profile-form-row-grid mb-5">
              <Form.Item
                label={
                  <p style={{ fontSize: "20px", color: "red" }}>
                    *
                    <span style={{ fontSize: "14px", color: "#030E1F" }}>
                      {t("postalCode")}
                    </span>
                  </p>
                }
                name="zip_code"
                rules={[{ pattern: "^[0-9]*$", message: t("msgnumeric") }]}
              >
                <Input
                  name="zip_code"
                  defaultValue={zip_code}
                  key={zip_code}
                  value={zip_code}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </div>
            <div className="profile-form-buttons mt-30">
              <Button
                type="primary"
                htmlType="submit"
                onClick={onFinishDirection}
              >
                {t("btnguardar")}
              </Button>
            </div>
          </div>
        ) : (
          <div className="profile-form-row">
            <p className="montserrat-14 mb-5 ">
              <span className="montserrat-14 bold c-blue-primary">
                {t("content2")}
                <FormOutlined
                  type="primary"
                  style={{ padding: "3px", fontSize: "26px", color: "#0C4393" }}
                  onClick={handleDirectionActive}
                />
              </span>
            </p>
            <TitleForm />
            <div className="profile-form-row-grid mb-5">
              <Form.Item label={t("direccion1")}>
                <Input value={address_line_1} disabled />
              </Form.Item>
              <Form.Item label={t("direccion2")}>
                <Input value={address_line_2} disabled />
              </Form.Item>
            </div>

            <div className="profile-form-row-grid mb-5">
              <Form.Item label={t("postalCode")}>
                <Input value={zip_code} disabled />
              </Form.Item>
            </div>
          </div>
        )} 
  </>
  );
};

export default DirectionDataUser;

