import './IconToggle.scss'

const IconToggle = ({ width, height, fill, props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 27"
      xmlns="http://www.w3.org/2000/svg"
      className="icon-toggle"
      {...props}
    >
      <path
        d="M0 0h36v4.5H0V0zm0 11.25h36v4.5H0v-4.5zM0 22.5h36V27H0v-4.5z"
        fill={fill}
      />
    </svg>
  )
}

export default IconToggle
