import { Button, Form, Input, Radio, Space } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../auth/AuthContext";
import { useTranslation } from "react-i18next";
import { feedbackRepo, userSubcriptionRepo } from "../../../store";
import { useForm } from "../../../customHooks/useForm";
import moment from "moment";
const { TextArea } = Input;

const UnsubscribeStep2 = ({ next, prev, step2Data, setStep2Data }) => {
  const { t, i18n } = useTranslation();
  const { subcription } = useContext(AuthContext);
  const [feedback, setFeedBack] = useState([]);
  const [answer, setAnswer] = useState();
  const [, setCleanup] = useState();
  const [formValues, handleInputChange] = useForm({
    valueTextAreaComment: "",
    valueTextComment: "",
  });
  const { valueTextAreaComment, valueTextComment } = formValues;

  useEffect(() => {
    onLoad();
    return () => {
      setCleanup();
    };
  }, []);

  const onLoad = async () => {
    const response = await feedbackRepo.show({ key: "feedback_builder" });
    const feedBack = JSON.parse(response.data.data[0].value);
    var arrayOptions = [];
    for (let i = 0; i < feedBack.length; i++) {
      if (feedBack[i].attributes.type === "radio") {
        for (let j = 0; j < feedBack[i].attributes.options.length; j++) {
          if (i18n.languages[0] === "es") {
            arrayOptions.push(
              feedBack[i].attributes.options[j].attributes.label.es
            );
          } else {
            arrayOptions.push(
              feedBack[i].attributes.options[j].attributes.label.en
            );
          }
        }
      }
      setFeedBack((feedback) => [
        ...feedback,
        {
          type: feedBack[i].attributes.type,
          required: feedBack[i].attributes.required,
          options:
            feedBack[i].attributes.type !== "radio"
              ? i18n.languages[0] === "es"
                ? feedBack[i].attributes.options[0].attributes.label.es
                : feedBack[i].attributes.options[0].attributes.label.en
              : arrayOptions,
          title:
            feedBack[i].attributes.type !== "radio"
              ? null
              : feedBack[i].attributes.title,
        },
      ]);
    }

    setCleanup(false);
  };

  const onChange = (e) => {
    setAnswer(e.target.value);
  };

  const onSend = async () => {
    var answers = [];
    for (let i = 0; i < feedback.length; i++) {
      if (feedback[i].type === "text") {
        answers.push({
          field_type: "text",
          question: feedback[i].options,
          answer: formValues.valueTextComment,
        });
      }
      if (feedback[i].type === "textarea") {
        answers.push({
          field_type: "textarea",
          question: feedback[i].options,
          answer: formValues.valueTextAreaComment,
        });
      }
      if (feedback[i].type === "radio") {
        answers.push({
          field_type: "radio",
          question: feedback[i].title,
          answer: answer,
        });
      }
    }
    var jsonAnswer = {
      created_at: moment(new Date().toISOString().slice(0, 10), "YYYY-MM-DD"),
      answers: answers,
    };
    userSubcriptionRepo
      .editFeedBack({
        id: subcription.subcriptionById.id,
        feedback: jsonAnswer,
      })
      .then((data) => {});

     await userSubcriptionRepo.statusSubscription({
      id: subcription.subcriptionById.id,
      status: "PAUSE",
    });
  };

  const finishStep2 = (e) => {
    setStep2Data(); // Guardo los datos del tercer formulario en un estado
    next(); // Voy al siguiente paso
  };

  return (
    <Form onFinish={finishStep2}>
      <div className="page-controls max-width">
        <div className="mt-60">
          {feedback.map((item, i) => (
            <div>
              {item.type === "radio" ? (
                item.required === true ? (
                  <div>
                    <Form.Item
                      label={item.title}
                      name="radio"
                      rules={[
                        { required: true, message: t("requiredUnsubscribe") },
                      ]}
                    >
                      <Radio.Group onChange={onChange} className="mt-40">
                        <Space direction="vertical">
                          {item.options.map((radio, i) => (
                            <Radio key={`key-radio-group-${i}`} value={radio}>
                              {radio}
                            </Radio>
                          ))}
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                ) : (
                  <div>
                    <Form.Item label={item.title} name="radio">
                      <Radio.Group onChange={onChange} className="mt-40">
                        <Space direction="vertical">
                          {item.options.map((radio, i) => (
                            <Radio key={`key-radio-group-${i}`} value={radio}>
                              {radio}
                            </Radio>
                          ))}
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                )
              ) : null}
              {item.type === "textarea" ? (
                item.required === true ? (
                  <div key={i}>
                    <Form.Item
                      label={item.options}
                      value={item.options}
                      name="valueTextAreaComment"
                      rules={[
                        { required: true, message: t("requiredUnsubscribe") },
                      ]}
                    >
                      <TextArea
                        className="unselectable"
                        value={valueTextAreaComment}
                        name="valueTextAreaComment"
                        onChange={handleInputChange}
                        showCount
                        maxLength={500}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                        style={{ height: 100 }}
                      />
                    </Form.Item>
                  </div>
                ) : item.required === true ? (
                  <div key={i}>
                    <Form.Item
                      label={item.options}
                      value={item.options}
                      name="valueTextAreaComment"
                      rules={[
                        { required: true, message: t("requiredUnsubscribe") },
                      ]}
                    >
                      <TextArea
                        className="unselectable"
                        value={valueTextAreaComment}
                        name="valueTextAreaComment"
                        onChange={handleInputChange}
                        showCount
                        maxLength={500}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                        style={{ height: 100 }}
                      />
                    </Form.Item>
                  </div>
                ) : (
                  <div key={i}>
                    <Form.Item
                      label={item.options}
                      value={item.options}
                      name="valueTextAreaComment"
                    >
                      <TextArea
                        className="unselectable"
                        value={valueTextAreaComment}
                        name="valueTextAreaComment"
                        onChange={handleInputChange}
                        showCount
                        maxLength={500}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                        style={{ height: 100 }}
                      />
                    </Form.Item>
                  </div>
                )
              ) : null}

              {item.type === "text" ? (
                <div>
                  <Form.Item label={item.options} name="valueTextComment">
                    <Input
                      name="valueTextComment"
                      value={valueTextComment}
                      onChange={handleInputChange}
                      size="large"
                    />
                  </Form.Item>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>

      <div className="container-buttons-unsubscribe">
        <Button
          onClick={() => {
            prev();
          }}
        >
          {t("btnback")}
        </Button>

        <Button type="primary" htmlType="submit" onClick={onSend}>
          {t("btn")}
        </Button>
      </div>
    </Form>
  );
};

export default UnsubscribeStep2;
