import { Button } from 'antd'
import { Link } from 'wouter'
import img404 from '../../../assets/404.svg'
import { useTranslation } from "react-i18next"
import './NotFound.scss'

const NotFound = () => {
  const { t } = useTranslation()
  return (
    <div className="page-404">
      <img src={img404} alt="Not found" />
      <div>
        <Button type="primary" className="mt-20"><Link href="/dashboard">{t('btn')}</Link></Button>
      </div>
    </div>
  )
}

export default NotFound
