import { types } from '../types/types'

const initialState = {
  subjectId: '',
  subjectName: '',
  subjectApprovalGrade: '',
  subscriptionId:'',
  paqueteId: '',
  standartTestId: '',
  periodId: '',
  configuration: null,
  userTestId: '',
  type: ''
}

export const controlReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.control:
      return {
        ...action.payload
      }

    case types.configuration:
      return {
        ...state,
        configuration: {
          ...action.payload
        }
      }

    case types.userTest:
      return {
        ...state,
        userTestId: { ...action.payload }
      }

    
    case types.controlCleaning:
      return {
        ...action.payload
      }

    default:
      return state
  }
}
