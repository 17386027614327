import { Avatar } from "antd";
import right_icon from "../../../assets/icon-right.svg";
import { useLocation } from "wouter";
import { useTranslation } from "react-i18next";
import "./MenuUser.scss";
import { APP_IKENGA } from "../../../Config/config";
import AzureAvatar from "../../Atoms/AzureAvatar/AzureAvatar";
import Cookie  from  'js-cookie';

const MenuUser = () => {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();

  // lógica para el cierre de sesion
  const handleCloseSession = () => {
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("username");
    localStorage.removeItem("avatar");
    localStorage.removeItem("redirect");
    localStorage.removeItem("header");
    Cookie.remove('ikenga_session');
    Cookie.remove('ikenga_dev_session');
    Cookie.remove('ikenga_pre_session');
    Cookie.remove('XSRF-TOKEN');
    window.open(APP_IKENGA.ikengaUrl, "_self");
  };

  const goToRoute = (route) => {
    setLocation(route);
  };

  return (
    <div className={`header-toolip user`}>
      <div className="header-toolip_user">
        {localStorage.getItem("avatar") === "null" ? (
          <Avatar
            style={{
              backgroundColor: "#0C4393",
              verticalAlign: "middle",
            }}
            size="large"
          >
            {localStorage.getItem("username").slice(0, 1).toUpperCase()}
          </Avatar>
        ) : (
          <AzureAvatar value={localStorage.getItem("avatar")} size="middle" />
        )}
        <div className="mb-5">
          <p className="montserrat-15 c-black mb-5">
            {localStorage.getItem("username")}
          </p>
        </div>
      </div>
      <div className="toolip-divider" />
      <div className="header-toolip_links">
        <div
          className="header-toolip_link mb-10"
          onClick={() => goToRoute("/dashboard")}
        >
          <img src={right_icon} alt="icon right" className="mr-5" />
          <p className="montserrat-15 c-black ">Dashboard</p>
        </div>
        <div className="header-toolip_link mb-10" onClick={()=> setLocation("/materias")}>
          <img src={right_icon} alt="icon right" className="mr-5" />
          <p className="montserrat-15 c-black">{t("control")}</p>
        </div>       
        <div
          className="header-toolip_link mb-10"
          onClick={() => goToRoute("/cuenta/editar")}
        >
          <img src={right_icon} alt="icon right" className="mr-5" />
          <p className="montserrat-15 c-black">{t("profile")}</p>
        </div>
         <div
          className="header-toolip_link mb-10"
          onClick={() => goToRoute("/agregar/subscripcion")}
        >
         <img src={right_icon} alt="icon right" className="mr-5" />
          <p className="montserrat-15 c-black">{t("renewalSubscription")}</p>
        </div>
      </div>
      <div className="toolip-divider" />
      
      <div className="header-toolip_sesion">
        <p
          className="montserrat-15 c-blue-primary"
          onClick={handleCloseSession}
        >
          {t("cerrar")}
        </p>
      </div>
    </div>
  );
};

export default MenuUser;
