import Repository from './repository.js'
export default class SubjectRepository extends Repository {
  constructor (apis) {
    super(apis)
  }

  getSubject () {
    return this.$axios.get('api/subject')
  }

  getUserTest (subjectId) {
    return this.$axios.get('api/subjects/' + subjectId + '/user-tests')
  }

  getSubjectThemes (subjectId, periodId) {
    return this.$axios.get(
      'api/subject/' + subjectId + '/' + periodId + '/themes'
    )
  }
}
