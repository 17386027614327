import { useState, useEffect } from "react";
import { Button,  Divider, Form } from "antd";
import  ProfileTableUser  from '../../Molecules/ProfileTableUser/ProfileTableUser'; 
import  PersonalDataUser  from '../../Molecules/PersonalDataUser/PersonalDataUser';
import  PasswordDataUser  from '../../Molecules/PasswordDataUser/PasswordDataUser';
import  DirectionDataUser  from '../../Molecules/DirectionDataUser/DirectionDataUser';
import  UbicationDataUser from "../../Molecules/UbicationDataUser/UbicationDataUser";
import { useLocation } from "wouter";
import TitleForm from "../../Atoms/TitleForm/TitleForm";
import { useTranslation } from "react-i18next";
import {
  userRepo,
  stateRepo,
  townRepo,
} from "../../../store";
import "./FormEditProfileUser.scss";

const FormProfileUser = () => {
  const { t } = useTranslation();  
 
  const [User, setUser] = useState({
    id: "",
    address_id: "",
    name: "",
    last_name: "",
    password: "",
    email: "",
    tax_id: "",
    phone: "",
    timezone: "",
    language: "",
    terms_and_conditions: "",
  });

  const [Address, setAddress] = useState({
    address_line_1: "",
    address_line_2: "",
    town_id: "",
    zip_code: "",
  });

  const [form] = Form.useForm();

  const [, setCleanup] = useState(); 
  
  const [, setLocation] = useLocation();

  const [stateOld, setStateOld] = useState("");
  const [cityOld, setCityOld] = useState("");
  const [townOld, setTownOld] = useState("");

  const [stateAll, setStateAll] = useState("");  
  
  //Carga  de datos
  useEffect(() => {
    onLoad();
    return () => {
      setCleanup();
    };
  }, []);
   
  const onLoad = async () => {
    const response = await userRepo.showUser();
    setUser({
      address_id: response.data.address_id,
      name: response.data.name,
      last_name: response.data.last_name,
      tax_id: response.data.tax_id,
      email: response.data.email,
      phone: response.data.phone,
    });
    if (response.data.address !== null) {
      setAddress({
        address_line_1: response.data.address.address_line_1,
        address_line_2: response.data.address.address_line_2,
        town_id: response.data.address.town_id,
        zip_code: response.data.address.zip_code,
      });

      const responseTown = await townRepo.findTown(
        response.data.address.town_id
      );

      setTownOld(responseTown.data.data.name);
      User.town_id = responseTown.data.data.id;

      setCityOld(responseTown.data.data.city.name);

      const responseState = await stateRepo.findState(
        responseTown.data.data.city.state_id
      );
      setStateOld(responseState.data.data.name);
    }
  
    const responseStateAll = await stateRepo.showStates();
    setStateAll(responseStateAll.data.data);
    setCleanup(false);
  };    

  const onCancel = () => {
    setLocation("/dashboard");
  }; 

  return (
    <div className="mt-30">
      <Form   form={form}>
         <PersonalDataUser  dataUser={User} />

        <PasswordDataUser   dataUser={User} />
        
        <DirectionDataUser  dataUser={User} address={Address} />

        <UbicationDataUser  dataUser={User} address={Address} states={stateAll} stateOld={stateOld} cityOld={cityOld} townOld={ townOld }/>
       
        <div className="profile-form-row  ">
          <p className="montserrat-14 mb-5 ">
            <span className="montserrat-14 bold c-blue-primary">
              {t("Subcription")}
            </span>
          </p>
          <TitleForm />
          <ProfileTableUser />
                   
        </div>
        <Divider></Divider>
        <div className="profile-form-buttons mt-30">
          <Button onClick={onCancel}>{t("btncancelar")}</Button>
        </div>
      </Form>
    </div>
  );
};

export default FormProfileUser;
