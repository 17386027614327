import Repository from './repository.js'
export default class PeriodRepository extends Repository {

    constructor(apis) {
        super(apis)
    }

     getPeriod(){
        return  this.$axios            
             .get('api/periods')
             .then(({data}) => data)
     }
     
     findPeriod(periodId){
        return  this.$axios            
             .get('api/periods/'+periodId)
             .then(({data}) => data)
     }
}