import { types } from '../types/types'

const initialState = {
  type: '',
  value: ''
}

export const typeTestReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.typeTest:
      return {
        ...action.payload
      }

    default:
      return state
  }
}
