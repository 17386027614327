import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../../auth/AuthContext";
import "./CardAverageListSubject.scss";
import { Card } from "antd";
import { useTranslation } from "react-i18next";
import { userSubjectStatsRepo } from "../../../store";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";


const CardAverageListSubject = () => {
  const { t } = useTranslation();
  const { user, control } = useContext(AuthContext);
  const [theme, setTheme ] = useState([]);
  const [dataList, setDatalist] = useState([]);
  const [, setCleanup] = useState();

  useEffect(() => {
    asyncFetch();
    return () => {
      setCleanup();
    };
  }, []);



  const asyncFetch = () => {
    userSubjectStatsRepo.getUserThemeStatsColumn({
      user_id: user.userId,
      subject_id: control.subjectId
    }).then(({data})=>{ 
      if (data.length !== 0) {
        for (let i = 0; i < data.length; i++) {
          setTheme((theme) => [
            ...theme,
            data[i].name,
          ]);
         setDatalist((dataList) => [...dataList, Math.round(data[i].correct_percentage)]);
           
          
        }
      }
    });

   
    setCleanup(false);
  };
  
  if (dataList.length !== 0) {
    var chartOptions = {
      chart: {
        type: "bar",
      },
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      xAxis: {
        categories: theme
      },
      yAxis: {
        min: 0,
        title: {
          text: t("average"),
        },
      },
      legend: {
        reversed: false,
      },
      plotOptions: {
        series: {
          stacking: "normal",
          dataLabels: {
            enabled: true,
          },
        },
      },
      series: [
        {
          name: t("modalityControl"),
          data: dataList,
          fontFamily: "Montserrat, sans-serif",
          fontSize:6
        },
      ],
    };
  }

  return (
    <Card title={t("cardAverageListSubject")}  bordered={false}>  
    {dataList.length !== 0 ? (
        <HighchartsReact
          className="pxy-10"
          highcharts={Highcharts}
          options={chartOptions}
        />
      ) : null}     
    </Card>
  );
};

export default CardAverageListSubject;
