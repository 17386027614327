const Subtitle = ({ h3, h4, content, customClass }) => {
  return (
    <>
      {h3 && (
        <h3 className={`montserrat-30 ${customClass ? customClass : ""}`}>
          {content}
        </h3>
      )}
      {h4 && (
        <h4 className={`montserrat-18 ${customClass ? customClass : ""}`}>
          {content}
        </h4>
      )}
    </>
  );
};

export default Subtitle;
