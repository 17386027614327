import { Button, Form } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "wouter";
import { AuthContext } from "../../../auth/AuthContext";
import { useTranslation } from "react-i18next";

const UnsubscribeStep3 = ({  }) => {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const { subcription } = useContext(AuthContext);
  const [ , setCleanup] = useState();

  useEffect(() => {
    return () => {
      setCleanup();
    };
  }, []);

  const onSend = async () => {
    setLocation("/dashboard");
  };

  const finishStep3 = (e) => {};

  return (
    <Form onFinish={finishStep3}>
      <div className="page-controls max-width">
        <div className="mt-20">
          <h2 className="mb-15 mt-30 text-center">
          {t('unsubscribStep31')}  {subcription.subcriptionById.paqueteName} {t('unsubscribStep32')}
          </h2>
                   
          <h4 className="mb-15 mt-30 text-center">
          {t('unsubscribStep33')}
          </h4>
        </div>
      </div>
      <div className="text-center">
        <Button type="primary" htmlType="submit" onClick={onSend}>
          {t("btnfinalizar")}
        </Button>
      </div>
    </Form>
  );
};

export default UnsubscribeStep3;
