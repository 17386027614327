import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  Checkbox,
  Divider,
  Form,
  Input,
  Modal,
  Pagination,
  Radio,
  Space,
  Table,
  Tag,
  message,
} from "antd";
import { useForm } from "../../../customHooks/useForm";
import { useLocation } from "wouter";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../auth/AuthContext";
import { Timer } from "../../Molecules/Timer/Timer";
import {
  userRepo,
  userTestRepo,
  userTestStemRepo,
  stemContextRepo,
} from "../../../store";
import LinearProgress from "@mui/material/LinearProgress";
import { APP } from "../../../Config/config";
import { types } from "../../../types/types";
import { CheckBox } from "../../../customHooks/CheckBox";
import "./Essays.scss";

const Essays = () => {
  const { t } = useTranslation();

  const columns = [
    {
      title: "#",
      dataIndex: "questionNumber",
      key: "questionNumber",
    },
    {
      title: t("mark"),
      dataIndex: "mark",
      key: "mark",
    },
    {
      title: t("answered"),
      dataIndex: "answered",
      key: "answered",
    },
  ];

  const [, setLocation] = useLocation();
  const { user } = useContext(AuthContext);
  const { control, dispatchUser, dispatchResult } = useContext(AuthContext);
  const { typeTest } = useContext(AuthContext);

  const [formValues, handleInputChange] = useForm({
    valueComment: "",
  });

  const { valueComment } = formValues;

  const [question, setQuestion] = useState([]);
  const [, setLoading] = useState(true);
  const [render, setRender] = useState(false);
  const [loadings, setLoadings] = useState();
  const [totalStem, setTotalStem] = useState();

  // Guarda las respuestas
  const [answer, setAnswer] = useState([]);
  const [value, setValue] = useState([]); //radio group
  const [dataMark, setDataMark] = useState([]);
  const [explanation, setExplanation] = useState([]);
  const [context, setContext] = useState(true);
  const [, setActive] = useState(false);
  const [activeTime, setActiveTime] = useState(false);

  const [startTime, setStarTime] = useState();

  const [themeStats, setThemeStats] = useState([]);
  const [stems] = useState([]);
  const [correctStemsChoice, setCorrectStemsChoice] = useState([]);
  const [modalVisibleSend, setModalVisibleSend] = useState(false);
  const [modalVisibleInfo, setModalVisibleInfo] = useState(true);
  const [modalVisibleComment, setModalVisibleComment] = useState(false);
  const [alphabet] = useState([
    "A)",
    "B)",
    "C)",
    "D)",
    "E)",
    "F)",
    "G)",
    "H)",
    "I)",
  ]);

  //Variable encargada de la paginación
  const [current, setCurrent] = useState(1);
  let setSize = false;
  const key = "updatable";
  const { TextArea } = Input;

  const time = new Date();

  const segundos = control.configuration.seconds;
  time.setSeconds(time.getSeconds() + parseInt(segundos));
  const [pressed, setPressed] = React.useState(false);

  React.useEffect(() => {
    window.onpopstate = (e) => {
      if (localStorage.getItem("header") === "0") {
        handleModalSendOk(true);
        localStorage.setItem("header", 1);
      }
    };
    window.onbeforeunload = (e) => {
      if (localStorage.getItem("header") === "0") {
        handleModalSendOk(true);
        localStorage.setItem("header", 1);
      }
    };
  });

  let node = React.createRef();

  useEffect(() => {
    renderMath();
  });

  const renderMath = () => {
    window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, node.current]);
  };

  useEffect(() => {
    onLoad();
    info();
    return () => {
      setLoadings();
    };
  }, []);

  useEffect(() => {
    if (render === false) {
      answer.map((itemAns) => {
        if (itemAns.value.length === 0) {
          dataMark
            .filter((item) => item.key === itemAns.id)
            .map((item) =>
              replaceItem(itemAns.id - 1, {
                key: item.key,
                questionNumber: (
                  <a
                    name={itemAns.id}
                    id={itemAns.id}
                    onClick={() => onChangeLogic(itemAns.id)}
                  >
                    {item.questionNumber}
                  </a>
                ),
                mark: (
                  <Checkbox
                    name={itemAns.id}
                    id={itemAns.id}
                    checked={item.mark.props.checked}
                  ></Checkbox>
                ),
                answered: (
                  <Checkbox
                    name={itemAns.id}
                    id={itemAns.id}
                    checked={
                      item.answered.props.checked === true
                        ? true
                        : item.answered.props.checked
                    }
                  ></Checkbox>
                ),
              })
            );
        }
      });
    }
  }, [answer]);

  const info = () => {
    message.info(t("msgAlert"), 0);
  };

  const onLoad = async () => {
    setModalVisibleInfo(true);
    var themeNow = [];
    const response = await control.configuration.urlStem;
    const responseContext = await stemContextRepo.getStemContext();
    for (var i = 0; i < response.data.data.length; i++) {
      setExplanation((explanation) => [...explanation, false]);
      setValue((value) => [...value, { id: null, value: null }]);
      setDataMark((dataMark) => [
        ...dataMark,
        {
          key: i + 1,
          questionNumber: i + 1,
          mark: <Checkbox name={i + 1} id={i + 1} checked={false}></Checkbox>,
          answered: (
            <Checkbox name={i + 1} id={i + 1} checked={false}></Checkbox>
          ),
        },
      ]);
      //} for setDataMark
      setTotalStem(response.data.data.length);
      var correctStems = 0;
      for (let s = 0; s < response.data.data[i].stem_choices.length; s++) {
        if (response.data.data[i].stem_choices[s].key_indicator === true) {
          correctStems = correctStems + 1;
        }
      }

      if (
        response.data.data[i].theme_id === undefined &&
        control.configuration.theme_id[i] !== undefined
      ) {
        themeNow[i] = control.configuration.theme_id[i];
      }
      if (response.data.data[i].theme_id !== undefined) {
        themeNow[i] = response.data.data[i].theme_id;
      }

      setCorrectStemsChoice((correctStemsChoice) => [
        ...correctStemsChoice,
        {
          id: response.data.data[i].id,
          correctStems: correctStems,
          theme: response.data.data[i].theme_id,
        },
      ]);
      if (response.data.data[i].stem_context_id !== null) {
        const context = responseContext.data.data.filter(
          (item) => item.id === response.data.data[i].stem_context_id
        );
        setQuestion((question) => [
          ...question,
          {
            id: response.data.data[i].id,
            statement: response.data.data[i].statement.replace(
              /<a /g,
              '<a target="blank" '
            ),
            solution_body: response.data.data[i].solution_body.replace(
              /<a /g,
              '<a target="blank" '
            ),
            theme_id: response.data.data[i].theme_id,
            stem_context_id: response.data.data[i].stem_context_id,
            answer: [response.data.data[i].stem_choices],
            context: context[0].body,
            multioption: response.data.data[i].is_multi_choice,
            stem_number: response.data.data[i].stem_number,
            url_source:
              response.data.data[i].url_source !== null
                ? response.data.data[i].url_source
                : null,
            url_stem_id:
              user.debug === true
                ? APP.apiUrl +
                  "nova/resources/stems/" +
                  response.data.data[i].id
                : null,
          },
        ]);
      }
      if (response.data.data[i].stem_context_id === null) {
        setQuestion((question) => [
          ...question,
          {
            id: response.data.data[i].id,
            statement: response.data.data[i].statement.replace(
              /<a /g,
              '<a target="blank" '
            ),
            solution_body: response.data.data[i].solution_body.replace(
              /<a /g,
              '<a target="blank" '
            ),
            theme_id: response.data.data[i].theme_id,
            stem_context_id: response.data.data[i].stem_context_id,
            answer: [response.data.data[i].stem_choices],
            context: null,
            multioption: response.data.data[i].is_multi_choice,
            stem_context_title: null,
            stem_number: response.data.data[i].stem_number,
            url_source:
              response.data.data[i].url_source !== null
                ? response.data.data[i].url_source
                : null,
            url_stem_id:
              user.debug === true
                ? APP.apiUrl +
                  "nova/resources/stems/" +
                  response.data.data[i].id
                : null,
          },
        ]);
      }
    } //total stem

    let resultTheme = themeNow.filter((item, index) => {
      return themeNow.indexOf(item) === index;
    });

    for (let q = 0; q < resultTheme.length; q++) {
      setThemeStats((themeStats) => [
        ...themeStats,
        {
          theme_id: resultTheme[q],
          correct_quantity: 0,
          unanswered_quantity: 0,
          incorrect_quantity: 0,
        },
      ]);
    }

    setLoading(false);
    setLoadings(false);
    setActiveTime(true);
    setStarTime(new Date());
  }; //fin de la funcion onload

  //metodo de las preguntas marcadas
  const onCheckboxClicked = (idx, isChecked) => {
    if (!isChecked) {
      setActive(false);
      dataMark
        .filter((item) => item.key === idx)
        .map((item) =>
          replaceItem(idx - 1, {
            key: item.key,
            questionNumber: (
              <a name={idx} id={idx} onClick={() => onChangeLogic(idx)}>
                {item.questionNumber}
              </a>
            ),
            mark: <Checkbox name={idx} id={idx} checked={false}></Checkbox>,
            answered: (
              <Checkbox
                name={idx}
                id={idx}
                checked={
                  item.answered.props.checked === true
                    ? true
                    : item.answered.props.checked
                }
              ></Checkbox>
            ),
          })
        );
    } else {
      setActive(true);
      dataMark
        .filter((item) => item.key === idx)
        .map((item) =>
          replaceItem(idx - 1, {
            key: item.key,
            questionNumber: (
              <a
                name={idx}
                id={idx}
                onClick={() => onChangeLogic(idx)}
                className="subrayado"
              >
                {item.questionNumber}
              </a>
            ),
            mark: <Checkbox name={idx} id={idx} checked={true}></Checkbox>,
            answered: (
              <Checkbox
                name={idx}
                id={idx}
                checked={
                  item.answered.props.checked === true
                    ? true
                    : item.answered.props.checked
                }
              ></Checkbox>
            ),
          })
        );
    }
  };

  //metodo de las preguntas respondidas
  const onClickedAnswered = (idx) => {
    dataMark
      .filter((item) => item.key === idx)
      .map((item) =>
        replaceItem(idx - 1, {
          key: item.key,
          questionNumber: (
            <a name={idx} id={idx} onClick={() => onChangeLogic(idx)}>
              {item.questionNumber}
            </a>
          ),
          mark: (
            <Checkbox
              name={idx}
              id={idx}
              checked={
                item.mark.props.checked === true
                  ? false
                  : item.mark.props.checked
              }
            ></Checkbox>
          ),
          answered: <Checkbox name={idx} id={idx} checked={true}></Checkbox>,
        })
      );
  };

  //método que add/delete la pregunta marcada
  const replaceItem = (indexToReplace, newItem) => {
    const updatedItems = [...dataMark];
    updatedItems.splice(indexToReplace, 1, newItem);
    return setDataMark(updatedItems);
  };

  //Método de las respuestas simples
  const onChange = (e) => {
    onClickedAnswered(e.target.name);
    replaceAnswer(e.target.name - 1, {
      id: e.target.name,
      value: e.target.value,
    });
    replaceSelect(e.target.name - 1, e.target.value);
  };
  //método de respuestas multiples
  const onChangeMulti = (e, current) => {
    onClickedAnswered(current);
    var index = answer.map((answers) => answers.id).indexOf(current);
    if (index === -1) {
      replaceAnswerMulti(current - 1, { id: current, value: e });
    } else {
      replaceAnswerMulti(index, { id: current, value: e });
    }
    value[current - 1].value = e;
  };
  const replaceAnswerMulti = (indexToReplace, newItem) => {
    const updatedItems = [...answer];
    updatedItems.splice(indexToReplace, 1, newItem);
    return setAnswer(updatedItems);
  };
  //metodo que guarda las respuestas seleccionadas
  const replaceSelect = (indexToReplace, newItem) => {
    const updatedItems = [...value];
    updatedItems.splice(indexToReplace, 1, newItem);
    return setValue(updatedItems);
  };
  //método que guarda las respuestas simples
  const replaceAnswer = (indexToReplace, newItem) => {
    const updatedItems = [...answer];
    updatedItems.splice(indexToReplace, 1, newItem);
    return setAnswer(updatedItems);
  };
  const onChangeContext = (e) => {
    setContext(e.target.checked);
  };
  const onChangeExplanation = (e) => {
    replaceExplanation(e.target.name - 1, e.target.checked);
  };
  //método que guarda las respuestas simples
  const replaceExplanation = (indexToReplace, newItem) => {
    const updatedItems = [...explanation];
    updatedItems.splice(indexToReplace, 1, newItem);
    return setExplanation(updatedItems);
  };

  //método contador de respuestas por tema
  const onThemeStats = (
    key,
    themeId,
    summation,
    summationNull,
    sumationIncorrect
  ) => {
    replaceThemeStats(key, {
      theme_id: themeId,
      correct_quantity: summation,
      unanswered_quantity: summationNull,
      incorrect_quantity: sumationIncorrect,
    });
  };
  //método que guarda la cantidad de respuestas buenas
  const replaceThemeStats = (indexToReplace, newItem) => {
    const updatedItems = [...themeStats];
    updatedItems.splice(indexToReplace, 1, newItem);
    return setThemeStats(updatedItems);
  };

  const sizeChangeLogic = (current, size) => {
    setSize = true;
    setCurrent(1);
  };

  const onChangeLogic = (current) => {
    setCurrent(current);
  };

  const onChangeCurrent = (current) => {
    if (current < totalStem) {
      setCurrent(current + 1);
    }
  };

  const deleteDuplicate = () => {
    let hash = {};
    let answers = answer.filter((o) =>
      hash[o.id] ? false : (hash[o.id] = true)
    );
    return setAnswer(answers);
  };

  const answerNull = () => {
    for (let i = 0; i < totalStem; i++) {
      if (!answer.some((item) => item.id === i + 1)) {
        answer.unshift({ id: i + 1, value: null });
      }
    }
    let answerSort = answer.sort((ans1, ans2) =>
      ans1.id > ans2.id ? 1 : ans1.id < ans2.id ? -1 : 0
    );
    return setAnswer(answerSort);
  };

  const handleModalSendOk = (modalVisibleSend) => {
    setRender(true);
    deleteDuplicate();
    answerNull();
    setModalVisibleSend(modalVisibleSend);
    onSend();
  };

  const handleModalSend = (modalVisibleSend) => {
    deleteDuplicate();
    setModalVisibleSend(modalVisibleSend);
  };

  const handleModalCommentOk = (
    modalVisibleComment,
    email,
    userId,
    name,
    // subscriptionId,
    subjectName,
    subjectId,
    stemNumber,
    stemId,
    valueComment
  ) => {
    setModalVisibleComment(modalVisibleComment);
    userRepo
      .sendEmail({
        email: email,
        userId: userId,
        name: name,
        // subscriptionId: subscriptionId,
        subjectName: subjectName,
        subjectId: subjectId,
        stemNumber: stemNumber,
        stemId: stemId,
        commment: valueComment,
      })
      .then(
        (response) => {
          Modal.info({
            title: t("info"),
            content: response.message,
          });
        },
        // COOKIE ERROR
        (error) => {
          Modal.error({
            title: t("error"),
            content: error.response.statusText,
          });
        }
      );
  };

  const handleModalComment = (modalVisibleComment) => {
    setModalVisibleComment(modalVisibleComment);
  };

  const onSubmitExam = () => {
    setRender(true);
    deleteDuplicate();
    answerNull();
    onSend();
  };

  const onSend = () => {
    var totalCorrect = 0;
    var totalIncorrect = 0;
    var correctAnswer = 0;
    var unanswered = 0;
    var incorrectAnswer = 0;
    var correctAnswerId;
    message.destroy();
    dispatchUser({
      type: types.header,
      payload: {
        header: 1,
      },
    });
    localStorage.setItem("header", 1);
    for (var k = 0; k < totalStem; k++) {
      correctAnswer = 0;
      incorrectAnswer = 0;
      if (answer[k].value !== null) {
        for (let l = 0; l < question[k].answer[0].length; l++) {
          if (typeof answer[k].value === "object") {
            for (let m = 0; m < answer[k].value.length; m++) {
              correctAnswerId = question[k].id;
              if (
                question[k].answer[0][l].key_indicator === true &&
                answer[k].value[m] === question[k].answer[0][l].id
              ) {
                correctAnswer += 1;
                stems.unshift({
                  is_correct: true,
                  is_marked: dataMark[k].mark.props.checked,
                  order: k + 1,
                  stem_id: question[k].id,
                  stem_choice_id: question[k].answer[0][l].id,
                });
              }
              if (
                question[k].answer[0][l].key_indicator === false &&
                answer[k].value[m] === question[k].answer[0][l].id
              ) {
                incorrectAnswer += 1;
                stems.unshift({
                  is_correct: false,
                  is_marked: dataMark[k].mark.props.checked,
                  order: k + 1,
                  stem_id: question[k].id,
                  stem_choice_id: question[k].answer[0][l].id,
                });
              }
            }
          }
          if (
            question[k].answer[0][l].key_indicator === true &&
            question[k].answer[0][l].id === answer[k].value
          ) {
            totalCorrect += 1;
            stems.unshift({
              is_correct: true,
              is_marked: dataMark[k].mark.props.checked,
              order: k + 1,
              stem_id: question[k].id,
              stem_choice_id: question[k].answer[0][l].id,
            });
            for (let i = 0; i < themeStats.length; i++) {
              if (themeStats[i].theme_id === question[k].theme_id) {
                onThemeStats(
                  i,
                  themeStats[i].theme_id,
                  (themeStats[i].correct_quantity += 1),
                  themeStats[i].unanswered_quantity,
                  themeStats[i].incorrect_quantity
                );
              }
            }
          }
          if (
            question[k].answer[0][l].key_indicator === false &&
            question[k].answer[0][l].id === answer[k].value
          ) {
            totalIncorrect += 1;
            stems.unshift({
              is_correct: false,
              is_marked: dataMark[k].mark.props.checked,
              order: k + 1,
              stem_id: question[k].id,
              stem_choice_id: question[k].answer[0][l].id,
            });
            for (let i = 0; i < themeStats.length; i++) {
              if (themeStats[i].theme_id === question[k].theme_id) {
                onThemeStats(
                  i,
                  themeStats[i].theme_id,
                  themeStats[i].correct_quantity,
                  themeStats[i].unanswered_quantity,
                  (themeStats[i].incorrect_quantity += 1)
                );
              }
            }
          }
        }
      }
      if (answer[k].value === null || answer[k].value.length === 0) {
        unanswered += 1;
        stems.unshift({
          is_correct: false,
          is_marked: dataMark[k].mark.props.checked,
          order: k + 1,
          stem_id: question[k].id,
          stem_choice_id: null,
        });

        for (let i = 0; i < themeStats.length; i++) {
          if (themeStats[i].theme_id === question[k].theme_id) {
            onThemeStats(
              i,
              themeStats[i].theme_id,
              themeStats[i].correct_quantity,
              (themeStats[i].unanswered_quantity += 1),
              themeStats[i].incorrect_quantity
            );
          }
        }
      } //preguntas con respuesta null
      if (
        correctAnswer === correctStemsChoice[k].correctStems &&
        correctAnswerId === correctStemsChoice[k].id
      ) {
        totalCorrect += 1;
        for (let j = 0; j < themeStats.length; j++) {
          if (themeStats[j].theme_id === question[k].theme_id) {
            onThemeStats(
              j,
              themeStats[j].theme_id,
              (themeStats[j].correct_quantity += 1),
              themeStats[j].unanswered_quantity,
              themeStats[j].incorrect_quantity
            );
          }
        }
      }
      if (
        correctAnswer !== correctStemsChoice[k].correctStems &&
        correctAnswerId === correctStemsChoice[k].id
      ) {
        totalIncorrect += 1;
        for (let j = 0; j < themeStats.length; j++) {
          if (themeStats[j].theme_id === question[k].theme_id) {
            onThemeStats(
              j,
              themeStats[j].theme_id,
              themeStats[j].correct_quantity,
              themeStats[j].unanswered_quantity,
              (themeStats[j].incorrect_quantity += 1)
            );
          }
        }
      }
    }

    dispatchResult({
      type: types.result,
      payload: {
        start: startTime,
        end: new Date(),
        score: 0,
        total: totalStem,
        totalCorrect: totalCorrect,
        totalIncorrect: totalIncorrect,
        totalUnanswered: unanswered,
      },
    });
    userTestRepo
      .createUserTest({
        start: startTime,
        end: new Date(),
        total_test_stems: totalStem,
        total_correct_choices: totalCorrect,
        type: typeTest.type,
        score: 0,
        user_id: user.userId,
        standard_test_id: control.standartTestId,
        theme_ids: themeStats,
        total_correct_quantity: totalCorrect,
        total_incorrect_quantity: totalIncorrect,
        total_unanswered_quantity: unanswered,
      })
      .then(
        (data) => {
          userTestStemRepo
            .createUserTestStem(data.data.id, { stems: stems })
            .then(
              (response) => {},
              (error) => {
                Modal.error({
                  title: t("error"),
                  content: error.response.statusText,
                });
              }
            );
        },
        // COOKIE ERROR
        (error) => {
          Modal.error({
            title: t("error"),
            content: error.response.statusText,
          });
        }
      );

    Modal.info({
      content: (
        <div>
          <div>
            <p> {t("score")} </p>
          </div>
          <br></br>
          <div>
            <p>
              {" "}
              {t("questionTotal")} : {totalStem}
            </p>
            <p>
              {" "}
              {t("questionCorrect")} : {totalCorrect}
            </p>
            <p>
              {" "}
              {t("questionIncorrect")} : {totalIncorrect}
            </p>
            <p>
              {" "}
              {t("questionUnanswered")} : {unanswered}
            </p>
          </div>
        </div>
      ),
      onOk() {},
    });
    setLocation("/resultado");
  };

  return (
    <div className="max-width">
      <div className="main-ensayos-button ">
        {activeTime ? (
          /*variables que calculan los segundo para el Timer*/
          <Button>
            <Timer expiryTimestamp={time} onSubmitExam={onSubmitExam} />
          </Button>
        ) : null}
      </div>
      <div className="main-ensayos-button">
        {control.configuration.value === 3 ? (
          <p>
            {t("radio31")} {control.configuration.to} {t("radio32")}{" "}
            {control.configuration.from}{" "}
          </p>
        ) : null}
      </div>
      {/* <div className="main-ensayo-grid" ref={node}> */}
      <div className="main-ensayo-grid" ref={node}>
        <Card
          className="mt-20 cols-1 "
          title={control.subjectName}
          bordered={false}
        >
          {typeof question != "undefined" ? (
            question
              .filter((item, i) => i + 1 === current)
              .map((item, i) => (
                <Card key={`key-essay-${current}`}>
                  <div className="pxy-10">
                    <div className="pl-10">
                      <Button type="primary">
                        {t("titleQuestion")} {current}
                      </Button>
                      {item.context !== null ? (
                        <div className="unselectable">
                          <div className="row">
                            <div className="col-80">
                              <p className="c-black mt-10">
                                {item.stem_context_title}
                              </p>
                            </div>
                            <div className="col-20">
                              <Form.Item>
                                <Checkbox
                                  name="context"
                                  onChange={onChangeContext}
                                  defaultChecked={true}
                                >
                                  {t("showContext")}
                                </Checkbox>
                              </Form.Item>
                            </div>
                          </div>
                          {context === true ? (
                            <div
                              className="main-ensayos-context mt-10"
                              dangerouslySetInnerHTML={{
                                __html: item.context,
                              }}
                            />
                          ) : null}
                          <Divider />
                        </div>
                      ) : null}
                      {user.debug === true ? (
                        <div style={{ float: "right" }}>
                          <a
                            href={item.url_stem_id}
                            target="_blank"
                            rel="noreferrer"
                            className="url"
                          >
                            {item.id}{" "}
                          </a>
                        </div>
                      ) : null}
                      <div
                        className="mt-10 unselectable"
                        dangerouslySetInnerHTML={{ __html: item.statement }}
                      />

                      <div className="row">
                        <div className="col-80 ">
                          {item.multioption === 0 ? (
                            <p className="c-black mt-10 unselectable">
                              {" "}
                              {t("option")}{" "}
                            </p>
                          ) : (
                            <p className="c-black mt-10 unselectable">
                              {" "}
                              {t("multioption")}{" "}
                            </p>
                          )}
                        </div>
                        <div className="col-20  mt-10 unselectable">
                          {dataMark[current - 1].mark.props.checked ? (
                            <CheckBox
                              initialState={true}
                              value={dataMark[current - 1]}
                              id={current}
                              name={current}
                              onChange={onCheckboxClicked}
                            />
                          ) : dataMark[current - 1].mark.props.checked ===
                              false &&
                            dataMark[current - 1].answered.props.checked ===
                              false ? (
                            <CheckBox
                              initialState={false}
                              value={dataMark[current - 1]}
                              id={current}
                              name={current}
                              onChange={onCheckboxClicked}
                            />
                          ) : (
                            <Checkbox
                              name={current}
                              onChange={onCheckboxClicked}
                              defaultChecked={false}
                            />
                          )}{" "}
                          {t("mark")}
                        </div>
                      </div>

                      {item.multioption === 0 ? (
                        <Radio.Group
                          onChange={onChange}
                          name={current}
                          value={value[current - 1]}
                        >
                          <Space direction="vertical">
                            {item.answer.length ? (
                              item.answer[0].map((Item1, index) => (
                                <Tag
                                  className="edit-tag"
                                  key={`key-tag-${Item1.id}`}
                                >
                                  <>
                                    {" "}
                                    {alphabet[index]}{" "}
                                    {user.debug === true
                                      ? Item1.key_indicator.toString()
                                      : null}{" "}
                                  </>
                                  <Radio
                                    key={`key-radio-group-${Item1.id}`}
                                    value={Item1.id}
                                  >
                                    <div className="row">
                                      <div className="col-80">
                                        <div
                                          className="mt-20 unselectable stem "
                                          style={{
                                            whiteSpace: "initial",
                                            textAlign: "justify",
                                          }}
                                          dangerouslySetInnerHTML={{
                                            __html: Item1.body.replace(
                                              /<a /g,
                                              '<a target="blank" '
                                            ),
                                          }}
                                        />
                                      </div>
                                      <div
                                        className="col-20"
                                        style={{
                                          marginTop: 20,
                                          marginLeft: 10,
                                        }}
                                      >
                                        {user.debug === true ? (
                                          <a
                                            className="url"
                                            href={
                                              APP.apiUrl +
                                              "nova/resources/stem-choices/" +
                                              Item1.id
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {" "}
                                            {Item1.id}
                                          </a>
                                        ) : null}
                                      </div>
                                    </div>
                                  </Radio>
                                </Tag>
                              ))
                            ) : (
                              <h1>{t("loading")}</h1>
                            )}
                          </Space>
                        </Radio.Group>
                      ) : (
                        <Checkbox.Group
                          className="unselectable"
                          onChange={(e) => onChangeMulti(e, current)}
                          name={current}
                          value={value[current - 1].value}
                        >
                          <Space direction="vertical">
                            {item.answer.length
                              ? item.answer[0].map((Item1, i) => (
                                  <Tag
                                    className="edit-tag"
                                    key={`key-tag-${Item1.id}`}
                                  >
                                    <>
                                      {" "}
                                      {alphabet[i]}{" "}
                                      {user.debug === true
                                        ? Item1.key_indicator.toString()
                                        : null}
                                    </>

                                    <Checkbox
                                      key={`key-checkbox-${Item1.id}`}
                                      value={Item1.id}
                                    >
                                      <div className="row">
                                        <div className="col-80">
                                          <div
                                            className="mt-20 stem"
                                            style={{
                                              whiteSpace: "initial",
                                              textAlign: "justify",
                                            }}
                                            dangerouslySetInnerHTML={{
                                              __html: Item1.body.replace(
                                                /<a /g,
                                                '<a target="blank" '
                                              ),
                                            }}
                                          />
                                        </div>
                                        <div
                                          className="col-20"
                                          style={{
                                            marginTop: 20,
                                            marginLeft: 10,
                                          }}
                                        >
                                          {user.debug === true ? (
                                            <a
                                              className="url"
                                              href={
                                                APP.apiUrl +
                                                "nova/resources/stem-choices/" +
                                                Item1.id
                                              }
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              {" "}
                                              {Item1.id}
                                            </a>
                                          ) : null}
                                        </div>
                                      </div>
                                    </Checkbox>
                                  </Tag>
                                ))
                              : null}
                          </Space>
                        </Checkbox.Group>
                      )}
                      <Divider></Divider>
                      <div>
                        {control.configuration.studyMode ? (
                          <Form.Item noStyle>
                            <Checkbox
                              value={explanation[current - 1]}
                              id={current}
                              name={current}
                              onChange={onChangeExplanation}
                            >
                              {t("explanation")}
                            </Checkbox>

                            {explanation[current - 1] === true ? (
                              <div
                                className="mt-20 unselectable main-ensayos-context"
                                dangerouslySetInnerHTML={{
                                  __html: item.solution_body,
                                }}
                              />
                            ) : null}

                            <Button
                              type="primary"
                              id={i + 1}
                              name={i + 1}
                              style={{ float: "right" }}
                              onClick={() => onChangeCurrent(current)}
                            >
                              {t("btnNext")}
                            </Button>
                          </Form.Item>
                        ) : null}
                      </div>

                      <div className="mt-20 cols-1 "></div>

                      {item.url_source !== null ? (
                        <div>
                          <Divider></Divider>

                          <div className="mt-5">
                            <a className="montserrat-9">
                            {t("informationTakenFrom")}{" "}
                              <button
                                className="url montserrat-9"
                                onClick={() =>
                                  window.open(item.url_source, "_blank")
                                }
                              >
                                {t("here")}
                              </button>
                            </a>
                            <a
                              className="montserrat-10"
                              style={{ float: "right" }}
                            >
                              <button
                                className="url montserrat-10"
                                onClick={() => handleModalComment(true)}
                              >
                                {t("comment")}{" "}
                              </button>
                            </a>
                            <Modal
                              title={t("comment")}
                              style={{ top: 20 }}
                              open={modalVisibleComment}
                              onOk={() =>
                                handleModalCommentOk(
                                  false,
                                  localStorage.getItem("email"),
                                  user.userId,
                                  localStorage.getItem("username"),
                                  // control.subscriptionId,
                                  control.subjectName,
                                  control.subjectId,
                                  item.stem_number,
                                  item.id,
                                  valueComment
                                )
                              }
                              onCancel={() => handleModalComment(false)}
                            >
                              <TextArea
                                className="unselectable"
                                value={valueComment}
                                name="valueComment"
                                onChange={handleInputChange}
                                showCount
                                maxLength={500}
                                autoSize={{ minRows: 3, maxRows: 5 }}
                                style={{ height: 100 }}
                              />
                            </Modal>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Card>
              ))
          ) : (
            <h1>{t("loading")}...</h1>
          )}

          <div className="pxy-10">
            <div className="main-ensayos-pagination-questions mt-10 mb-10">
              <Pagination
                current={current}
                onShowSizeChange={sizeChangeLogic}
                onChange={onChangeLogic}
                showSizeChanger={false}
                total={dataMark.length * 10}
              />
            </div>

            <div className="main-ensayos-buttons-questions">
              {loadings === false ? (
                pressed ? (
                  <Button type="primary" onClick={handleModalSend(true)}>
                    {t("btnfinalizar")}{" "}
                  </Button>
                ) : (
                  <Button type="primary" onClick={() => handleModalSend(true)}>
                    {t("btnfinalizar")}{" "}
                  </Button>
                )
              ) : null}
            </div>
          </div>
        </Card>

        <Card className="mt-20 cols-2" title={t("status")} bordered={false}>
          <div className="pxy-30 ">
            <Table
              size="small"
              pagination={true}
              columns={columns}
              dataSource={dataMark}
            />
          </div>
        </Card>
      </div>

      <Modal
        title={t("msginfo")}
        style={{ top: 30 }}
        closable={false}
        open={modalVisibleInfo}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <h3>{t("paragraph1")}</h3>
        <br />
        <p>{t("paragraph10")}</p>
        <p className="mt-5">{t("paragraph2")}</p>
        <p className="mt-5">{t("paragraph3")}</p>
        <p className="mt-5">{t("paragraph4")}</p>
        <p className="mt-5">{t("paragraph5")}</p>
        <br />
        <Divider></Divider>
        <h3>{t("paragraph6")}</h3>
        <br />
        <p>{t("paragraph7")}</p>
        <p className="mt-5">{t("paragraph8")}</p>
        <p className="mt-5">{t("paragraph9")}</p>
        <br />
        <LinearProgress color="primary" />
        <br />
        {loadings === false ? (
          <span>
            <Button
              type="primary"
              style={{ float: "right" }}
              onClick={() => setModalVisibleInfo(false)}
            >
              {t("btncontinue")}
            </Button>
            <br />
          </span>
        ) : null}
        <br />
      </Modal>

      <Modal
        title={t("info")}
        style={{ top: 20 }}
        open={modalVisibleSend}
        onOk={() => handleModalSendOk(false)}
        onCancel={() => handleModalSend(false)}
      >
        {t("sendTest")}
      </Modal>
    </div>
  );
};

export default Essays;
