import { useState, useEffect, useContext } from "react";
import { Modal,  Table } from "antd";
import { Link } from "wouter";
import { useTranslation } from "react-i18next";
import {
  userSubcriptionRepo,
  packageRepo,
} from "../../../store";
import { AuthContext } from "../../../auth/AuthContext";
import { types } from "../../../types/types";
import { useLocation } from "wouter";
import  listSubjects  from "../../../Config/genericFunctions";
import moment from "moment";


const ProfileTableUser = () => {

    const { t } = useTranslation();
    const [, setLocation] = useLocation();
    const columns = [
        {
          title: "",
          dataIndex: "select",
          key: "select",
        },
        {
          title: t("plan"),
          dataIndex: "plan",
          key: "plan",
        },
        {
          title: t("statusProfile"),
          dataIndex: "state",
          key: "state",
        },
        {
          title: t("start"),
          dataIndex: "start",
          key: "start",
        },
        {
          title: t("end"),
          dataIndex: "end",
          key: "end",
        },
        {
          title: t("quantitySubjects"),
          dataIndex: "quantitySubjects",
          key: "quantitySubjects",
        },
        {
          title: t("materia"),
          dataIndex: "materia",
          key: "materia",
        },
        {
          title: t("action"),
          dataIndex: "action",
          key: "action",
        },
    ];
    const [modalVisibleActivate, setModalVisibleActivate] = useState(false);
    const { user, dispatchSubcription } = useContext(AuthContext);
  
    const [userSubcriptions, setUserSubcriptions] = useState([]);
    
    const arraySubscription = [];
    const [, setCleanup] = useState();

    useEffect(() => {
        onLoadTableSubscription();
        return () => {
          setCleanup();
        };
    }, []);

    const onLoadTableSubscription = () =>{
        userSubcriptionRepo.getUserSubcriptionDetails().then(({ data }) => {
          data.map((item, i) => {
            var subjects;
            packageRepo
              .getMaxSubsjectsProfile(item.package.standard_test_id)
              .then(({ data }) => {
                subjects = quantitySubjects(
                  item.package.subjects_quantity,
                  data[0].maxSubjects,
                  item.subjects
                );
                var textAction = "";
                if (item.status === "Active") {
                  textAction = t("btncancelar");
                }
                if (item.status === "Pause") {
                  textAction = t("active");
                }
                if (
                  item.status === "Pending" ||
                  item.status === "Pending_payment" ||
                  item.status === "Trial" ||
                  item.status === "Created"
                ) {
                  textAction = t("toSubscribe");
                }
    
                setUserSubcriptions((userSubcriptions) => [
                  ...userSubcriptions,
                  {
                    key: i,
                    plan: user.debug === true ? item.id +' '+ item.package.name : item.package.name,
                    state: item.status,
                    start: moment(item.subscription_start)
                      .utc()
                      .format("DD/MM/YYYY"),
                    end: moment(item.subscription_end).utc().format("DD/MM/YYYY"),
                    quantitySubjects: item.package.subjects_quantity,
                    materia: subjects,
                    action: (
                      <Link href="" onClick={() => onClickAction(item)}>
                        <div className="card-matter-item">
                          <p className="montserrat-14 bold c-blue-second">
                            {textAction}
                          </p>
                        </div>
                      </Link>
                    ),
                  },
                ]);
              });
          });
        });
      }

      const quantitySubjects = (quantity, maxQuantity, subjects) => {
        var arraySubjects = "";
    
        if (quantity < maxQuantity) {
          for (let j = 0; j < subjects.length; j++) {
            arraySubjects += subjects[j].name + ",";
          }
        } else {
          arraySubjects = t("all");
        }
        return arraySubjects;
      };
    
    
    
      const onClickAction = (item) => {
        var subjectsNames = listSubjects.Subjects( item.subjects);
        if (item.status === "Active") {
          arraySubscription.push([
            item.id,
            moment(item.subscription_end).utc().format("MM/DD/YYYY"),
            item.package.name,
            subjectsNames,
          ]);
          unsubscribe();
        }
        if (item.status === "Pause") {
          activateSubscription(item.id);
        }
        if (item.status === "Pending" || item.status === "Pending_payment" || item.status === "Trial" || item.status === "Created") {
          dispatchSubcription({
            type: types.subcriptionById,
            payload: {
              id: item.id,
              status: item.status,
             },
          });
         setLocation('/factura/detalles');
        }     
      };
    
      const unsubscribe = () => {
        dispatchSubcription({
          type: types.subcriptionById,
          payload: {
            id: arraySubscription[0][0],
            subscriptionEnd: arraySubscription[0][1],
            paqueteName: arraySubscription[0][2],
            subjects: arraySubscription[0][3],
          },
        });
        setLocation("/cancelar/subscripcion");
      };
    
      const activateSubscription = async (id) => {
        setModalVisibleActivate(false);
       await userSubcriptionRepo.statusSubscription({
          id: id,
          status: "ACTIVE",
        });
        setLocation("/dashboard");
      }; 
    
      const handleModalActivate = (modalVisibleActivate) => {
        setModalVisibleActivate(modalVisibleActivate);
      };
      return (
        <>
      <Table
      style={{overflow: "auto"}}
      columns={columns}
      dataSource={userSubcriptions}
      pagination={true}
      size="middle"
    />
    
    <Modal
      title={t("info")}
      style={{ top: 20 }}
      open={modalVisibleActivate}
      onOk={() => activateSubscription()}
      onCancel={() => handleModalActivate(false)}
    >
      <p>{t("modalActiveSubscription")}</p>
    </Modal>
    </>
    );
  
}

export default ProfileTableUser;

