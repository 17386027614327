import HeaderPage from "../../Organisms/HeaderPage/HeaderPage";
import CardTheme from "../../Organisms/CardTheme/CardTheme";
import { useTranslation } from "react-i18next";

const Themes = () => {
  const { t } = useTranslation();
  return (
    <div className="max-width">
      
        <HeaderPage
          withMenu
          contentButton={t("contentButtonTheme")}
          routeButton="/materias"
          contentTitle={t("contentTitleTheme")}
          contentSubtitle={t("contentSubtitleThemeE")}
        />     

       <CardTheme /> 
    </div>
  );
};

export default Themes;
