import { useState } from "react";
import "./ResetPassword.scss";
import { Form, Input, Button, Modal } from "antd";
import { ReloadOutlined } from "@ant-design/icons"; 
import Logo from "../../Atoms/Logo/Logo";
import { Link, useLocation } from "wouter";
import { useTranslation } from "react-i18next";
import { userRepo } from "../../../store";

const ResetPassword = () => {
  const { t } = useTranslation();

  const [, setLocation] = useLocation();

  const [loadings, setLoadings] = useState();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token = params.get("token");
  

  const onFinish = (values) => {
    setLoadings(true);
    userRepo.resetPassword({
            token: token,
            email: values.email,
            password: values.password,
            password_confirmation: values.confirm,
          })
          .then(
            (data) => {
              setLoadings(false);
              Modal.success({
                title: t('info'),
                content: data.message ,
              });
              setLocation("/login");
              
            }, // COOKIE ERROR
            (error) => {    
              setLoadings(false);          
              Modal.error({
                title: t('error'),
                content:  error.response.data.message,
              });
            }
          );
      
     
  };

  return (
    <div className="login_container">
      <div>
        <Logo width={"200px"} height={"100%"} customClass="mb-10 text-center" />
        <Form
          name="login"
          className="login_container_form form"
          onFinish={onFinish}
        >
          <h1 className="mb-10">Forgot</h1>
          <p className="mb-10">{t("contentReset")}</p>

          <Form.Item
            label={t("email")}
            name="email"
            rules={[{ required: true, message: t("msgemail") }]}
          >
            <Input placeholder={t("email")} size="large" />
          </Form.Item>

          <Form.Item
            label={t("password")}
            name="password"
            hasFeedback
            rules={[
              { required: true, message: t("msgpassword") },
              {
                pattern:
                  "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[()~!@#$%^&*-_=+<><>.]).{8,}$",
                message: t("msgpassword1"),
              },
            ]}
          >
            <Input.Password placeholder={t("password")} size="large" />
          </Form.Item>

          <Form.Item
            name="confirm"
            label={t("confirmPassword")}
            dependencies={["password"]}
            hasFeedback
            rules={[
              { required: true, message: t("msgconfirm") },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t("coincide")));
                },
              }),
            ]}
          >
            <Input.Password placeholder={t("repeat")} size="large" />
          </Form.Item>

          <div className="login_remenber_container">
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              <Link href="/login"> {t("btncancel")} </Link>
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              icon={<ReloadOutlined />}
              loading={loadings}
            >
              {t("btnsend")}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ResetPassword;
