import {
  Button,
  Card,
  Modal,
  Form,
  InputNumber,
  Radio,
  Switch,
  Table,
} from "antd";
import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "wouter";
import "./CardThemeOption.scss";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useCheck } from "../../../customHooks/useCheck";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../auth/AuthContext";
import { types } from "../../../types/types";
import { periodRepo, stemRepo, themeRepo } from "../../../store";


const CardThemeOption = ({columns}) => {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const { control, dispatchControl, dispatchUser, dispatchType} = useContext(AuthContext);
  const isSmallScreen = useMediaQuery("(max-width: 840px)");
  const [questionSelect, setQuestionSelect] = useState(0);
  const [questionStart, setQuestionStart] = useState(0);
  const [questionFinal, setQuestionFinal] = useState(0);
  const [HH, setHH] = useState(0);
  const [MM, setMM] = useState(0);
  const [duration, setDuration] = useState(0);
  const [checkedQuestion, handleChangeQuestion] = useCheck(false);
  const [checkedStudy, handleChangeStudy] = useCheck(true);
  const [checkedAnswer, handleChangeAnswer] = useCheck(false);
  const [checkedTimer, handleChangeTimer] = useCheck(true);
  const [value, setValue] = useState(1); //radio group
  const [, setCleanup] = useState();
  const [selectionType, setSelectionType] = useState("checkbox");
  const [selectedRowKeys, setSelectedRowKeys] = useState();
  const [themePeriodContext, ] = useState([]);
  const [theme, setTheme] = useState([]);
  const [themeContextId, setThemeContextId] = useState([]);
  const [themePeriodId, setThemeId] = useState([]);
  const [themeIdQuanty, setThemeIdQuanty] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [contextto, setContextto] = useState(0);
  var context = 0;
  
  useEffect(() => {   
    onload();
    return () => {
      setCleanup();
    };
  }, []);

  useEffect(() => {
    sum(theme);
  }, [theme]);

  const onload = async () => {
    setCleanup(false);
    periodRepo.findPeriod(control.periodId).then(({ data }) => {
      setDuration(parseInt(data.test_duration));
    });
    var themeNow = [];
    const response = await themeRepo.getSubjectThemes({
      subject_id: control.subjectId,
      period_id: control.periodId,
    });

    for (let j = 0; j < response.data.data.length; j++) {
      setThemeId((themePeriodId) => [
        ...themePeriodId,
        response.data.data[j].id,
      ]);
      themeNow[j] = response.data.data[j].theme_id;
    }

    let resultTheme = themeNow.filter((item, index) => {
      return themeNow.indexOf(item) === index;
    });

    const responses = await themeRepo.getStemsTheme({
      themes_ids: resultTheme,
    });
    for (let i = 0; i < responses.data.data.length; i++) {
      if (responses.data.data[i].title !== null) {
        setThemeContextId( resultTheme);
        context = 2;
      } else {
        context = 1;
      }
    }
    for (let j = 0; j < responses.data.data.length; j++) {
      if (context === 2) {
        setContextto(context);
        setQuantity(responses.data.data[j].count);
        setThemeIdQuanty((themeIdQuanty) => [
          ...themeIdQuanty,
          {
            id: responses.data.data[j].theme_id,
            quantity: responses.data.data[j].count,
          },
        ]);
        setTheme((theme) => [
          ...theme,
          {
            key: j,
            select: "",
            theme: responses.data.data[j].theme.name,
            context: responses.data.data[j].title,
            quantity: responses.data.data[j].count,
          },
        ]);
      }
      if (context === 1) {
        setContextto(context);
        setQuantity(responses.data.data[j].count);
        setThemeIdQuanty((themeIdQuanty) => [
          ...themeIdQuanty,
          {
            id: responses.data.data[j].theme_id,
            quantity: responses.data.data[j].count,
          },
        ]);
        setTheme((theme) => [
          ...theme,
          {
            key: responses.data.data[j].theme_id,
            select: "",
            theme: responses.data.data[j].theme.name,
            context: responses.data.data[j].title,
            quantity: responses.data.data[j].count,
          },
        ]);
      }
    }     
  };

  const sum = (theme) => { 
    var total = theme.reduce(
      (sum, value) =>
        typeof value.quantity === "number" ? sum + value.quantity : sum,
      0
    );
    setQuantity(total);    
  };

  const onChangeNumberSelect = (value) => {
    setQuestionSelect(value);
  };
  const onChangeNumberStar = (value) => {
    setQuestionStart(value);
  };
  const onChangeNumberFinal = (value) => {
    setQuestionFinal(value);
  };  
  const onChangeNumberHH = (value) => {
    setHH(value);
  };
  const onChangeNumberMM = (value) => {
    setMM(value);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectionType(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onCancel = () => {
    setLocation("/dashboard");
  };
  
  const onSend = async (
    value,
    selectedRowKeys,
    selectionType,
    questionSelect,
    questionStart,
    questionFinal,
    checkedQuestion,
    checkedStudy,
    checkedAnswer,
    checkedTimer,
    HH,
    MM
  ) => {
    let HOST_NAME;
    let seconds;
    const STEMS_GENERAL = 1;
    const STEMS_SELECT = 2;
    const STEMS_SEGMENT = 3;
    const STEMS_THEME = 4;   
    
    if ((checkedTimer === true && (HH > 0 || HH === 0 && MM > 0 ) ) || (checkedTimer === false  && HH === 0) ) {
      if (checkedTimer === true ){
        const minutos = parseInt(HH) * 60 + parseInt(MM);
        seconds = parseInt(minutos) * 60;
      }
      if (checkedTimer === false ) {    
        seconds = parseInt(duration * 60);
      } 
    
    if (value === STEMS_GENERAL) {
      if (checkedQuestion === false && checkedAnswer === false) {
        HOST_NAME = stemRepo.getStems({
          period_theme_ids: themePeriodId,
          type: "answer",
          orderBy: "true"
        });
      }
      if (checkedQuestion === true && checkedAnswer === false) {
        HOST_NAME = stemRepo.getStems({
          period_theme_ids: themePeriodId,
          type: "answer-random",
        });
      }
      if (checkedQuestion === true && checkedAnswer === true) {
        HOST_NAME = stemRepo.getStems({
          period_theme_ids: themePeriodId,
          type: "answer-random,stem-choices-random",
        });
      }
      if (checkedQuestion === false && checkedAnswer === true) {
        HOST_NAME = stemRepo.getStems({
          period_theme_ids: themePeriodId,
          type: "stem-choices-random",
        });
      }

      dispatchControl({
        type: types.configuration,
        payload: {
          value: value,
          theme_id: themePeriodId,
          themeIdQuanty: themeIdQuanty,
          limit: questionSelect,
          to: questionStart,
          from: questionFinal,
          randomQuestion: checkedQuestion,
          randomAnswer: checkedAnswer,
          studyMode: checkedStudy,
          timer: checkedTimer,
          seconds: seconds,
          urlStem: HOST_NAME,
        },
      });
      dispatchUser({
        type: types.header,
        payload: {
          header: 0,
        },
      });
      dispatchType({
        type: types.typeTest,
        payload: {
          type: "custom"
        },
      }); 
      localStorage.setItem("header", 0);
      setLocation("/test");
    }
    if (value === STEMS_SELECT && questionSelect !== 0) {
      if (checkedQuestion === false && checkedAnswer === false) {
        HOST_NAME = stemRepo.getStems({
          period_theme_ids: themePeriodId,
          type: "answer",
          limit: questionSelect,
          orderBy: "true"
        });
      }
      if (checkedQuestion === true && checkedAnswer === false) {
        HOST_NAME = stemRepo.getStems({
          period_theme_ids: themePeriodId,
          type: "answer-random",
          limit: questionSelect,
        });
      }
      if (checkedQuestion === true && checkedAnswer === true) {
        HOST_NAME = stemRepo.getStems({
          period_theme_ids: themePeriodId,
          type: "answer-random,stem-choices-random",
          limit: questionSelect,
        });
      }
      if (checkedQuestion === false && checkedAnswer === true) {
        HOST_NAME = stemRepo.getStems({
          period_theme_ids: themePeriodId,
          type: "stem-choices-random",
          limit: questionSelect,
        });
      }

      dispatchControl({
        type: types.configuration,
        payload: {
          value: value,
          theme_id: themePeriodId,
          themeIdQuanty: themeIdQuanty,
          limit: questionSelect,
          to: questionStart,
          from: questionFinal,
          randomQuestion: checkedQuestion,
          randomAnswer: checkedAnswer,
          studyMode: checkedStudy,
          timer: checkedTimer,
          seconds: seconds,
          urlStem: HOST_NAME,
        },
      });
      dispatchUser({
        type: types.header,
        payload: {
          header: 0,
        },
      });
      dispatchType({
        type: types.typeTest,
        payload: {
          type: "custom"
        },
      }); 
      localStorage.setItem("header", 0);
      setLocation("/test");
    }
    if (value === STEMS_SELECT && questionSelect === 0) {
      Modal.error({
        title: t("error"),
        content: t("quantitySelection"),
      });
    }
    if (value === STEMS_SEGMENT && questionStart !== 0 && questionFinal !== 0) {
      if (questionStart < questionFinal) {
        var questionRange = questionFinal - questionStart;
        if (checkedQuestion === false && checkedAnswer === false) {
          HOST_NAME = stemRepo.getStems({
            period_theme_ids: themePeriodId,
            type: "answer",
            to: questionStart - 1,
            from: questionRange,
            orderBy: "true"
          });
        }
        if (checkedQuestion === true && checkedAnswer === false) {
          HOST_NAME = stemRepo.getStems({
            period_theme_ids: themePeriodId,
            type: "answer-random",
            to: questionStart - 1,
            from: questionRange,
          });
        }
        if (checkedQuestion === true && checkedAnswer === true) {
          HOST_NAME = stemRepo.getStems({
            period_theme_ids: themePeriodId,
            type: "answer-random,stem-choices-random",
            to: questionStart - 1,
            from: questionRange,
          });
        }
        if (checkedQuestion === false && checkedAnswer === true) {
          HOST_NAME = stemRepo.getStems({
            period_theme_ids: themePeriodId,
            type: "stem-choices-random",
            to: questionStart - 1,
            from: questionRange,
          });
        }

        dispatchControl({
          type: types.configuration,
          payload: {
            value: value,
            theme_id: themePeriodId,
            themeIdQuanty: themeIdQuanty,
            limit: questionSelect,
            to: questionStart,
            from: questionFinal,
            randomQuestion: checkedQuestion,
            randomAnswer: checkedAnswer,
            studyMode: checkedStudy,
            timer: checkedTimer,
            seconds: seconds,
            urlStem: HOST_NAME,
          },
        });
        dispatchUser({
          type: types.header,
          payload: {
            header: 0,
          },
        });
        dispatchType({
          type: types.typeTest,
          payload: {
            type: "custom"
          },
        }); 
        localStorage.setItem("header", 0);
        setLocation("/test");
      } else {
        Modal.error({
          title: t("error"),
          content: t("invalidRange"),
        });
      }
    }
    if (value === STEMS_SEGMENT && questionStart === 0 && questionFinal === 0) {
      Modal.error({
        title: t("error"),
        content: t("rangeSelection"),
      });
    }

    if (value === STEMS_THEME && selectedRowKeys !== undefined) {
      if (contextto === 2) {        
        const response = await themeRepo.getThemeContext({
          context_title: selectionType[0].context,
        });
        for (let i = 0; i < response.data.data.length; i++) {
          themePeriodContext[i] = response.data.data[i].id;
        }
        if(themePeriodContext !== '[]'){
        if (checkedQuestion === false && checkedAnswer === false) {
          HOST_NAME = await  stemRepo.getStemsContext({
            period_theme_ids: themePeriodContext,
            type: "answer,stem-context",
            context: selectionType[0].context,
            orderBy: "true"
          });
        }
        if (checkedQuestion === true && checkedAnswer === false) {
          HOST_NAME = await stemRepo.getStemsContext({
            period_theme_ids: themePeriodContext,
            type: "answer-random,stem-context",
            context: selectionType[0].context,
          });
        }
        if (checkedQuestion === true && checkedAnswer === true) {
          HOST_NAME = await stemRepo.getStemsContext({
            period_theme_ids: themePeriodContext,
            type: "answer-random,stem-choices-random,stem-context",
            context: selectionType[0].context,
          });
        }
        if (checkedQuestion === false && checkedAnswer === true) {
          HOST_NAME = await stemRepo.getStemsContext({
            period_theme_ids: themePeriodContext,
            type: "stem-choices-random,stem-context",
            context: selectionType[0].context,
          });
        }
      }
      }
      if (contextto === 1) {
        const response = await themeRepo.getPeriodThemes({
          theme_ids: selectionType[0].key,
        });
  
        for (let i = 0; i < response.data.data.length; i++) {
          themePeriodContext[i] = response.data.data[i].id;
        }
        if (checkedQuestion === false && checkedAnswer === false) {
          HOST_NAME = stemRepo.getStems({
            period_theme_ids: themePeriodContext,
            type: "answer",
            orderBy: "true"
          });
        }
        if (checkedQuestion === true && checkedAnswer === false) {
          HOST_NAME = stemRepo.getStems({
            period_theme_ids: themePeriodContext,
            type: "answer-random",
          });
        }
        if (checkedQuestion === true && checkedAnswer === true) {
          HOST_NAME = stemRepo.getStems({
            period_theme_ids: themePeriodContext,
            type: "answer-random,stem-choices-random",
          });
        }
        if (checkedQuestion === false && checkedAnswer === true) {
          HOST_NAME = stemRepo.getStems({
            period_theme_ids: themePeriodContext,
            type: "stem-choices-random",
          });
        }
      }
      if(themePeriodContext !== '[]'){
      dispatchControl({
        type: types.configuration,
        payload: {
          value: value,
          theme_id:  themeContextId,
          themeIdQuanty: themeIdQuanty,
          limit: questionSelect,
          to: questionStart,
          from: questionFinal,
          randomQuestion: checkedQuestion,
          randomAnswer: checkedAnswer,
          studyMode: checkedStudy,
          timer: checkedTimer,
          seconds: seconds,
          urlStem: HOST_NAME,
        },
      });
      dispatchUser({
        type: types.header,
        payload: {
          header: 0,
        },
      });
      dispatchType({
        type: types.typeTest,
        payload: {
          type: "custom"
        },
      }); 
      localStorage.setItem("header", 0);   
       
      setLocation("/test");
      }

      
    }
    if (value === STEMS_THEME && selectedRowKeys === undefined) {
      Modal.error({
        title: t("error"),
        content: t("selectionTheme"),
      });
    }
    }else {
        Modal.error({
          title: t("error"),
          content: t("msgRequiredTime"),
        });
    }
  };

  return (
   <>       
        <div className="mains-control-grid">         
          <div className="row">
            <div className="col ">
              <div className="pxy-20">
                <Radio.Group
                  onChange={onChange}
                  value={value}
                  className="ant-radio-group"
                >
                  <div className="row">
                    <div className="col">
                      <Radio value={1} checked>
                        {t("radio1")} {quantity}
                      </Radio>
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: 30 }}>
                    <div className="col-card col-60">
                      <Form.Item>
                        {" "}
                        <Radio value={2}>{t("radio21")}</Radio>{" "}
                      </Form.Item>
                    </div>
                    <div className="col-card col-40" style={{ marginLeft: -14 }}>
                      {value === 2 ? (
                        <Form.Item>
                          <InputNumber
                            name="selectquestion"
                            id="selectquestion"
                            min={1}
                            max={quantity}
                            defaultValue={quantity}
                            onChange={onChangeNumberSelect}
                          />
                        </Form.Item>
                      ) : (
                        <Form.Item>
                          <InputNumber name="selectquestion" disabled />
                        </Form.Item>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-card  col-70">
                      <Form.Item>
                        <Radio value={3}> {t("radio31")}</Radio>
                      </Form.Item>
                    </div>
                    <div className="col-card  col-10" style={{ marginLeft: -8 }}>
                      {value === 3 ? (
                        <Form.Item>
                          <InputNumber
                            name="questionStart"
                            id="questionStart"
                            min={1}
                            max={quantity}
                            defaultValue={1}
                            onChange={onChangeNumberStar}
                          />
                        </Form.Item>
                      ) : (
                        <Form.Item>
                          <InputNumber name="questionStart" disabled />
                        </Form.Item>
                      )}
                    </div>
                    <div className="col-card  col-15">
                      <Form.Item>
                        <p style={{ marginLeft: 10 , marginRight: 12 }}> {t("radio32")} </p>
                      </Form.Item>
                    </div>
                    <div className="col col-05">
                      {value === 3 ? (
                        <Form.Item>
                          <InputNumber
                            name="questionFinal"
                            id="questionFinal"
                            min={1}
                            max={quantity}
                            defaultValue={quantity}
                            onChange={onChangeNumberFinal}
                          />
                        </Form.Item>
                      ) : (
                        <Form.Item>
                          <InputNumber name="questionFinal" disabled />
                        </Form.Item>
                      )}
                    </div>
                  </div>
                  <div className="theme-table-row" style={{ marginTop: 5 }}>
                    <Radio value={4}>{t("radio4")}</Radio>
                  </div>
                </Radio.Group>
              </div>
            </div>   {isSmallScreen === false ?
            <div className="col pxy-20">
              <div className="row" style={{ marginTop: 10 }}>
                <div className="col col-35">
                  <p>{t("switch4")}</p>
                </div>
                <div
                  className="col col-20"
                >
                  <Switch
                    style={{ width: "40px" }}
                    defaultChecked
                    onChange={handleChangeTimer}
                  />
                  <label className="form-check-label"></label>
                </div>
                <div className="col col-45">
                  {checkedTimer ? (
                        <div className="row">                          
                        <InputNumber
                          name="HH"
                          min={0}
                          max={12}
                          style={{ width: 70, height: 30, marginRight: 6 }}
                          defaultValue={0}
                          onChange={onChangeNumberHH}
                        />
                        <p> : </p>
                        <InputNumber
                          name="MM"
                          min={0}
                          max={60}
                          style={{ width: 70, height: 30, marginLeft: 6 }}
                          defaultValue={0}
                          onChange={onChangeNumberMM}                        
                        />
                      </div>              
                  ) : (
                    <div className="row">
                    <div className="col">
                      <p className="mb-5">
                        {t("tiempo")} {duration} min.
                      </p>
                    </div>
                  </div>  
                   
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col col-35">
                  <p>{t("switch1")}</p>
                </div>

                <div className="col col-65">
                  <Switch
                    style={{ width: "30px" }}
                    defaultChecked
                    onChange={handleChangeQuestion}
                  />
                  <label className="form-check-label"></label>
                </div>
              </div>
              
               <div className="row">
                <div className="col col-35">
                  <p>{t("switch3")}</p>
                </div>
                <div className="col col-65">
                  <Switch
                    style={{ width: "40px" }}
                    defaultChecked
                    onChange={handleChangeAnswer}
                  />
                  <label className="form-check-label"></label>
                </div>
              </div>              
              <div className="row">
                <div className="col col-35">
                  <p>{t("switch2")}</p>
                </div>
                <div className="col col-65">
                  <Switch
                    style={{ width: "40px" }}
                    defaultChecked
                    onChange={handleChangeStudy}
                  />
                  <label className="form-check-label"></label>
                </div>
              </div>
            </div>
            :
            <div className="col pxy-20">
              <div className="row">
                <div className="row" style={{ padding: "4%"  }}>
                  {t("switch4")}       
                  <Switch
                    style={{ width: "40px", marginLeft:80  }}
                    defaultChecked
                    onChange={handleChangeTimer}
                  />             
                </div>
                  {checkedTimer ? (
                     <div className="row">
                     <InputNumber
                       name="HH"
                       min={0}
                       max={12}
                       style={{ width: 70, height: 30, marginRight: 6 }}
                       defaultValue={0}
                       onChange={onChangeNumberHH}
                     />
                     : 
                     <InputNumber
                       name="MM"
                       min={0}
                       max={60}
                       style={{ width: 70, height: 30, marginLeft: 6 }}
                       defaultValue={0}
                       onChange={onChangeNumberMM}                        
                     />
                   </div> 
                                     
                  ) : (
                    <div className="row">
                      <p className="mb-5" style={{ marginLeft: 12}}>
                        {t("tiempo")} {duration} min.
                      </p>                    
                  </div> 
                  )}
                
              </div>
              <div className="row" style={{ padding: "4%"  }}>
                  {t("switch1")}                
                  <Switch
                    style={{ width: "40px",  marginLeft:178, marginTop: -40  }}
                    defaultChecked
                    onChange={handleChangeQuestion}
                  />
              </div>
              
               <div className="row" style={{ padding: "4%"  }}>
                {t("switch3")}               
                  <Switch
                    style={{ width: "40px",  marginLeft:178, marginTop: -40   }}
                    defaultChecked
                    onChange={handleChangeAnswer}
                  />
                 
              </div>              
              <div className="row" style={{ padding: "4%"  }}>
                  {t("switch2")}               
                  <Switch
                    style={{ width: "40px", marginLeft:80 }}
                    defaultChecked
                    onChange={handleChangeStudy}
                  />                  
              </div>
            </div>
            }
          </div>
          <div className="row-table px-20">
            {value === 4 ? (
              <div className="card-theme-table">
                <Card className="mt-30 mb-20">
                  <Table
                    rowSelection={{
                      type: selectionType,
                      ...rowSelection,
                    }}
                    columns={columns}
                    dataSource={theme}
                    pagination={true}
                    size="middle"
                  />
                </Card>
              </div>
            ) : (
              <div className="card-theme-table">
                <Card className="mt-30 mb-20">
                  <Table
                    columns={columns}
                    dataSource={theme}
                    pagination={true}
                    size="middle"
                  />
                </Card>
              </div>
            )}
          </div>
        </div>
      
      <div
        className="themes-table-switchs-buttons pxy-20"
        style={{ float: "right" }}
      >
        <Button onClick={onCancel}>{t("btncancelar")}</Button>
        <Button
          type="primary"
          onClick={() =>
            onSend(
              value,
              selectedRowKeys,
              selectionType,
              questionSelect,
              questionStart,
              questionFinal,
              checkedQuestion,
              checkedStudy,
              checkedAnswer,
              checkedTimer,
              HH,
              MM
            )
          }
        >
          {t("btninicio")}
        </Button>
      </div>
    </>
  );
};

export default React.memo(CardThemeOption);
