import React,  { useEffect, useState, useContext } from "react";
import { Card } from "antd";
import { AuthContext } from "../../../auth/AuthContext";
import { userSubjectStatsRepo } from "../../../store";
import { RadialBar } from "@ant-design/plots";
import { useTranslation } from "react-i18next";

const ChartsRadial = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  
  const [dataList, setDataList] = useState([]);
  const [, setCleanup] = useState();
 
  const asyncFetch =  () => {
        
      userSubjectStatsRepo.getUserSubjectStatsAverageList(user.userId).then(({ data }) => {
      
        for (let j = 0; j < data.length; j++) {           
          setDataList((dataList) => [
            ...dataList,
            {
              name: data[j].subject.name,
              puntaje: Math.round(data[j].sum)            
            },
          ]);        
        } 
       
    })
    
    setCleanup(false);
  };

  useEffect(() => {
    asyncFetch();
    return () => {
      setCleanup();
    };
  }, []);

  if (dataList !== "[]") {
    var config = {
      width: 390,
      height: 400,
      data: dataList,
      xField: "name",
      yField: "puntaje",
      radius: 1,
      innerRadius: 0.5,
      maxAngle: 250,
      type: "line",
      xAxis: {
        title: {
          text: t('matter'),
          style: { fill: "black", fontSize: 12 },
        },
      
      },
      tooltip: {
        tooltipContainerFillColor: "black",
        fontFamily: "Montserrat, sans-serif",        
        formatter: (datum)=> {
          return {        
            name: datum.name,
            value: datum.puntaje + '%'
          };
        },
      },
      
      
    };
  }
  return (
    <Card title={t('radial')} bordered={false}>
      {dataList !== "[]" ? <RadialBar style={ { width: "80% !important", height: "80% !important" }} {...config} /> : null}
    </Card>
  );
};

export default ChartsRadial;
