import Repository from './repository.js'
export default class UserTestStemRepository extends Repository {
    constructor(apis) {
        super(apis)
    }

    createUserTestStem(userTestId,params = {}) {            
        const {stems} = params;    
        return this.$axios            
            .post('api/user-tests/'+userTestId+'/stems',{
                stems: stems 
            })
            .then(({data}) => data)
    }

    async getUserTestStem(userTestId){
        return await this.$axios            
             .get('api/user-tests/'+userTestId+'/stems' ) 
             .then(({ data }) => data)          
    }
   
   
}
