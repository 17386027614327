import Repository from './repository.js'

export default class FeedbackBuilderRepository extends Repository {
    constructor(apis) {
        super(apis)
    }

    show(params = {}){
       return this.$axios
            .get('api/feedback-builder', {params: params})
    }
}


