import { useContext, useEffect, useState } from "react";
import "./Login.scss";
import { Form, Input, Button, Modal } from "antd";
import { PoweroffOutlined } from "@ant-design/icons";
import Logo from "../../Atoms/Logo/Logo";
import { Link, useLocation } from "wouter";
import { AuthContext } from "../../../auth/AuthContext";
import { types } from "../../../types/types";
import { useTranslation } from "react-i18next";
import {
  authRepo,
  userRepo,
  userSubcriptionRepo,
  verifyEmailRepo,
} from "../../../store/index";
import { APP_IKENGA } from "../../../Config/config";
import * as authHelper from "../../../utils/auth";
import  trialDays  from "../../../Config/genericFunctions";


const Login = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [, setLocation] = useLocation();
  const [loadings, setLoadings] = useState();
  const [, setCleanup] = useState();
  const { dispatchUser, dispatchSubcription } = useContext(AuthContext);

  var id = window.location.href.split("/");


  useEffect(() => {
    if (id[4] !== undefined) {
      if (
        localStorage.getItem("banner") === null ||
        localStorage.getItem("banner") === "null"
      ) {
        window.location.reload();
      }
      i18n.changeLanguage(id[4]);
      localStorage.setItem("lang", id[4]);
      localStorage.setItem("banner", id[5]);
    } else {
      i18n.changeLanguage(localStorage.getItem("lang"));
    }

    localStorage.removeItem("matters");
    localStorage.removeItem("active");
    localStorage.removeItem("firstAddress");
    localStorage.removeItem("secondAddress");
    localStorage.removeItem("state");
    localStorage.removeItem("city");
    localStorage.removeItem("town");
    localStorage.removeItem("postalCode");
    localStorage.removeItem("message");
    return () => {
      setCleanup();
      setLoadings();
    };
  }, []);

  const onFinish = (values) => {
    setLoadings(true);
    authRepo.login(values).then(
      () => {
        userRepo.showUser().then(
          ({ data }) => {
            authHelper.setLocalCurrentUser(
              data.name,
              data.last_name,
              data.avatar,
              data.email
            );
            dispatchUser({
              type: types.login,
              payload: {
                name: data.name,
                userId: data.id,
                avatar: data.avatar,
                debug: data.permissions.length === 0 ? false : true,
              },
            });
            onSupcription();
          },
          // GET USER ERROR
          (error) => {
            setLoadings(false);
            Modal.error({
              title: t("error"),
              content: error.response.data.message,
            });
          }
        );
      },
      // LOGIN ERROR
      (error) => {
        setLoadings(false);
        if (typeof error.response.data.message.email === "string") {
          Modal.error({
            title: t("error"),
            content: error.response.data.message.email,
          });
        }
        if (typeof error.response.data.message.verify === "string") {
          Modal.success({
            title: t("info"),
            content: (
              <p className=" mb-10 mt-20 ">
                {error.response.data.message.verify}
                <Link href="/" onClick={() => onVerifyEmail(values.email)}>
                  <br></br>
                  <span className="link"> Reenviar Correo </span>
                </Link>
              </p>
            ),
          });
        }
        if (error.response.status === 419) {
          Modal.error({
            title: t("error"),
            content: error.response.data.message,
          });
          setLocation("/login");
        } else {
          Modal.error({
            title: t("error"),
            content: error.response.data.message,
          });
        }
      }
    );
  };

  const onVerifyEmail = (email) => {
    verifyEmailRepo.resendVerifyEmail(email).then(({ data }) => {
      localStorage.setItem("redirect", 0);
      Modal.destroyAll();
      Modal.success({
        title: t("info"),
        content: t("checkEmail"),
      });
      setLoadings(false);
    });
  };

  const onSupcription = () => {
    userSubcriptionRepo.getUserSubcription().then(({ data }) => {

      if (data.length === 0) {
        localStorage.setItem("redirect", 0);
        localStorage.setItem("header", 0);
        dispatchUser({
          type: types.header,
          payload: {
            header: 1,
          },
        });
        dispatchSubcription({
          type: types.subcription,
          payload: {
            active: 1,
            status: "",
            days: "",
          },
        });
        setLocation("/subscripcion/paquete");
       
      } else {
        var status = "";
        for (let l = 0; l < data.length; l++) {
          if (data.length === 1 && data[l].status === "Created") {
            dispatchSubcription({
              type: types.subcriptionById,
              payload: {
                id: data[l].id,
                status: data[l].status,
              },
            });
            setLocation("/factura/detalles");
          }
          if (data[l].status === "Trial") {           
            status = data[l].status;
            localStorage.setItem("redirect", 1);
            localStorage.setItem("header", 1);
            dispatchSubcription({
              type: types.subcription,
              payload: {
                active: 1,
                status: status,
                days: trialDays.calculationTrialDays(data[l].trial_started_at, data[l].trial_duration),
              },
            });
            setLocation("/dashboard");
          }
          if (
            data[l].status === "Active" ||
            data[l].status === "Pause" ||
            data[l].status === "Pending_payment" ||
            data[l].status === "Pending" ||
            data[l].status === "Canceled" ||
           ( data.length > 1 && data[l].status === "Created")
          ) {
            dispatchSubcription({
              type: types.subcription,
              payload: {
                active: 1,
                status: status,
                days: "",
              },
            });
            localStorage.setItem("redirect", 1);
            localStorage.setItem("header", 1);
            setLocation("/dashboard");
          }
        }
      }
    });
    setCleanup(false);
  };

  return (
    <div className="login_container">
      <div>
        <Logo width={"200px"} height={"100%"} customClass="mb-10 text-center" />
        <Form
          name="login"
          className="login_container_form form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <h1 className="mb-10">{t("login")}</h1>
          <p className="mb-10">{t("contentLogin")}</p>

          <Form.Item
            label={t("email")}
            name="email"
            rules={[{ required: true, message: t("msgemail") }]}
            normalize={(value, prevVal, prevVals) => value.trim()}
          >
            <Input placeholder={t("email")} size="large" />
          </Form.Item>

          <Form.Item
            label={t("password")}
            name="password"
            rules={[{ required: true, message: t("msgpassword") }]}
            normalize={(value, prevVal, prevVals) => value.trim()}
          >
            <Input.Password placeholder={t("password")} size="large" />
          </Form.Item>

          <div className="login_remenber_container ">
            <a href={APP_IKENGA.ikengaUrl} rel="noopener noreferrer">
              <Button>{t("btnback")}</Button>
            </a>

            <Button
              type="primary"
              className="login-form-button"
              htmlType="submit"
              icon={<PoweroffOutlined />}
              loading={loadings}
            >
              {t("btn")}
            </Button>
          </div>
          <p className=" mb-10 mt-20 ">
            {t("recover")}
            <Link href="/olvido">
              <span className="link">{t("regain")}</span>
            </Link>
          </p>
          <p className="">
            {t("subscribeLogin")}{" "}
            <a href="/paquetes">
              <span className="link">{t("signin")}</span>
            </a>
          </p>
        </Form>
      </div>
    </div>
  );
};

export default Login;
