import { Redirect, Route } from "wouter";
import PropTypes from "prop-types";
import { AppLayout } from "../components/Pages/AppLayout/AppLayout";

const Protected = ({ isAuthenticated, component: Component, ...rest }) => {
  
  return (
    <AppLayout>
      <Route
        {...rest}
        component={(props) =>
          !isAuthenticated ? (
            localStorage.getItem("redirect") === "0" ||
            localStorage.getItem("redirect") === null ? (
              <Redirect to="/" />
            ) : (
              <Redirect to="/dashboard" />
            )
          ) : (
            <Component {...props} />
          )
        }
      />
    </AppLayout>
  );
};
Protected.protoTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
};

export default Protected;
