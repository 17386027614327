import { useEffect, useState } from 'react'
import { Router, Switch, useLocation } from 'wouter'
import Controls from './components/Pages/Controls/Controls'
import ConfirmRegister from './components/Pages/ConfirmRegister/ConfirmRegister'
import SuccessfulPayment from './components/Pages/SuccessfulPayment/SuccessfulPayment'
import SuccessfulSubscription from './components/Pages/SuccessfulSubscription/SuccessfulSubscription'
import Essays from './components/Pages/Essays/Essays'
import EssaysEnsayo from './components/Pages/Essays/EssaysEnsayo'
import EssaysHistorical from './components/Pages/EssaysHistorical/EssaysHistorical'
import EssaysCheck from './components/Pages/EssaysCheck/EssaysCheck'
import EssaysWrong from './components/Pages/EssaysWrong/EssaysWrong'
import Historical from './components/Pages/Historical/Historical'
import InvoiceDetails from './components/Pages/InvoiceDetails/InvoiceDetails'
import Forgot from './components/Pages/Forgot/Forgot'
import GridPriceTable from './components/Pages/GridPriceTable/GridPriceTable'
import Home from './components/Pages/Home/Home'
import ResetPassword from './components/Pages/ResetPassword/ResetPassword'
import Login from './components/Pages/Login/Login'
import NotFound from './components/Pages/NotFound/NotFound'
import Register from './components/Pages/Register/Register'
import RegisterSubcriptionPackage from './components/Pages/RegisterSubcriptionPackage/RegisterSubcriptionPackage'
import AddPackage from './components/Pages/AddPackage/AddPackage'
import Unsubscribe from './components/Pages/Unsubscribe/Unsubscribe'
import Result from './components/Pages/Result/Result'
import Statistics from './components/Pages/Statistics/Statistics'
import Themes from './components/Pages/Themes/Themes'
import ThemesHistorical from './components/Pages/ThemesHistorical/ThemesHistorical'
import ProfileAccountEdit from './components/Pages/ProfileAccountEdit/ProfileAccountEdit'

import Public from './Routes/Public'
import Protected from './Routes/Protected'

const AppRouter = () => {
  const [location] = useLocation()

  const [isAuthenticated, setIsAuthenticated] = useState()

  useEffect(() => {
    if (localStorage.getItem('isAuthenticated') === 'true') {
      setIsAuthenticated(true)    
    } else {      
      setIsAuthenticated(true)
    }
    window.scrollTo({ top: 0, behavior: 'smooth' }) // Cada vez que ingrese a una Page el scroll sube al top
  }, [location])

  

  return (
    <Switch>
      
      <Router path='/registro/:id/:lang/:banner' exact >
        <Register />
      </Router>

      <Router path='/registro/:id/:lang' exact >
        <Register />
      </Router>

       <Router path='/paquetes' exact>
        <GridPriceTable />
      </Router>       

      <Router path='/verify-email'>
        <ConfirmRegister />
      </Router>

      <Router path='/'>
        <Login />
      </Router>

      <Router path='/resetpassword'>
        <ResetPassword />
      </Router>

      <Router path='/olvido'>
        <Forgot />
      </Router>

      <Public
        path='/registro/:id/:lang/:banner'
        component={Register }
        isAuthenticated={isAuthenticated}
      />

      <Public
        path='/login/:lang/:banner'
        component={Login}
        isAuthenticated={isAuthenticated}
      />

      <Public
        path='/login' exact
        component={Login}
        isAuthenticated={isAuthenticated}
      />

      <Router path='/confirmacion'>
        <SuccessfulPayment />
      </Router>

      <Router path='/subscripcion/exitosa'>
        <SuccessfulSubscription />
      </Router>

       
      <Protected
        path='/dashboard'
        component={Home}
        isAuthenticated={isAuthenticated}
      />      
      
      <Protected
        path='/subscripcion/paquete'
        component={RegisterSubcriptionPackage}
        isAuthenticated={isAuthenticated}
      />
      <Protected
        path='/cuenta/editar'
        component={ProfileAccountEdit}
        isAuthenticated={isAuthenticated}
      />

      <Protected
        path='/agregar/subscripcion'
        component={AddPackage}
        isAuthenticated={isAuthenticated}
      />
      <Protected
        path='/materias'
        exact
        component={Controls}
        isAuthenticated={isAuthenticated}
      />
     
      <Protected
        path='/test'
        component={Essays}
        isAuthenticated={isAuthenticated}
      />      
      <Protected
        path='/test/ensayo'
        component={EssaysEnsayo}
        isAuthenticated={isAuthenticated}
      />
      <Protected
        path='/test/historial'
        component={EssaysHistorical}
        isAuthenticated={isAuthenticated}
      />
      <Protected
        path='/revisar'
        component={EssaysCheck}
        isAuthenticated={isAuthenticated}
      />
      <Protected
        path='/revision'
        component={EssaysWrong}
        isAuthenticated={isAuthenticated}
      />
      <Protected
        path='/temas'
        exact
        component={Themes}
        isAuthenticated={isAuthenticated}
      />
      <Protected
        path='/temashistorial'
        exact
        component={ThemesHistorical}
        isAuthenticated={isAuthenticated}
      />
      <Protected
        path='/historico'
        exact
        component={Historical}
        isAuthenticated={isAuthenticated}
      />      
      <Protected
        path='/estadistica'
        exact
        component={Statistics}
        isAuthenticated={isAuthenticated}
      />
      <Protected
        path='/resultado'
        exact
        component={Result}
        isAuthenticated={isAuthenticated}
      />
      <Protected
        path='/cancelar/subscripcion'
        exact
        component={Unsubscribe}
        isAuthenticated={isAuthenticated}
      />
      <Protected
        path='/factura/detalles'
        exact
        component={InvoiceDetails}
        isAuthenticated={isAuthenticated}
      />
      <Protected component={NotFound} isAuthenticated={isAuthenticated} />
    </Switch>
  )
}

export default AppRouter
