import CardAverageList from "../../Molecules/CardAverageList/CardAverageList";
import BannerDashboard from "../../Organisms/BannerDashboard/BannerDashboard";
import ChartsBellGauss from "../../Organisms/ChartsGauss/ChartsBellGauss";
import ChartsRadial from "../../Organisms/ChartsRadial/ChartsRadial";
import ChartsColumn from "../../Organisms/ChartsColumn/ChartsColumn";
import GeneralAverage from "../../Organisms/GeneralAverage/GeneralAverage";

import "./Home.scss";

const Home = () => {
  return (
    <div className="page-home">
      <div className="page-home_grid max-width">
        <div className="cols-1">
          <BannerDashboard />
        </div>
        <div className="cols-2">
          <GeneralAverage />
        </div>
      </div>
      <div className="row  mt-15 max-width">
        <div className="col-home col-40 mt-15 mr-5" style={{background: "white"}}>
        <ChartsBellGauss />
        </div>        
        <div className="col-home col-40 mt-15">
        <ChartsRadial />
        </div>
      </div>
      <div className="row mt-15 max-width">
      <div className="col-home col-40 mt-15 mr-5">
        <ChartsColumn />
        </div>
        <div className="col-home col-40 mt-15" style={{background: "white"}}>
        <CardAverageList />
        </div>
      </div>
    </div>
  );
};

export default Home;
