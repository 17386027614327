
import Repository from './repository.js'
export default class UserRepository extends Repository {
    constructor(apis) {
        super(apis)
    }

    createUser(params = {}) {
        
        const {name, last_name, email, password, phone, tax_id, timezone, language, terms_and_conditions } = params;        
        return this.$axios            
            .post('api/register', {
               name: name,
               last_name: last_name,
               email: email,
               password :password,
               phone: phone,
               tax_id: tax_id,
               timezone: timezone,
               language: language,
               terms_and_conditions: terms_and_conditions
            }).then(({data}) => data)
    }

    showUser() {
        return this.$axios            
            .get('api/me')
            .then(({data}) => data)
    }

    async getUser() {
        return await  this.$axios            
            .get('api/me')
            .then(({data}) => data)
    
    }

    verifyEmail(url) {
        return this.$axios            
            .get(url)
            .then(({data}) => data)
    }
    
    forgot(params = {}) {     
                
        return this.$axios            
            .post('api/forgot-password', {               
               email: params
            }).then(({data}) => data)
    }

    resetPassword(params = {}) {
       //to-do response un objeto
        const { token, email, password, password_confirmation } = params;        
        return this.$axios            
            .post('api/reset-password', {               
               token: token,
               email: email,
               password: password,
               password_confirmation: password_confirmation,
            }).then(({data}) => data)
    }

    sendEmail(params = {}) {      
       
        return this.$axios            
            .post('api/mail/stem-correction-request', {params: params})
            .then(({data}) => data)
    }
    
    upload(formData = {}) {
                
        return this.$axios            
            .post('api/profile-upload',              
                formData,
                {headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(({data}) => data)
    }
    

    editUserAll(params = {}) {  
        const {name, last_name, email, phone, timezone, language, terms_and_conditions }  = params;            
        return this.$axios            
            .patch('api/me',              
            {   name: name,
                last_name: last_name,
                email: email,
                phone: phone,
                timezone: timezone,
                language: language,
                terms_and_conditions: terms_and_conditions
            })
            .then(({data}) => data)
    }

    editUserPassword(params = {}) {  
        const {name, last_name, email, old_password, new_password, phone, timezone, language, terms_and_conditions }  = params;            
        return this.$axios            
            .post('api/change-password',              
            {   name: name,
                last_name: last_name,
                email: email,
                old_password: old_password,
                new_password: new_password,
                phone: phone,
                timezone: timezone,
                language: language,
                terms_and_conditions: terms_and_conditions
            })
            .then(({data}) => data)
    }
    
}
