import "./GeneralAverageBySubject.scss";
import { useState, useEffect, useContext } from "react";
import averageImg from "../../../assets/average.svg";
import { Card, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../auth/AuthContext";
import { userTestRepo } from "../../../store";
import { useLocation } from "wouter";

const GeneralAverageBySubject = () => {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const { user, control } = useContext(AuthContext);
  const [average, setAverage] = useState(0);
  const [correct, setCorrect] = useState(0);
  const [count, setCount] = useState(0);
  const [, setCleanup] = useState();
  const [sendingRequest, setSendingRequest] = useState(false);

  useEffect(() => {
    onload();
    return () => {
      setCleanup();
    };
  }, []);

  const onload = () => {
    if (sendingRequest === false) {
      setSendingRequest(true);      
      var sumTotal = 0;
      var sumCorrect = 0;
      userTestRepo
        .getStemsCorrectThemes(control.subjectId, user.userId)
        .then(({ data }) => {
          if (data.length !== 0) {
            for (let l = 0; l < data.length; l++) {
              sumTotal = parseInt(data[l].total_test_stems) + sumTotal;
              sumCorrect = parseInt(data[l].total_correct_quantity) + sumCorrect;
              setCorrect(sumCorrect);
              setCount(sumTotal);
              setAverage(Math.round(sumCorrect *100 / sumTotal));
            }
          } else {
            setCorrect(0);
            setCount(0);
            setAverage(0);
          }
        },
          (error) => {
            if (error.response.status === 401) {
              Modal.error({
                title: t("error"),
                content: error,
              });
              setLocation("/login");
            } else {
              Modal.error({
                title: t("error"),
                content: error,
              });
            }
          }
        );

      setCleanup(false);
    }
  };

  return (
    <Card title={t("promedio")} bordered={false}>
      <div className="main-average_icon">
        <img src={averageImg} alt="Average" />
        <p className="montserrat-50 bold">{average} %</p>
        <ol className="card-average-list" style={{ listStyleType: "none" }}>
          <li>
            {" "}
            {t("questionCorrectAverage")} : {correct}
          </li>
          <li>
            {" "}
            {t("questionTotalAverage")} : {count}
          </li>
        </ol>
      </div>
    </Card>
  );
};

export default GeneralAverageBySubject;
