import {
  Button,
  Card,
  Modal,
  InputNumber,
  Radio,
  Switch,
  Form,
  Table,
} from "antd";
import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "wouter";
import "./CardThemeOptionExam.scss";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useCheck } from "../../../customHooks/useCheck";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../auth/AuthContext";
import { types } from "../../../types/types";
import { periodRepo, themeRepo } from "../../../store";

const CardThemeOptionExam = ({columns}) => {
  const { t } = useTranslation();
  
  const [, setLocation] = useLocation();
  const { control, dispatchControl, dispatchPageHeader, dispatchType } =
    useContext(AuthContext);  
  const isSmallScreen = useMediaQuery("(max-width: 840px)");
  const [questionselect] = useState(0);
  const [questionstart] = useState(0);
  const [questionfinal] = useState(0);
  const [checkedQuestion, handleChangeQuestion] = useCheck(true);
  const [checkedStudy, handleChangeStudy] = useCheck(false);
  const [checkedAnswer, handleChangeAnswer] = useCheck(true);
  const [checkedTimer, handleChangeTimer] = useCheck(true);
  const [duration, setDuration] = useState(0);
  const [value, setValue] = useState(1); //radio group
  const [, setLoadings] = useState();
  const [theme, setTheme] = useState([]);

  const [themeId, setThemeId] = useState([]);
  const [themeIdQuanty, setThemeIdQuanty] = useState([]);
  const [, setQuantity] = useState(0);

  const [stateWeight, setStateWeight] = useState();
  const [stateTheme, setStateTheme] = useState(); 
  var themeWeight = [];
  var resultTheme;

  useEffect(() => {
    onload();
    return () => {
      setLoadings();
    };
  }, []);

  useEffect(() => {
    sum(theme);
  }, [theme]);

  const onload = async () => {
    
    periodRepo.findPeriod(control.periodId).then(
      ({ data }) => {
        setDuration(parseInt(data.test_duration));
      },
      (error) => {
        setLoadings(false);
        if (error.response) {
          Modal.error({
            title: t("error"),
            content: error.response.data.message.email,
          });
        }
        if (error.response.status === 401) {
          Modal.error({
            title: t("error"),
            content: error,
          });
          setLocation("/login");
        }
      }
    );
    var themeNow = [];

    const response = await themeRepo.getSubjectThemes({
      subject_id: control.subjectId,
      period_id: control.periodId,
    });
    var i = 0;
    for (let j = 0; j < response.data.data.length; j++) {
      setThemeId((themePeriodId) => [
        ...themePeriodId,
        response.data.data[j].id,
      ]);
      themeNow[j] = response.data.data[j].theme_id;
      if (response.data.data[j].period_id === control.periodId) {
        themeWeight[i] = response.data.data[j].weight;
        i++;
      }
    }

    resultTheme = themeNow.filter((item, index) => {
      return themeNow.indexOf(item) === index;
    });
    setStateWeight(themeWeight);
    setStateTheme(resultTheme);

    const responses = await themeRepo.getStemsTheme({
      themes_ids: resultTheme,
    });
    for (let j = 0; j < responses.data.data.length; j++) {
      
      if (themeWeight[j] !== undefined) {
        setQuantity(responses.data.data[j].count);
        setThemeIdQuanty((themeIdQuanty) => [
          ...themeIdQuanty,
          {
            id: responses.data.data[j].theme_id,
            quantity: themeWeight[j],
          },
        ]);

        setTheme((theme) => [
          ...theme,
          {
            key: j,
            select: "",
            theme: responses.data.data[j].theme.name,
            quantity: themeWeight[j],
          },
        ]);
      }
    }
   
    setLoadings(false);
  };

  const sum = (theme) => {
    var total = theme.reduce(
      (sum, value) =>
        typeof value.quantity === "number" ? sum + value.quantity : sum,
      0
    );
    setQuantity(total);
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onCancel = () => {
    setLocation("/dashboard");
  };

  const onSend = (
    value,
    questionselect,
    questionstart,
    questionfinal,
    checkedQuestion,
    checkedStudy,
    checkedAnswer,
    checkedTimer
  ) => {
    var seconds = parseInt(duration * 60);
    dispatchControl({
      type: types.configuration,
      payload: {
        value: value,
        theme_id: themeId,
        themeIdQuanty: themeIdQuanty,
        limit: questionselect,
        to: questionstart,
        from: questionfinal,
        randomQuestion: checkedQuestion,
        randomAnswer: checkedAnswer,
        studyMode: checkedStudy,
        timer: checkedTimer,
        seconds: seconds,
        resultTheme: stateTheme,
        resultWeight: stateWeight,
      },
    });
    dispatchPageHeader({
      type: types.pageheader,
      payload: {
        active: 0,
      },
    });
    
    dispatchType({
      type: types.typeTest,
      payload: {
        type: "official"
      },
    }); 
    localStorage.setItem("header", 0);
    setLocation("/test/ensayo");
  };

  return (
    <>
        <div className="main-control-grid">
          <div className="row">
            <div className="col ">
              <div className="pxy-20">
                <Radio.Group
                  onChange={onChange}
                  value={value}
                  className="ant-radio-group"
                >
                  <div className="row">
                    <div className="col ">
                      <Radio value={1} checked disabled>
                        {t("radio1")}
                      </Radio>
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: 30 }}>
                    <div className="col-ensayo col-60">
                      <Form.Item>
                        {" "}
                        <Radio value={2} disabled>
                          {t("radio21")}
                        </Radio>{" "}
                      </Form.Item>
                    </div>
                    <div
                      className="col-ensayo col-40"
                      style={{ marginLeft: -14 }}
                    >
                      <Form.Item>
                        <InputNumber name="selectquestion" disabled />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-ensayo col-70">
                      <Radio value={3} disabled>
                        {t("radio31")}
                      </Radio>
                    </div>
                    <div className="col-ensayo col-05">
                      <Form.Item>
                        <InputNumber name="questionstart" disabled />
                      </Form.Item>
                    </div>
                    <div className="col-ensayo col-40">
                      <Form.Item>
                        <p
                          style={{ marginLeft: 15, color: "hwb(0deg 60% 40%)" }}
                        >
                          {t("radio32")}
                        </p>
                      </Form.Item>
                    </div>
                    <div className="col-ensayo col-05">
                      <Form.Item>
                        <InputNumber name="questionfinal" disabled />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="theme-table-row" style={{ marginTop: 5 }}>
                    <Radio value={4} disabled>
                      {t("radio4")}
                    </Radio>
                  </div>
                </Radio.Group>
              </div>
            </div>
            {isSmallScreen === false ?
              <div className="col pxy-20">
              <div className="row" style={{ marginTop: 10 }}>
                <div className="col col-35">
                  <p>{t("switch4")}</p>
                </div>
                <div
                  className="col col-20"                 
                >
                  <Switch
                    style={{ width: "40px" }}
                    onChange={handleChangeTimer}
                    disabled
                  />
                  <label className="form-check-label"></label>
                </div>
                <div className="col col-45">
                  <div className="row">
                    <div className="col">
                      <p className="mb-5">
                        {t("tiempo")} {duration} min.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col col-35">
                  <p>{t("switch1")}</p>
                </div>

                <div className="col col-65">
                  <Switch
                    style={{ width: "30px" }}
                    defaultChecked
                    onChange={handleChangeQuestion}
                    disabled
                  />
                  <label className="form-check-label"></label>
                </div>
              </div>

              <div className="row">
                <div className="col col-35">
                  <p>{t("switch3")}</p>
                </div>
                <div className="col col-65">
                  <Switch
                    style={{ width: "40px" }}
                    onChange={handleChangeAnswer}
                    disabled
                  />
                  <label className="form-check-label"></label>
                </div>
              </div>
              <div className="row">
                <div className="col col-35">
                  <p>{t("switch2")}</p>
                </div>
                <div className="col col-65">
                  <Switch
                    style={{ width: "40px" }}
                    onChange={handleChangeStudy}
                    disabled
                  />
                  <label className="form-check-label"></label>
                </div>
              </div>
            </div>
             :
            <div className="col pxy-20">
              <div className="row" style={{ marginTop: 10 }}>
                <div className="row" style={{ padding: "4%"  }}>
                  {t("switch4")}
                  <Switch
                    style={{ width: "40px", marginLeft:80 }}
                    onChange={handleChangeTimer}
                    disabled
                  />
                </div>
                  <div className="row">
                      <p className="mb-5"  style={{ marginLeft: 12}}>
                        {t("tiempo")} {duration} min.
                      </p>                    
                  </div>
              </div>
              <div className="row"  style={{ padding: "4%"  }}>
               {t("switch1")}
                  <Switch
                     style={{ width: "40px",  marginLeft:178, marginTop: -40  }}
                    defaultChecked
                    onChange={handleChangeQuestion}
                    disabled
                  />
              </div>

              <div className="row" style={{ padding: "4%"  }}>
               {t("switch3")}
                  <Switch
                   style={{ width: "40px",  marginLeft:178, marginTop: -40   }}
                    onChange={handleChangeAnswer}
                    disabled
                  />
              </div>
              <div className="row"  style={{ padding: "4%"  }}>
                {t("switch2")}
                  <Switch
                    style={{ width: "40px", marginLeft:80 }}
                    onChange={handleChangeStudy}
                    disabled
                  />
              </div>
            </div>
            }
            
          </div>
          <div className="row-table px-20">
            <div className="card-theme-table">
              <Card className="mt-30 mb-20">
                <Table
                  columns={columns}
                  dataSource={theme}
                  pagination={true}
                  size="middle"
                />
              </Card>
            </div>
          </div>
        </div> 

      <div
        className="themes-ensayo-table-switchs-buttons pxy-20"
        style={{ float: "right" }}
      >
        <Button onClick={onCancel}>{t("btncancelar")}</Button>
        <Button
          type="primary"
          onClick={() =>
            onSend(
              value,
              questionselect,
              questionstart,
              questionfinal,
              checkedQuestion,
              checkedStudy,
              checkedAnswer,
              checkedTimer
            )
          }
        >
          {t("btninicio")}
        </Button>
      </div>
    </>
  );
};

export default React.memo(CardThemeOptionExam);
