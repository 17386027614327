import "./Title.scss";

const Title = ({ content, spanContent, customClass, firtTitle }) => {
  return (
    <>
      {firtTitle && (
        <h1
          className={`page-title montserrat-50 ${
            customClass ? customClass : ""
          }`}
        >
          {content} <span>{spanContent}</span>
        </h1>
      )}
      {!firtTitle && (
        <h1
          className={`page-title montserrat-40 ${
            customClass ? customClass : ""
          }`}
        >
          {content} <span>{spanContent}</span>
        </h1>
      )}
    </>
  );
};

export default Title;
