import { Button } from "antd";
import React, { useEffect, useState } from "react";
import Logo from "../../Atoms/Logo/Logo";
import { verifyEmailRepo } from "../../../store";
import { useTranslation } from "react-i18next";
import { useLocation } from "wouter";
import "./ConfirmRegister.scss";
import {APP} from "../../../Config/config";

const ConfirmRegister = () => {
    const { t, i18n } = useTranslation();
    const [, setLocation] = useLocation();
    const [verified, setVerified] = useState();

    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem("lang")); 
        
        let path =  window.location.href.split('verify-email?url=')[1];
        path = path.startsWith('/') ? path.substring(1) : path;

        verifyEmailRepo
            .verifyEmail(APP.apiUrl + path)
            .then(function (response) {
                setVerified(true);
            })
            .catch(function (error) {
                console.error(error.response);
            });

    }, []);

    const  next = () =>{
        setLocation("/login");
    }

    return (
        <div className="register_container">
            {verified === true ?
                <div>
                    <Logo width={"200px"} height={"100%"} customClass="mb-10 text-center" />
                    <h1 className="mb-10 text-center pt-30 pl-20 pr-20">{t("successful")}</h1>
                    <p className="mb-10 text-center">{t("msg")}</p>
                    <div className="text-center pb-30">
                        <Button type="primary" onClick={() => next()}>
                            {t("btncontinue")}
                        </Button>
                    </div>
                </div>
                :
                <div>
                    <Logo width={"200px"} height={"100%"} customClass="mb-10 text-center" />
                    <h1 className="mb-10 text-center pt-30 pl-20 pr-20">{t("failure")}</h1>
                </div>
            }
        </div>
    );
};

export default ConfirmRegister;

