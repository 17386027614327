import Repository from './repository.js'
export default class StandardTestRepository extends Repository {

    constructor(apis) {
        super(apis)
    }

    showStandartTest(){
        return this.$axios            
             .get('api/standard-tests')
    }

    findStandartTest(standardTestId){
        return this.$axios            
             .get('api/standard-tests/'+standardTestId)
             
    }

    getPackages(standardTestId){
        return this.$axios            
             .get('api/standard-tests/'+standardTestId+'/packages')             
             
    } 
    getStem(standardTestId, params={}){        
        return this.$axios            
            .get('api/standard-tests/'+standardTestId+'/user-stems',{ params
        })          
            
    }     
    
    

    

}